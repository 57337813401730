<script>
  import { onMount, setContext, getContext } from "svelte";
  import { fly } from "svelte/transition";
  import Tailwind from "./Tailwind.svelte";
  import PDFPage from "./PDFPage.svelte";
  import Image from "./Image.svelte";
  import Signature from "./Signature.svelte";
  import Text from "./Text.svelte";
  import Drawing from "./Drawing.svelte";
  import LeftMenu from "./LeftMenu.svelte";
  import DrawingCanvas from "./DrawingCanvas.svelte";
  import prepareAssets, { fetchFont } from "./utils/prepareAssets.js";
  import { clickOutside } from "./utils/clickOutside.js";
  import {
    readAsArrayBuffer,
    readAsImage,
    readAsPDF,
    readAsDataURL,
  } from "./utils/asyncReader.js";
  import { ggID } from "./utils/helper.js";
  import { save, getData } from "./utils/PDF.js";
  import Menu from "./RightMenu.svelte";
  import MenuOption from "./RightMenuOption.svelte";
  import Background from "./Background.svelte";
  import Carousel from "./Carousel.svelte";
  import CheckMark from "./CheckMark.svelte";
  import CopyClipBoard from "./CopyClipBoard.svelte";
  import Selectbox from "./Selectbox.svelte";
  import SendTo from "./SendTo.svelte";

  import {
    signInWithGoogle,
    signInWithMicrosoft,
    user as storeUser,
    auth,
    logout,
    sendBase64FileToGoogleDrive,
    sendBase64FileToOneDrive,
    getChatUsers,
    getTeam,
  } from "./store/auth.store.js";
  import { timeAgo } from "./store/timeAgo.js";

  import { IApp, IUser } from "./types.js";
  import ImageUploader from "./ImageUploader.svelte";
  import PdfTemplate from "./Template.svelte";

  const genID = ggID();
  let pdfFile;
  let pdfName = "";
  let pages = [];
  let pagesScale = [];
  let allObjects = [];
  let currentFont = "Times-Roman";
  let focusId = null;
  let selectedPageIndex = -1;
  let saving = false;
  let sending = false;
  let addingDrawing = false;
  let users = "";
  let chatUsers = [];
  let selectedUser = "";
  let usersPanel = false;
  let loginPanel = false;
  let user_name = "";
  let user_pw = "";
  let user_data = [];
  let loginError = "";
  let showMenu = false;
  let pos = { x: 0, y: 0 };
  let layerPos = { x: 0, y: 0 };
  let wx = 0;
  let wy = 0;
  let quickSigns = [];
  let showSignatureTab = false;
  let signatureTabLoading = true;
  let selectedSginTab = "pending";
  let pendingSignature = [];
  let completedSignature = [];
  let templates = [];
  let sendingSign = false;
  let firstLoading = false;
  let sharePanel = false;
  let signatureMenu = false;
  let logoutpanel = false;
  let sendPDFType = "";
  let sendPDFRes = false;
  let clipboardTxt = "";
  let blog_slug = "";
  let loginOnly = true;
  let searchKeyPending = "";
  let searchKeyCompleted = "";
  let searchKeySignature = "";
  let newUserPanel = false;
  let userName = "";
  let userPhone = "";
  let defaultDocs = [
    "CLIENT-Forwarding_Consent_Forms_for_MSA_SSDI_-_Settlement.pdf",
    "Fee_Disclosure_Statement.pdf",
    "intake_dwc-1.docx.pdf",
    "INTAKE_imr_rule.pdf",
    "INTAKE_LABOR_CODE_SECTION_4906_g.pdf",
    "intake_new_client_part3.pdf",
    "INTAKE_VENUE.pdf",
    "INTAKE_Warning_To_Employee_Letter.pdf",
  ];
  let sendingNewUser = false;
  let sendingNewUserType = 0;
  let templateId = 0;
  let showRenameTemp = false;
  let newTempName = "";
  let tempThumb = "";
  let newSignPanel = false;
  let newSignName = "";
  let newSignPhone = "";
  let twoFactAuthPanel = false;
  let verificationCode = "";
  let user_data_temp = "";
  let display_name_panel = false;
  let newUserName = "";
  let friends = [];
  let searchKeyFriend = "";
  let inviteFriendPanel = true;
  let inviteFriendEmail = "";
  let tutorialStep = 0;
  let inviteClipboardTxt = "";
  let pdfWrap;
  let smsLang = "";
  let languages = [
    { value: "en", label: "English" },
    { value: "sp", label: "Spanish" },
    { value: "ko", label: "Korean" },
    { value: "ch", label: "Chinese" },
    { value: "Fr", label: "French" },
  ];
  let smsSign = "";
  let signColor = 0;
  let displayLeftMenu = false;
  let sendToId = 0;
  let emailPanel = false;
  let emailCCPanel = false;
  let shareEmail = "";
  let shareUserId = "";
  let shareUserName = "";
  let shareCaseFileId = "";
  let shareEmailcc = "";
  let checkIfEmail = false;
  let shortName = "";
  let merusToken = "";
  let blog = { siteurl: "https://qix.cloud" };
  let signUrl = "";
  let logo = "/img/default_image.svg";
  let emailNotifyAvailable = true;
  let selectedMerusUserId = "";
  let selectedMerusActivityTypeId = "";
  let user = {
    id: "",
    appId: "",
    email: "",
    name: "",
    surname: "",
    img: "",
    phone: "",
    role: "",
    locale: "",
    loggedIn: "", // optional
    google: "", // optional
    microsoft: "", // optional
  };
  let entry_requireds = [];
  let entry_required_txts = [];
  let entry_options = [];
  let entry_option_txts = [];
  // Subscribe to storeUser changes
  storeUser.subscribe((val) => {
    if (val != null) {
      user = val;
      get_company_logo();
    }
  });
  auth()
    .then(() => {
      Promise.all([]).finally(() => {
        //firstLoading = true;
      });
    })
    .catch(() => {
      firstLoading = false;
    });

  getTeam()
    .then((info) => {
      friends = info;
    })
    .catch((err) => {
      console.log(err);
    });
  onMount(async () => {
    try {
      chatUsers = await getChatUsers();
      await getDefaultDoc();
      firstLoading = true;
      getMerusToken();
      getEmailNotifyAvailable();
      if (user.id != "") {
        let body = {
          auth: "google",
          user_id: user.id,
          email: user.email,
          blog_id: blog.blog_id,
          team: friends,
        };
        const req = await fetch(
          blog.siteurl +
            "/wp-admin/admin-ajax.php?action=getSignatureListForCloudflare",
          {
            method: "POST",
            body: JSON.stringify(body),
          }
        );
        let data = await req.json();
        pendingSignature = data.pending;
        completedSignature = data.completed;
        templates = data.templates;
      }
      setTimeout(() => {
        fetchFont(currentFont);
        prepareAssets();
        firstLoading = true;
      }, 5000);
    } catch (e) {
      console.log(e);
    }
  });

  async function get_company_logo() {
    let body = { appId: user.appId, email: user.email };
    const req = await fetch(blog.siteurl + "/ajax/logo.php", {
      method: "POST",
      body: JSON.stringify(body),
    });
    let res = await req.json();
    logo = res.logo.replace("{{api}}", "https://api.qix.cloud/");
  }
  const copy = () => {
    const app = new CopyClipBoard({
      target: document.getElementById("clipboard"),
      props: { clipboardTxt },
    });

    app.$destroy();
    //clipboardTxt = "";
    //usersPanel = false;
    alert("Copied.");
  };
  const copyInviteUrl = () => {
    clipboardTxt = inviteClipboardTxt;
    const app = new CopyClipBoard({
      target: document.getElementById("inviteclipboard"),
      props: { clipboardTxt },
    });

    app.$destroy();
    //clipboardTxt = "";
    //usersPanel = false;
    alert("Copied.");
  };
  const copySignUrl = () => {
    clipboardTxt = signUrl;
    const app = new CopyClipBoard({
      target: document.getElementById("signclipboard"),
      props: { clipboardTxt },
    });

    app.$destroy();
    //clipboardTxt = "";
    //usersPanel = false;
    alert("Copied.");
  };
  async function onUploadPDF(e) {
    const files = e.target.files || (e.dataTransfer && e.dataTransfer.files);
    const file = files[0];
    if (!file || file.type !== "application/pdf") return;
    selectedPageIndex = -1;
    try {
      await addPDF(file);
      selectedPageIndex = 0;
    } catch (e) {
      console.log(e);
    }
  }
  async function addPDF(file) {
    try {
      getSignatures();
      const pdf = await readAsPDF(file);
      pdfName = file.name;
      pdfFile = file;
      const numPages = pdf.numPages;
      pages = Array(numPages)
        .fill()
        .map((_, i) => pdf.getPage(i + 1));
      allObjects = pages.map(() => []);
      pagesScale = Array(numPages).fill(1);
    } catch (e) {
      console.log("Failed to add pdf.");
      throw e;
    }
  }
  async function onUploadImage(e) {
    const file = e.target.files[0];
    if (file && selectedPageIndex >= 0) {
      addImage(file);
    }
    e.target.value = null;
  }
  async function addImage(file) {
    try {
      // get dataURL to prevent canvas from tainted
      const url = await readAsDataURL(file);
      const img = await readAsImage(url);
      const id = genID();
      const { width, height } = img;
      const object = {
        id,
        type: "image",
        width,
        height,
        x: 0,
        y: 0,
        payload: img,
        file,
      };
      allObjects = allObjects.map((objects, pIndex) =>
        pIndex === selectedPageIndex ? [...objects, object] : objects
      );
    } catch (e) {
      console.log(`Fail to add image.`, e);
    }
  }
  function onAddTextField() {
    if (selectedPageIndex >= 0) {
      addTextField();
    }
  }
  function addTextField(text = "New Text Field", pos = false) {
    const id = genID();
    fetchFont(currentFont);
    const object = {
      id,
      text,
      type: "text",
      size: 16,
      width: 0, // recalculate after editing
      lineHeight: 1.4,
      fontFamily: currentFont,
      x: 0,
      y: 0,
    };
    if (pos) {
      object.x = layerPos.x;
      object.y = layerPos.y;
    }
    allObjects = allObjects.map((objects, pIndex) =>
      pIndex === selectedPageIndex ? [...objects, object] : objects
    );
  }
  function onAddDrawing() {
    if (selectedPageIndex >= 0) {
      addingDrawing = true;
      signatureMenu = false;
    }
  }
  function addDrawing(originWidth, originHeight, path, scale = 1) {
    const id = genID();
    const object = {
      id,
      path,
      type: "drawing",
      x: 0,
      y: 0,
      originWidth,
      originHeight,
      width: originWidth * scale,
      scale,
    };
    allObjects = allObjects.map((objects, pIndex) =>
      pIndex === selectedPageIndex ? [...objects, object] : objects
    );
  }
  function selectFontFamily(event) {
    const name = event.detail.name;
    fetchFont(name);
    currentFont = name;
  }
  function selectPage(index) {
    selectedPageIndex = index;
  }
  function updateObject(objectId, payload) {
    //console.log("payload", payload);
    if (payload.text == "New Text Field") {
      payload.text = payload.lines[0];
    } else if (payload.text == "Phone_Number") {
      payload.text = "Phone_" + payload.lines[0].replace("Phone_", "").trim();
    } else if (payload.text == "Entry Required") {
      payload.text =
        "entry_r_" +
        payload.lines[0]
          .replace("Entry Required", "")
          .trim()
          .replace(/\s+/g, " ")
          .replace(/ /g, "_");
      if (
        !entry_required_txts.includes(payload.text) &&
        payload.text != "entry_r_"
      ) {
        entry_required_txts.push(payload.text);
        entry_requireds.push(payload);
      }
    } else if (payload.text == "Entry Optional") {
      payload.text =
        "entry_o_" +
        payload.lines[0]
          .replace("Entry Optional", "")
          .trim()
          .replace(/\s+/g, " ")
          .replace(/ /g, "_");
      if (
        !entry_option_txts.includes(payload.text) &&
        payload.text != "entry_o_"
      ) {
        entry_option_txts.push(payload.text);
        entry_options.push(payload);
      }
    }
    allObjects = allObjects.map((objects, pIndex) =>
      pIndex == selectedPageIndex
        ? objects.map((object) =>
            object.id === objectId ? { ...object, ...payload } : object
          )
        : objects
    );
  }
  function deleteObject(objectId) {
    allObjects = allObjects.map((objects, pIndex) =>
      pIndex == selectedPageIndex
        ? objects.filter((object) => object.id !== objectId)
        : objects
    );
  }
  function onMeasure(scale, image, i) {
    pagesScale[i] = scale;
    if (i == 0) tempThumb = image;
  }
  // FIXME: Should wait all objects finish their async work
  async function savePDF() {
    if (!pdfFile || saving || !pages.length) return;
    saving = true;
    try {
      console.log("allObjects", allObjects);
      await save(pdfFile, allObjects, pdfName, pagesScale);
    } catch (e) {
      console.log(e);
    } finally {
      saving = false;
    }
  }
  async function sendPDFfunc(type) {
    sendPDFType = type;
    sendPDFRes = false;
    display_name_panel = false;
    hideNewUserPanel();
    sendPDF();
  }
  async function sendPDF() {
    sharePanel = false;
    if (sendPDFType == "link") {
      sendSignature(0);
    } else if (user.id != "") {
      usersPanel = true;
    } else {
      loginError = "";
      loginOnly = false;
      loginPanel = true;
      return;
    }
  }

  function showLoginPopup() {
    loginOnly = true;
    loginError = "";
    loginPanel = true;
  }
  async function handleUserSelect(event) {
    selectedUser = event.detail.item;
    smsLang = smsLang == "" ? selectedUser.lang : smsLang;
    //console.log("selectedUser", selectedUser);
    if (sendPDFType == "sms" && selectedUser.tw_b_id < 2) {
      alert("Upgrade.");
    } else {
      sendSignature(
        selectedUser.value,
        selectedUser.blog,
        selectedUser.tw_b_id,
        selectedUser.phone,
        selectedUser.email
      );
    }
  }
  async function handleChatUserSelect(event) {
    shareEmail = event.detail.item.email;
    if (event.detail.item.id != "") {
      shareUserId = event.detail.item.id;
      shareUserName = event.detail.item.fullName;
      shareCaseFileId = event.detail.item.advanced.caseFileIds.toString();
    }
    checkIfEmailFunc();
    console.log("shareEmail", shareEmail);
  }
  async function handleChatUserEnterEmail(event) {
    shareEmail = event.detail.item.email;
    shareUserId = "";
    shareUserName = "";
    shareCaseFileId = "";
    checkIfEmailFunc();
    console.log("enterEmail", shareEmail);
  }

  async function handleSendEmail() {
    smsLang = smsLang == "" ? selectedUser.lang : smsLang;
    sendSignature(0, 1, 1, "", shareEmail, shareEmailcc);
    selectedUser = "";
  }
  async function handleLanguageSelect() {}
  async function sendSignature(
    contact_id = 0,
    cblog = "",
    tw_b_id = 0,
    phone = "",
    email = "",
    cc = ""
  ) {
    if (!pdfFile || sending || !pages.length) return;
    //if(contact_id == 0 && newUserName == "") return;
    sending = true;
    try {
      clipboardTxt = "";
      sendingSign = true;
      usersPanel = true;
      let body = await getSignatureOptions(contact_id);
      body.newName = newUserName;
      body.lang = smsLang;
      body.color = signColor;
      if (cblog != "") body.blog = cblog;
      body.tw_b_id = tw_b_id;
      body.phone = phone;
      body.email = email;
      body.user_id = shareUserId;
      body.user_name = shareUserName;
      body.user_caseFileId = shareCaseFileId;
      body.cc = cc;
      body.staff_id = user.id;
      body.staff_email = user.email;
      body.appId = user.appId;
      body.merusUserId = selectedMerusUserId;
      body.merusActivityTypeId = selectedMerusActivityTypeId;

      const req = await fetch(
        blog.siteurl + "/wp-admin/admin-ajax.php?action=updateContactPost",
        {
          method: "POST",
          body: JSON.stringify(body),
        }
      );
      let data = await req.json();
      //alert('Sent successfully!.');

      shareEmail = "";
      shareUserId = "";
      shareUserName = "";
      shareCaseFileId = "";
      if (sendPDFType == "link") {
        clipboardTxt = blog.siteurl + "/s/" + data.id;
      }
      sendPDFRes = true;
      await getUsersFunc();
    } catch (e) {
      console.log(e);
    } finally {
      sending = false;
      selectedUser = "";
    }
    sendingSign = false;
    smsLang = "";
    //usersPanel = false;
  }

  async function getUsersFunc() {
    const users_req = await fetch(
      blog.siteurl +
        "/wp-admin/admin-ajax.php?action=getCloudFlareAppUsers&auth=" +
        "google" +
        "&id=" +
        user.id +
        "&email=" +
        user.email
    );
    users = await users_req.json();
  }
  async function onRightClick(e) {
    if (e.target.nodeName == "CANVAS") {
      if (showMenu) {
        showMenu = false;
        await new Promise((res) => setTimeout(res, 100));
      }
      pos = { x: e.clientX + wx, y: e.clientY + wy };
      layerPos = { x: e.layerX, y: e.layerY };
      showMenu = true;
    } else if (e.target.className.includes("Signature wrap")) {
      signColor = signColor == 0 ? 255 : 0;
      toggleSignImage();
    }
  }
  async function toggleSignImage() {
    let tempObjects = allObjects;
    for (var i = 0; i < tempObjects.length; i++) {
      for (var j = 0; j < tempObjects[i].length; j++) {
        let obj = tempObjects[i][j];
        if (obj.type == "Signature") {
          selectedPageIndex = i;
          deleteObject(obj.id);
          layerPos = { x: obj.x, y: obj.y };
          await addSignImage("", 1);
        }
      }
    }
  }
  function closeMenu() {
    showMenu = false;
  }
  async function addSignImage(url = "", p) {
    try {
      if (url == "") {
        if (signColor == 0) {
          url = "https://qix.cloud/img/john_doe_sign_black.png";
        } else {
          url = "https://qix.cloud/img/john_doe_signature_black.png";
        }
      }
      // get dataURL to prevent canvas from tainted
      const file = url;
      const img = await readAsImage(url);
      const id = genID();
      const { width, height } = img;
      signatureMenu = false;
      const object = {
        id,
        type: "Signature",
        width,
        height,
        x: layerPos.x,
        y: layerPos.y,
        payload: img,
        file,
      };
      if (p == 0) {
        object.x = 0;
        object.y = 0;
      }
      allObjects = allObjects.map((objects, pIndex) =>
        pIndex === selectedPageIndex ? [...objects, object] : objects
      );
    } catch (e) {
      console.log(`Fail to add image.`, e);
    }
  }
  async function addQuickSignImage(url, p) {
    try {
      // get dataURL to prevent canvas from tainted
      const file = url;
      const img = await readAsImage(url);
      const id = genID();
      const { width, height } = img;
      signatureMenu = false;
      const object = {
        id,
        type: "quickSign",
        width,
        height,
        x: layerPos.x,
        y: layerPos.y,
        payload: img,
        file,
      };
      if (p == 0) {
        object.x = 0;
        object.y = 0;
      }
      allObjects = allObjects.map((objects, pIndex) =>
        pIndex === selectedPageIndex ? [...objects, object] : objects
      );
      //console.log("allObjects", allObjects);
    } catch (e) {
      console.log(`Fail to add image.`, e);
    }
  }
  async function showSignatureTabFunc() {
    //if (blog != "") {
    pendingSignature = [];
    completedSignature = [];
    signatureTabLoading = true;
    showSignatureTab = true;
    showRenameTemp = false;
    selectedSginTab = "pending";
    let body = {
      auth: "google",
      user_id: user.id,
      email: user.email,
      blog_id: blog.blog_id,
      team: friends,
    };
    const req = await fetch(
      blog.siteurl +
        "/wp-admin/admin-ajax.php?action=getSignatureListForCloudflare",
      {
        method: "POST",
        body: JSON.stringify(body),
      }
    );
    let data = await req.json();
    pendingSignature = data.pending;
    completedSignature = data.completed;
    templates = data.templates;
    if (users.length < 1) {
      await getUsersFunc();
    }
    getSignatures();
    //getFriends();
    signatureTabLoading = false;
    //}
  }
  function hideSignatureTabFunc() {
    showSignatureTab = false;
  }
  async function deleteSignature(id) {
    if (confirm("Are you sure you want to delete the signature?") == true) {
      signatureTabLoading = true;
      let body = {
        id: id,
        auth: "google",
        user_id: user.id,
        email: user.email,
      };
      const req = await fetch(
        blog.siteurl + "/wp-admin/admin-ajax.php?action=deleteSignatureProcess",
        {
          method: "POST",
          body: JSON.stringify(body),
        }
      );
      let data = await req.json();
      pendingSignature = data.pending;
      completedSignature = data.completed;
      templates = data.templates;
      signatureTabLoading = false;
    }
  }
  async function deleteQuickSignature(id) {
    if (confirm("Are you sure you want to delete the signature?") == true) {
      signatureTabLoading = true;
      let body = { id: id };
      const req = await fetch(
        blog.siteurl +
          "/wp-admin/admin-ajax.php?action=deleteQuickSignatureProcess",
        {
          method: "POST",
          body: JSON.stringify(body),
        }
      );
      quickSigns = await req.json();
      signatureTabLoading = false;
    }
  }
  async function resendSignature(id) {
    if (confirm("Are you sure you want to resend the signature?") == true) {
      signatureTabLoading = true;
      let body = { id: id };
      const req = await fetch(
        blog.siteurl + "/wp-admin/admin-ajax.php?action=resendSignatureProcess",
        {
          method: "POST",
          body: JSON.stringify(body),
        }
      );
      alert("Sent!");
    }
  }
  function changeSignatureTabFunc(tab) {
    selectedSginTab = tab;
  }
  async function shareSignature(title, text, url) {
    const shareData = {
      title: title,
      text: text,
      url: url,
    };
    try {
      await navigator.share(shareData);
      //alert('Shared successfully');
    } catch (err) {
      alert(err);
    }
  }
  async function shareFunc() {
    if (selectedPageIndex >= 0) {
      usersPanel = false;
      sharePanel = true;
      await getUsersFunc();
    }
  }
  function handleClickOutsideSharePanel() {
    sharePanel = false;
  }
  function closeUsersPanel() {
    usersPanel = false;
  }
  function showSignatureMenu() {
    if (selectedPageIndex >= 0) {
      signatureMenu = true;
    }
  }
  function handleClickOutsideSignatureMenu() {
    signatureMenu = false;
  }
  function handleClickOutsideSignatureTab() {
    showSignatureTab = false;
  }
  function handleClickOutsideLogoutPanel() {
    logoutpanel = false;
  }
  function showlogoutpanel() {
    logoutpanel = true;
  }
  function showNewUserPanel() {
    sendingNewUserType = 0;
    sendingNewUser = false;
    newUserPanel = true;
  }
  function showEmailPanel(status) {
    shareEmail = "";
    checkIfEmailFunc();
    emailPanel = status;
  }
  function showEmailCCPanel(status) {
    shareEmailcc = "";
    emailCCPanel = status;
  }
  function checkIfEmailFunc() {
    // Regular expression to check if string is email
    const regexExp =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;

    checkIfEmail = regexExp.test(shareEmail);
  }
  function hideNewUserPanel() {
    newUserPanel = false;
    display_name_panel = false;
  }
  async function addNewUser() {
    if (userName != "" && userPhone != "") {
      sendingNewUser = true;
      sendingNewUserType = 0;
      const req = await fetch(
        "https://qix.cloud/wp-admin/admin-ajax.php?action=addNewConversationCloudFlare",
        {
          method: "POST",
          body: JSON.stringify({
            name: userName,
            phone: userPhone,
            auth: "google",
            id: user.id,
          }),
        }
      );
      let data = await req.json();
      users = data.users;
      selectedUser = data.contact_id;
      if (data.type > 0) {
        sendingNewUserType = 1;
      } else {
        sendingNewUser = false;
        newUserPanel = false;
        sendSignature(data.contact_id);
      }
    }
  }

  async function continueSendSignature() {
    sendingNewUserType = 0;
    sendingNewUser = false;
    newUserPanel = false;
    sendSignature(selectedUser);
  }
  function checkUserPhone() {
    let l = userPhone.length;
    let newValue = userPhone.replace(/\D+/g, "");
    userPhone = formatToTelephone(newValue, l);
  }
  function formatToTelephone(str, l) {
    var splitString = str.split(""),
      returnValue = "";

    for (var i = 0; i < splitString.length; i++) {
      var currentLoop = i,
        currentCharacter = splitString[i];

      switch (currentLoop) {
        case 0:
          returnValue = returnValue.concat("(");
          returnValue = returnValue.concat(currentCharacter);
          break;
        case 2:
          returnValue = returnValue.concat(currentCharacter);
          if (l > 6) returnValue = returnValue.concat(") ");
          break;
        case 5:
          returnValue = returnValue.concat(currentCharacter);
          if (l > 10) returnValue = returnValue.concat("-");
          break;
        default:
          returnValue = returnValue.concat(currentCharacter);
      }
    }
    return returnValue;
  }
  async function handleLoadTemplate(event) {
    loadTemplate(event.detail.template);
  }
  async function loadTemplate(template) {
    selectedMerusUserId = template.merusUserId;
    selectedMerusActivityTypeId = template.activity_type_id;
    showSignatureTab = false;
    let body = { url: template.pdfUrl, auth: "google" };
    const res = await fetch(
      blog.siteurl + "/wp-admin/admin-ajax.php?action=loadTemplateCloudFlare",
      {
        method: "POST",
        body: JSON.stringify(body),
      }
    );
    let pdfBlob = await res.blob();
    selectedPageIndex = 0;
    await addPDF(pdfBlob);
    pdfName = template.pdfName;
    let Objs = JSON.parse(template.allObjects);
    for (var i = 0; i < Objs.length; i++) {
      for (var j = 0; j < Objs[i].length; j++) {
        let obj = Objs[i][j];
        layerPos = { x: obj.x, y: obj.y };
        selectedPageIndex = i;
        if (obj.type == "Signature") {
          await addSignImage(obj.file, 1);
        }
        if (obj.type == "quickSign") {
          await addQuickSignImage(obj.file, 1);
        }
        if (obj.type == "text") {
          addTextField(obj.text, true);
        }
      }
    }
    templateId = template.id;
  }
  async function loadDefaultDoc(index) {
    selectedPageIndex = 0;
    const res = await fetch("/pdfs/" + defaultDocs[index]);
    const pdfBlob = await res.blob();
    await addPDF(pdfBlob);
    pdfName = defaultDocs[index];
    updateDefaultDoc(index);
  }
  async function getDefaultDoc() {
    if (user.id != "") {
      let body = new URLSearchParams();
      body.append("action", "getDefaultPdfs");
      body.append("user_id", user.id);
      const req = await fetch("https://qix.cloud/ajax/", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: body.toString(),
      });
      const res = await req.json();
      if (res.length > 0) defaultDocs = res;
    }
  }
  async function updateDefaultDoc(index) {
    // Get the item at the specified index
    const selectedDoc = defaultDocs[index];
    // Create a new array with the selected item at the start, followed by the rest
    defaultDocs = [
      selectedDoc,
      ...defaultDocs.slice(0, index),
      ...defaultDocs.slice(index + 1),
    ];

    let body = new URLSearchParams();
    body.append("action", "updateDefaultPdfs");
    body.append("user_id", user.id);
    body.append("defaultDocs", JSON.stringify(defaultDocs)); // Convert array to JSON string
    const req = await fetch("https://qix.cloud/ajax/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: body.toString(),
    });
    let data = await req.json();
  }

  async function getSignatureOptions(contact_id = 0) {
    const prePdfData = await getData(pdfFile, allObjects, pdfName, pagesScale);
    //let pdfData = "data:application/pdf;base64,"  +  prePdfData[0];
    let pdfData = prePdfData[0];
    let optionData = prePdfData[1];
    let sign1 = "";
    let sign2 = "";
    let sign3 = "";
    let sign4 = "";
    let sign5 = "";
    let sign6 = "";
    for (let i = 0; i < optionData.length; i++) {
      const pageNum = optionData[i].pageIndex + 1;
      let pageWidth = optionData[i].pageWidth;
      let pageHeight = optionData[i].pageHeight;
      if (pageWidth == 792 && pageHeight == 612) {
        if (optionData[0].pageWidth == 612 && optionData[0].pageHeight == 792) {
          pageWidth = 612;
          pageHeight = 792;
        }
      }
      if (optionData[i].type == "text") {
        //console.log(optionData[i].pageWidth, optionData[i].pageHeight);
        let posX = optionData[i].x / pageWidth;
        let posY = (optionData[i].y + 15) / pageHeight;
        if (optionData[i].text == "Initial") {
          if (sign2 == "") {
            sign2 += pageNum + ":" + posX + ":" + posY;
          } else {
            sign2 += "," + pageNum + ":" + posX + ":" + posY;
          }
        } else if (optionData[i].text == "Date") {
          if (sign3 == "") {
            sign3 += pageNum + ":" + posX + ":" + posY;
          } else {
            sign3 += "," + pageNum + ":" + posX + ":" + posY;
          }
        } else if (optionData[i].text == "Name") {
          if (sign4 == "") {
            sign4 += pageNum + ":" + posX + ":" + posY;
          } else {
            sign4 += "," + pageNum + ":" + posX + ":" + posY;
          }
        } else {
          //console.log("optionData", i, optionData);
          if (sign5 == "") {
            sign5 +=
              pageNum + ":" + posX + ":" + posY + ":" + optionData[i].text;
          } else {
            sign5 +=
              "," +
              pageNum +
              ":" +
              posX +
              ":" +
              posY +
              ":" +
              optionData[i].text;
          }
        }
      } else {
        let posX = optionData[i].x / pageWidth;
        let posY = optionData[i].y / (pageHeight - 23);
        if (optionData[i].type == "quickSign") {
          let path = optionData[i].url.replace(blog.siteurl, "");
          if (sign6 == "") {
            sign6 += pageNum + ":" + posX + ":" + posY + ":" + path;
          } else {
            sign6 += "," + pageNum + ":" + posX + ":" + posY + ":" + path;
          }
        }
        if (optionData[i].type == "Signature") {
          if (sign1 == "") {
            sign1 += pageNum + ":" + posX + ":" + posY;
          } else {
            sign1 += "," + pageNum + ":" + posX + ":" + posY;
          }
        }
      }
    }
    let timestamp = 0;
    let option1 = sign1 + "--*--" + sign3;
    let option2 =
      sign2 +
      "--*--" +
      sign4 +
      "--*--" +
      sign5 +
      "--*--letter--*--" +
      timestamp +
      "--*--" +
      sign6;
    let display_name = "google_" + user.name;
    let body = {
      author_id: user.id,
      author: display_name,
      contact_id: contact_id,
      pdfData: pdfData,
      pdfName: pdfName,
      option1: option1,
      option2: option2,
      sendPDFType: sendPDFType,
      allObjects: allObjects,
    };
    return body;
  }
  async function saveTemplate() {
    let body = await getSignatureOptions();
    body.templateId = templateId;
    body.thumbnail = tempThumb;
    body.auth = "google";
    //console.log("body", body);
    const res = await fetch(
      blog.siteurl +
        "/wp-admin/admin-ajax.php?action=saveTemplateFromCloudFlare",
      {
        method: "POST",
        body: JSON.stringify(body),
      }
    );
    let data = await res.json();
    templateId = data;
    showSignatureTab = false;
  }
  async function showNewSignPanel(status) {
    newSignPanel = status;
  }
  async function getSignatures() {
    if (quickSigns.length < 1) {
      let body = {
        auth: "google",
        user_id: user.id,
        email: user.email,
      };
      const signs_req = await fetch(
        blog.siteurl + "/wp-admin/admin-ajax.php?action=getSignItQuickSign",
        {
          method: "POST",
          body: JSON.stringify(body),
        }
      );
      quickSigns = await signs_req.json();
    }
  }
  async function getFriends() {
    if (friends.length < 1) {
      let body = {
        auth: "google",
        user_id: user.id,
        name: user.name,
        email: user.email,
        blog_id: blog.blog_id,
      };
      const res = await fetch(
        blog.siteurl + "/wp-admin/admin-ajax.php?action=getGoogleFriends",
        {
          method: "POST",
          body: JSON.stringify(body),
        }
      );
      friends = await res.json();
    }
  }
  async function saveNewSignature() {
    let body = {
      name: newSignName,
      phone: newSignPhone,
      auth: "google",
      user_id: user.id,
    };
    const req = await fetch(
      blog.siteurl +
        "/wp-admin/admin-ajax.php?action=updateSignItQuickSignCloudflare",
      {
        method: "POST",
        body: JSON.stringify(body),
      }
    );
    let res = await req.json();
    signUrl = res.url;
    getSignatures();
  }
  async function checkallObjects() {
    newUserName = "";
    display_name_panel = false;
    for (var i = 0; i < allObjects.length; i++) {
      for (var j = 0; j < allObjects[i].length; j++) {
        let obj = allObjects[i][j];
        if (obj.type == "text") {
          if (obj.text == "Initial" || obj.text == "Name") {
            if (!display_name_panel) {
              showNewUserPanel();
              display_name_panel = true;
            }
          }
        }
      }
    }
    if (!display_name_panel) {
      sendSignature(0);
    }
  }
  async function deleteFriend(id) {
    if (confirm("Are you sure you want to remove the friend?") == true) {
      signatureTabLoading = true;
      let body = { id: id };
      await fetch(
        blog.siteurl + "/wp-admin/admin-ajax.php?action=deleteGoogleFriend",
        {
          method: "POST",
          body: JSON.stringify(body),
        }
      );
      getSignatures();
    }
  }
  async function displayTutorial(step) {
    if (step > 1) return;
    if (step == -1) {
      if (tutorialStep > 5) {
        tutorialStep = 0;
      } else {
        tutorialStep = tutorialStep + 1;
      }
    } else {
      tutorialStep = step;
    }
    if (tutorialStep == 3) {
      signatureMenu = true;
      loadDefaultDoc(0);
    } else if (tutorialStep == 4) {
      layerPos = { x: 50, y: 710 };
      selectedPageIndex = 1;
      addSignImage("", 1);
      //scrollToBottom(pdfWrap);
      window.scrollTo(0, document.body.scrollHeight);
    } else if (tutorialStep == 5) {
      layerPos = { x: 350, y: 710 };
      selectedPageIndex = 1;
      addTextField("Name", true);
    }
  }
  function displayLeftMenuFunc() {
    displayLeftMenu = !displayLeftMenu;
  }
  async function getMerusToken() {
    let body = {
      appId: user.appId,
    };
    const req = await fetch("https://qix.cloud/ajax/meruscase.php", {
      method: "POST",
      body: JSON.stringify(body),
    });
    let res = await req.json();
    merusToken = res.token;
    //console.log("merusToken", merusToken);
  }
  function updateSendToId(id) {
    sendToId = sendToId == id ? 0 : id;
    // sendToType = "";
    // merusToken = "";
    // caseFileId = "";
    // merusTokenPanel = false;
  }
  async function sendToFunc(event) {
    if (event.detail.error == "") {
      updateSendToId(event.detail.signId);
      alert("Sent successfully!");
    } else {
      alert(event.detail.error);
    }
  }
  async function handleLogoUpload(img) {
    logo = img.replace(
      "https://drive.google.com/uc?export=view&id=",
      "https://lh3.google.com/u/0/d/"
    );
    let body = { appId: user.id, email: user.email, logo: logo };
    const req = await fetch(blog.siteurl + "/ajax/logo.php", {
      method: "POST",
      body: JSON.stringify(body),
    });
    await req.json();
  }
  async function getEmailNotifyAvailable(status = "") {
    let body = { user_id: user.id };
    if (status != "") {
      body = { user_id: user.id, available: status };
    }
    const req = await fetch(
      blog.siteurl + "/ajax/sender_notify_available.php",
      {
        method: "POST",
        body: JSON.stringify(body),
      }
    );
    let res = await req.json();
    emailNotifyAvailable = res.available == "disable" ? false : true;
  }
</script>

<svelte:window
  bind:scrollX={wx}
  bind:scrollY={wy}
  on:dragenter|preventDefault
  on:dragover|preventDefault
  on:drop|preventDefault={onUploadPDF}
  on:contextmenu|preventDefault={onRightClick}
/>
<Tailwind />

{#if firstLoading}
  <style>
    .signaturetab {
      max-width: 90vw;
      position: absolute;
      right: 5px;
      top: 50px;
      min-width: 220px;
    }
    .signItem {
      border: 1px solid;
      border-radius: 5px;
      padding: 10px;
      margin: 0 10px 10px;
    }
    .signItem .action {
      justify-content: space-between;
    }
    .signItem .action > div > span,
    .signItem .action > div > a {
      margin-right: 20px;
      color: #4299e1;
      cursor: pointer;
    }
    .signItem .action > div > span.delete {
      margin-right: 0;
      color: red;
      cursor: pointer;
    }
    .signItem .signed {
      white-space: nowrap;
      margin-right: 30px;
    }
    .tab-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .tab-container .tabs {
      display: flex;
      position: relative;
      padding: 0.75rem;
    }
    .tab-container .tabs * {
      z-index: 2;
    }
    .tab-container input[type="radio"] {
      display: none;
    }
    .tab-container .tab {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 45px;
      width: 150px;
      font-weight: 500;
      border-radius: 99px;
      cursor: pointer;
      transition: color 0.15s ease-in;
    }
    .tab-container .notification {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2rem;
      height: 2rem;
      margin-left: 0.75rem;
      border-radius: 50%;
      background-color: #c1dcf1;
      transition: 0.15s ease-in;
    }
    .tab-container input[type="radio"]:checked + label {
      /* color: #185ee0; */
      background: #c1dcf1;
    }
    .tab-container input[type="radio"]:checked + label > .notification {
      background-color: #185ee0;
      color: #fff;
    }
    .tab-container input[id="radio-1"]:checked ~ .glider {
      transform: translateX(0);
    }
    .tab-container input[id="radio-2"]:checked ~ .glider {
      transform: translateX(100%);
    }
    .tab-container input[id="radio-3"]:checked ~ .glider {
      transform: translateX(200%);
    }
    .tab-container input[id="radio-4"]:checked ~ .glider {
      transform: translateX(300%);
    }
    .tab-container input[id="radio-5"]:checked ~ .glider {
      transform: translateX(400%);
    }
    .tab-container .glider {
      position: absolute;
      display: flex;
      height: 45px;
      width: 150px;
      background-color: #c1dcf1;
      z-index: 1;
      border-radius: 99px;
      transition: 0.25s ease-out;
    }
    .carousel-desktop {
      display: block;
    }
    .carousel-mobile {
      display: none;
    }
    @media (max-width: 767px) {
      .carousel-desktop {
        display: none;
      }
      .carousel-mobile {
        display: block;
      }
    }
    @media (max-width: 700px) {
      .tab-container .tabs {
        transform: scale(0.8);
      }
    }

    /* .carousel .slides>div>div>div {
        height: 8rem;
        width: 8rem;
      } */
    .defaultPdfWrap {
      padding: 2rem;
      background: #edf2f6;
      position: relative;
      border-radius: 10px;
      margin-top: 30px;
      /* max-width: 768px;
        margin: auto; */
    }
    .defaultPdfWrap h2 {
      position: relative;
      font-size: 1.5em;
      margin-bottom: 10px;
      font-weight: 500;
    }
    .defaultPdfWrap .carousel > ul {
      display: none;
    }
    .carousel .slides .item,
    .carousel .slides .firstItem {
      width: 100%;
      height: 100%;
      padding-right: 10%;
    }
    .carousel .slides .item > div,
    .carousel .slides .firstItem > div {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      overflow: hidden;
      border-radius: 10px;
    }
    .carousel .slides .item > div:hover,
    .carousel .slides .firstItem > div:hover {
      border: 2px solid blue;
    }
    .carousel .slides .title {
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      background: rgba(0, 0, 0, 0.5);
      padding: 10px;
      color: #fff;
    }
    .carousel .slides .item img {
      max-width: 100%;
    }
    .carousel .slides > div {
      display: flex;
    }
    .carousel span {
      display: inline-block;
      background: #b7d0f9;
      padding: 10px;
      border-radius: 100px;
    }
    .shadow-lg.p-5 {
      margin-top: 1rem !important;
    }
    .logged-out .carousel {
      max-width: 50%;
    }
    .logged-out .video {
      max-width: 50%;
    }
    @media (max-width: 1279px) {
      .logged-out .video iframe {
        max-height: 275px !important;
      }
    }
    @media (max-width: 1023px) {
      .logged-out .video iframe {
        max-height: 200px !important;
      }
    }
    .carousel .right {
      right: -50px !important;
    }
    .carousel .left {
      left: -50px !important;
    }
    @media (max-width: 767px) {
      .logged-out .video iframe {
        max-height: 300px !important;
      }
      .logged-out .carousel {
        max-width: 100%;
      }
      .logged-out .video {
        max-width: 100%;
        margin-top: 20px;
        padding-right: 25px;
      }
      .carousel .right {
        right: -25px !important;
      }
      .carousel .left {
        left: -25px !important;
      }
    }
    @media (max-width: 1045px) {
      .signaturetab {
        position: fixed;
        top: 68px;
      }
    }
    .logged-out {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;
    }
    /* .slides>div>div:nth-child(1),.slides>div>div:nth-child(2),.slides>div>div:nth-child(3),.slides>div>div:nth-child(4){
        opacity: 0;
      } */
    .tutorialWrap {
      height: 100vh;
      position: fixed;
      background: rgba(0, 0, 0, 0.1);
      top: 0px;
      left: 0px;
      z-index: 99;
    }
    .step {
      background: #edf2f6;
      padding: 3em;
      border-radius: 15px;
      max-width: 500px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .step.step2 {
      position: absolute;
      left: 50%;
      bottom: 50px;
      flex-direction: row;
      z-index: 999;
    }
    .step.step3,
    .step.step4 {
      position: fixed;
      left: 40%;
      top: 80px;
      flex-direction: row;
      z-index: 999;
      padding: 15px;
      width: 300px;
    }
    .step.step5,
    .step.step6 {
      position: fixed;
      left: 50%;
      top: 80px;
      flex-direction: row;
      z-index: 999;
      padding: 15px;
      width: 300px;
    }
    .step.step2 img,
    .step.step3 img,
    .step.step4 img {
      width: 80px;
      margin-right: 20px;
    }
    .step h1 {
      font-size: 1.7em;
    }
    .step p {
      font-size: 1.2em;
      text-align: center;
    }
    .border-green {
      border: 3px solid #00ef00;
    }
    .slides .border-green {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    .tabs.mobile {
      display: none;
    }
    .g-m-btn {
      padding: 5px 10px;
      background: #d1d6f6;
      border-radius: 10px;
      margin: 5px 20px 0 0;
    }
    @media screen and (max-width: 767px) {
      .step {
        width: 90vw !important;
        left: 5vw !important;
        top: 250px !important;
      }
      .delete-sign {
        max-width: 20px;
        max-height: 20px;
      }
      .signItem .action {
        flex-direction: column;
        align-items: flex-start;
      }
      .tabs.desktop {
        display: none;
      }
      .tabs.mobile {
        display: flex;
      }
    }
    .chat-page-header__user-icon {
      width: 28px;
      height: auto;
      aspect-ratio: 1 / 1;
      border-radius: 50%;
      cursor: pointer;
      transition: opacity 0.25s;
    }
  </style>
  {#if showMenu}
    <Menu {...pos} on:click={closeMenu} on:clickoutside={closeMenu}>
      <MenuOption
        on:click={() => addSignImage("", 1)}
        text="Get Signature"
        menutype="parent"
      />
      <MenuOption text="Saved Signatures" />
      {#each quickSigns as sign}
        <MenuOption
          on:click={() => addQuickSignImage(blog.siteurl + sign.image, 1)}
          text={sign.name}
          menutype="sub"
        />
      {/each}

      <MenuOption
        on:click={() => addTextField("Initial", true)}
        text="Initial"
        menutype="parent"
      />

      <MenuOption
        on:click={() => addTextField("Date", true)}
        text="Date"
        menutype="parent"
      />

      <MenuOption
        on:click={() => addTextField("Name", true)}
        text="Name"
        menutype="parent"
      />

      <MenuOption
        on:click={() => addTextField("Phone_Number", true)}
        text="Phone"
        menutype="parent"
      />

      <MenuOption
        on:click={() => addTextField("New Text Field", true)}
        text="Text"
        menutype="parent"
      />
      <MenuOption
        on:click={() => addTextField("Entry Required", true)}
        text="Entry(required)"
        menutype="parent"
      />
      {#each entry_requireds as object}
        <MenuOption
          on:click={() => addTextField(object.text, true)}
          text={object.lines[0]}
          menutype="sub"
        />
      {/each}
      <MenuOption
        on:click={() => addTextField("Entry Optional", true)}
        text="Entry(optional)"
        menutype="parent"
      />
      {#each entry_options as object}
        <MenuOption
          on:click={() => addTextField(object.text, true)}
          text={object.lines[0]}
          menutype="sub"
        />
      {/each}
    </Menu>
  {/if}
  {#if usersPanel}
    <style>
      .selectContainer > div {
        max-width: 460px;
        height: 60vh;
        overflow: auto;
      }
      .selectContainer .listContainer {
        background: #fff;
      }
      .selectContainer .clearSelect {
        height: auto;
      }
    </style>
    <div
      transition:fly={{ y: -200, duration: 500 }}
      class="w-full fixed z-10 justify-center top-0"
      style="margin-top: 50px;"
      use:clickOutside
      on:click_outside={closeUsersPanel}
    >
      <div
        class="border-b border-gray-300 bg-white shadow-lg p-5"
        style="max-width: 500px;margin: auto;position: relative;min-height: 130px;
}"
      >
        {#if sendingSign}
          <div
            class="flex items-center"
            style="justify-content: space-between;"
          >
            <span class:hidden={sendPDFType != "link"}>Generating Link</span>
            <img alt="" src="/loading.gif" style="max-width: 30px;" />
          </div>
        {:else if sendPDFRes}
          {#if sendPDFType != "link"}
            <div
              class="flex items-center"
              style="justify-content: space-between;"
            >
              <span>Sent Successfully</span><CheckMark />
            </div>
          {:else}
            <div id="clipboard" />
            <div
              class="flex items-center"
              style="justify-content: space-between;"
            >
              <span>{clipboardTxt}</span>
              <img
                src="filter.svg"
                class="cursor-pointer"
                style="width: 30px; margin-left: 15px;"
                on:click={copy}
                alt="Copy"
              />
            </div>
          {/if}
        {:else if !newUserPanel}
          {#if !display_name_panel}
            <label
              class="flex items-center h-full hover:bg-gray-500 cursor-pointer mb-4"
              class:hidden={sendPDFType != "link"}
              style="padding: 5px 10px;"
              on:click={checkallObjects}
              >Link you don’t need to select a contact to sign document
            </label>
            <!-- <Select items={users} placeholder="Select a user"  on:select={handleUserSelect}></Select> -->
            {#if users.length > 0 && sendPDFType != "email"}
              <Selectbox
                items={users}
                type={sendPDFType}
                placeholder="Select a user"
                {merusToken}
                appId={user.appId}
                on:select={handleUserSelect}
              />
            {/if}
          {/if}
          {#if sendingNewUser}
            <img
              alt=""
              src="/loading.gif"
              style="max-width: 30px;"
              class:hidden={sendingNewUserType > 0}
            />
            <div
              class="flex items-center"
              class:hidden={sendingNewUserType < 1}
              style="justify-content: space-between;"
            >
              <span>User Added Successfully</span>
              <button
                class="w-30 text-white font-bold py-1 px-3 md:px-4 mr-3 md:mr-4 rounded bg-blue-500 hover:bg-blue-700"
                on:click={continueSendSignature}>Continue</button
              >
              <CheckMark />
            </div>
          {:else}
            <div
              class="flex"
              style="justify-content: space-between;align-items: flex-start;"
            >
              <button
                class="w-30 text-white font-bold py-1 px-3 md:px-4 mr-3 md:mr-4 rounded bg-blue-500 hover:bg-blue-700"
                style="margin-top: 15px;"
                on:click={showNewUserPanel}
                class:hidden={newUserPanel || sendPDFType != "sms"}
                >New User</button
              >
              <div class:hidden={sendPDFType != "email"}>
                <div
                  class="flex"
                  style="justify-content: space-between;margin-top: 15px;"
                >
                  <button
                    class="w-30 text-white font-bold py-1 px-3 md:px-4 mr-3 md:mr-4 rounded bg-blue-500 hover:bg-blue-700"
                    on:click={() => showEmailPanel(true)}
                    class:hidden={emailPanel}>Enter Email</button
                  >
                  <!-- <div class:hidden={!emailPanel}> -->
                  {#if emailPanel}
                    <Selectbox
                      items={chatUsers}
                      type="email"
                      placeholder="Enter Email"
                      {merusToken}
                      appId={user.appId}
                      on:select={handleChatUserSelect}
                      on:enterEmail={handleChatUserEnterEmail}
                    />
                  {/if}
                  <!-- </div> -->
                  <!-- <input
                    placeholder="Enter Email"
                    type="email"
                    class="flex-grow bg-transparent py-1 px-2 w-full"
                    class:hidden={!emailPanel}
                    style="border: 1px solid;border-radius: 5px;"
                    on:keyup={checkIfEmailFunc}
                    bind:value={shareEmail}
                  /> -->
                  <span
                    on:click={() => showEmailPanel(false)}
                    class="cursor-pointer"
                    class:hidden={!emailPanel}
                    style="float: right;font-size: 1.5rem;margin-left: 10px;"
                    >×</span
                  >
                </div>
                <!-- <div
                  class="flex items-center"
                  style="justify-content: space-between;margin-top: 15px;"
                >
                  <button
                    class="w-30 text-white font-bold py-1 px-3 md:px-4 mr-3 md:mr-4 rounded bg-blue-500 hover:bg-blue-700"
                    on:click={() => showEmailCCPanel(true)}
                    class:hidden={emailCCPanel}>Add CC</button
                  >
                  <input
                    placeholder="Add CC"
                    type="email"
                    class="flex-grow bg-transparent py-1 px-2 w-full"
                    class:hidden={!emailCCPanel}
                    style="border: 1px solid;border-radius: 5px;"
                    bind:value={shareEmailcc}
                  />
                  <span
                    on:click={() => showEmailCCPanel(false)}
                    class="cursor-pointer"
                    class:hidden={!emailCCPanel}
                    style="float: right;font-size: 1.5rem;margin-left: 10px;"
                    >×</span
                  >
                </div> -->
              </div>
              <div
                class={sendPDFType}
                style="margin-top: 15px;"
                class:hidden={sendPDFType == "link"}
              >
                <select bind:value={smsLang} on:change={handleLanguageSelect}>
                  {#each languages as lang}
                    <option value={lang.value}>{lang.label}</option>
                  {/each}
                </select>
              </div>
            </div>
            <div
              class:hidden={sendPDFType != "email"}
              style="position: absolute; top: 65px; right: 0;"
            >
              {#if checkIfEmail}
                <button
                  class="text-white font-bold py-1 px-3 md:px-4 mr-3 md:mr-4 rounded bg-blue-500 hover:bg-blue-700"
                  style="
                  margin-top: 15px;
                  display: flex;
                  align-items: center;
                  margin-left: auto;"
                  on:click={handleSendEmail}
                >
                  <img
                    alt=""
                    src="mail_w.png"
                    style="width: 25px; margin-right: 15px;"
                  /> Send</button
                >
              {:else}
                <button
                  class="text-white font-bold py-1 px-3 md:px-4 mr-3 md:mr-4 rounded"
                  style="
                  margin-top: 15px;
                  display: flex;
                  align-items: center;
                  margin-left: auto;
                  background: grey"
                  ><img
                    alt=""
                    src="mail_w.png"
                    style="width: 25px; margin-right: 15px;"
                  /> Send</button
                >
              {/if}
            </div>
          {/if}
        {:else}
          <span
            on:click={hideNewUserPanel}
            class="cursor-pointer"
            class:hidden={!newUserPanel}
            style="float: right;font-size: 1.5rem;margin-top: -1rem;">×</span
          >
          <input
            placeholder="First Name, Last Name"
            type="text"
            class="flex-grow bg-transparent py-1 px-2 w-full"
            class:hidden={display_name_panel}
            style="border: 1px solid;border-radius: 5px;"
            bind:value={userName}
          />
          <input
            placeholder="Phone Number"
            type="text"
            class="flex-grow bg-transparent py-1 px-2 w-full"
            class:hidden={display_name_panel}
            style="border: 1px solid;border-radius: 5px;margin-top: 10px;"
            maxlength="14"
            on:keyup={checkUserPhone}
            bind:value={userPhone}
          />
          <button
            class="w-30 text-white font-bold py-1 px-3 md:px-4 mr-3 md:mr-4 rounded bg-blue-500 hover:bg-blue-700"
            style="margin-top: 15px;"
            on:click={addNewUser}
            class:hidden={display_name_panel}>Add User</button
          >
          <input
            placeholder="Insert the name of the Signer"
            type="text"
            class="flex-grow bg-transparent py-1 px-2 w-full"
            style="border: 1px solid;border-radius: 5px;margin-top: 10px;"
            class:hidden={!display_name_panel}
            bind:value={newUserName}
          />
          <button
            class="w-30 text-white font-bold py-1 px-3 md:px-4 mr-3 md:mr-4 rounded bg-blue-500 hover:bg-blue-700"
            style="margin-top: 15px;"
            class:hidden={!display_name_panel}
            on:click={() => sendSignature(0)}>Next</button
          >
        {/if}
      </div>
    </div>
  {/if}
  {#if sharePanel}
    <style>
      .shareContainer > div {
        max-width: 460px;
        height: 60vh;
        overflow: auto;
      }
      .sshareContainer .listContainer {
        background: #fff;
      }
    </style>
    <div
      transition:fly={{ y: -200, duration: 500 }}
      class="w-full fixed z-10 justify-center top-0"
      style="margin-top: 50px;"
      use:clickOutside
      on:click_outside={handleClickOutsideSharePanel}
    >
      <div
        class="border-b border-gray-300 bg-white shadow-lg p-5"
        style="max-width: 160px; margin: auto;"
      >
        <label
          class="flex items-center h-full hover:bg-gray-500
          cursor-pointer"
          on:click={() => sendPDFfunc("link")}
        >
          <img alt="" src="link.svg" style="width: 30px; margin-right: 15px;" />
          <span>Link</span>
        </label>

        <!-- <label
          class="flex items-center h-full hover:bg-gray-500
          cursor-pointer"
          on:click={() => sendPDFfunc("sms")}
        >
          <img alt="" src="sms.svg" style="width: 30px; margin-right: 15px;" />
          <span>SMS</span>
        </label> -->

        <label
          class="flex items-center h-full hover:bg-gray-500
          cursor-pointer"
          on:click={savePDF}
        >
          <img
            alt=""
            src="download.svg"
            style="width: 30px; margin-right: 15px;"
          />
          <span>Download</span>
        </label>
        <label
          class="flex items-center h-full hover:bg-gray-500
          cursor-pointer"
          on:click={() => sendPDFfunc("email")}
        >
          <img alt="" src="mail.svg" style="width: 30px; margin-right: 15px;" />
          <span>Email</span>
        </label>
      </div>
    </div>
  {/if}
  <main
    bind:this={pdfWrap}
    class="flex flex-col items-center py-16 bg-gray-100 min-h-screen"
    style="padding-top: 5rem; padding-bottom: 0;"
  >
    <Background />
    <div
      class="fixed z-10 top-0 left-0 right-0 flex justify-center items-center
        bg-gray-200 border-b border-gray-300"
      style="height: 4rem;"
    >
      <div
        on:click={displayLeftMenuFunc}
        on:mouseover={displayLeftMenuFunc}
        class="flex items-center justify-center hidden md:flex"
        style="position: absolute; left: 30px;cursor: pointer;"
      >
        <img
          src="img/logo_2.png"
          alt="Sign"
          style="margin-right: 10px;max-width: 30px; width: 100%;"
        />Sign
      </div>
      <input
        type="file"
        name="pdf"
        id="pdf"
        on:change={onUploadPDF}
        class="hidden"
      />
      <input
        type="file"
        id="image"
        name="image"
        class="hidden"
        on:change={onUploadImage}
      />
      <div
        class="relative mr-3 flex h-8 bg-gray-400 rounded-sm
          md:mr-4"
      >
        {#if selectedPageIndex < 0}
          <label
            class="flex items-center justify-center h-full w-8 hover:bg-gray-500
              cursor-pointer"
            class:cursor-not-allowed={selectedPageIndex < 0}
            class:bg-gray-500={selectedPageIndex < 0}
          >
            <img src="image.svg" alt="An icon for adding images" />
          </label>
        {:else}
          <label
            class="flex items-center justify-center h-full w-8 hover:bg-gray-500
              cursor-pointer"
            for="image"
            class:cursor-not-allowed={selectedPageIndex < 0}
            class:bg-gray-500={selectedPageIndex < 0}
          >
            <img src="image.svg" alt="An icon for adding images" />
          </label>
        {/if}
        <label
          class="flex items-center justify-center h-full w-8 hover:bg-gray-500
            cursor-pointer"
          class:border-green={tutorialStep == 4}
          class:cursor-not-allowed={selectedPageIndex < 0}
          class:bg-gray-500={selectedPageIndex < 0}
          on:click={onAddTextField}
        >
          <img src="text.svg" alt="An icon for adding text" />
        </label>
        <label
          class="flex items-center justify-center h-full w-8 hover:bg-gray-500
            cursor-pointer"
          on:click={showSignatureMenu}
          class:border-green={tutorialStep == 3}
          class:cursor-not-allowed={selectedPageIndex < 0}
          class:bg-gray-500={selectedPageIndex < 0}
        >
          <img src="gesture.svg" alt="An icon for adding drawing" />
        </label>
        {#if signatureMenu}
          <div
            class="bg-gray-200 border-b border-gray-300 py-1 px-2 rounded"
            style="position: absolute; left: 0; top: 50px;width: 190px;"
            use:clickOutside
            on:click_outside={handleClickOutsideSignatureMenu}
          >
            <label
              class="flex items-center py-1 h-full hover:bg-gray-500
                cursor-pointer"
              on:click={onAddDrawing}
            >
              <img
                src="draw.svg"
                alt="Draw Signature"
                class="mr-3"
                style="width: 24px;"
              /> <span>Draw Signature</span>
            </label>
            <label
              class="flex items-center py-1 h-full hover:bg-gray-500
                cursor-pointer"
              class:border-green={tutorialStep == 3}
              on:click={() => addSignImage("", 0)}
            >
              <img
                src="3p.svg"
                alt="Get Signature"
                class="mr-3"
                style="width: 24px;"
              /> <span>Get Signature</span>
            </label>
            <label class="flex items-center py-1 h-full">
              <img
                src="save.svg"
                alt="Saved Signature"
                class="mr-3"
                style="width: 24px;"
              /> <span>Saved Signature</span>
            </label>
            {#each quickSigns as sign}
              <label
                class="flex items-center py-1 h-full hover:bg-gray-500 px-2
                  cursor-pointer"
                on:click={() => addQuickSignImage(blog.siteurl + sign.image, 0)}
                style="margin-left: 25px;"
              >
                <span>- {sign.name}</span>
              </label>
            {/each}
          </div>
        {/if}
      </div>
      <div class="justify-center mr-3 md:mr-4 w-full max-w-xs hidden md:flex">
        <img src="/edit.svg" class="mr-2" alt="a pen, edit pdf name" />
        <input
          placeholder="Rename your PDF here"
          type="text"
          class="flex-grow bg-transparent"
          bind:value={pdfName}
        />
      </div>
      {#if user.id != ""}
        <button
          on:click={shareFunc}
          class="w-20 text-white font-bold py-1 px-3
          md:px-4 mr-3 md:mr-4 rounded"
          class:border-green={tutorialStep == 5}
          class:cursor-not-allowed={selectedPageIndex < 0}
          class:bg-gray-500={selectedPageIndex < 0}
          class:bg-blue-500={selectedPageIndex >= 0}
          class:hover:bg-blue-700={selectedPageIndex >= 0}
        >
          SHARE
        </button>
      {:else}
        <button
          class="w-20 text-white font-bold py-1 px-3
          md:px-4 mr-3 md:mr-4 rounded cursor-not-allowed bg-gray-500"
          class:border-green={tutorialStep == 5}
        >
          SHARE
        </button>
      {/if}
      {#if user.id != ""}
        <!-- <div class="relative">
          <label
            class="flex items-center justify-center h-full md:flex cursor-pointer"
            on:click={showlogoutpanel}
          >
            {user.name}
          </label>
          {#if logoutpanel}
            <label
              class="whitespace-no-wrap round py-1 px-3 cursor-pointer"
              on:click={logoutFunc}
              use:clickOutside
              on:click_outside={handleClickOutsideLogoutPanel}
              style="position: absolute; background: #edf2f7; top: 50px; left: 0;"
            >
              Log out
            </label>
          {/if}
        </div> -->
        <div style="position: relative;">
          <button
            on:click={showSignatureTabFunc}
            class="w-10 py-1 flex items-center justify-center md:flex"
            class:border-green={tutorialStep == 6}
            style="border-radius: 50%;width: 30px;height: 30px;color: #fff;font-weight: bold;"
          >
            <!-- <img alt="" src="/menu.png" /> -->
            <!-- {user.name} -->
            {#if user.img != ""}
              <img
                class="chat-page-header__user-icon"
                src={user.img}
                alt="User"
              />
            {:else}
              <img
                class="chat-page-header__user-icon"
                src="/account_icon.png"
                alt="User"
              />
            {/if}
          </button>
          {#if showSignatureTab}
            <div
              class="signaturetab bg-gray-200 border-b border-gray-300 py-1 px-2 rounded"
              use:clickOutside
              on:click_outside={handleClickOutsideSignatureTab}
            >
              <div
                class="w-full flex items-center"
                style="justify-content: flex-end; font-size: 25px;"
              >
                <span
                  on:click={hideSignatureTabFunc}
                  class="px-1 cursor-pointer">×</span
                >
              </div>

              <div class="w-full flex items-center" style="padding-left: 10px;">
                {#if user.google}
                  <img
                    alt=""
                    src="/google1.png"
                    style="width: 20px;margin-right: 0.75rem;"
                  />
                {/if}
                {#if user.microsoft}
                  <img
                    alt=""
                    src="/microsoft_logo.png"
                    style="width: 20px;margin-right: 0.75rem;"
                  />
                {/if}
                {user.email}
                <label
                  class="whitespace-no-wrap round py-1 px-3 cursor-pointer"
                  on:click={logout}
                  use:clickOutside
                  on:click_outside={handleClickOutsideLogoutPanel}
                  style="color:rgb(24, 94, 224)"
                >
                  Log out
                </label>
              </div>
              <div
                class="w-full flex items-center"
                style="padding-left: 10px;font-size: .8em"
              >
                AppID: {user.appId}
              </div>
              <div
                class="w-full flex items-center"
                style="padding: 15px 0 15px 10px;"
              >
                <ImageUploader
                  src={logo}
                  {user}
                  on:upload={(e) => handleLogoUpload(e.detail.image)}
                />
                <div style="margin-left: 20px;font-size: 1.3em;">
                  Company Logo
                </div>
              </div>
              <div class="tab-container">
                <div class="tabs desktop">
                  <input type="radio" id="radio-1" name="tabs" checked />
                  <label
                    on:click={() => changeSignatureTabFunc("pending")}
                    class="tab"
                    for="radio-1"
                    >Pending
                    {#if pendingSignature.length > 0}
                      <span class="notification">{pendingSignature.length}</span
                      >
                    {/if}
                  </label>
                  <input type="radio" id="radio-2" name="tabs" />
                  <label
                    on:click={() => changeSignatureTabFunc("completed")}
                    class="tab"
                    for="radio-2"
                    >Completed
                    {#if completedSignature.length > 0}
                      <span class="notification"
                        >{completedSignature.length}</span
                      >
                    {/if}
                  </label>
                  <input type="radio" id="radio-3" name="tabs" />
                  <label
                    on:click={() => changeSignatureTabFunc("templates")}
                    class="tab"
                    for="radio-3"
                    >Templates
                    {#if templates.length > 0}
                      <span class="notification">{templates.length}</span>
                    {/if}
                  </label>

                  <input type="radio" id="radio-4" name="tabs" />
                  <label
                    on:click={() => changeSignatureTabFunc("signatures")}
                    class="tab"
                    for="radio-4"
                    >Signatures
                    {#if quickSigns.length > 0}
                      <span class="notification">{quickSigns.length}</span>
                    {/if}
                  </label>
                  <!-- {#if user_data['auth'] == "google"} -->
                  <input type="radio" id="radio-5" name="tabs" />
                  <label
                    on:click={() => changeSignatureTabFunc("friends")}
                    class="tab"
                    for="radio-5"
                    >Team
                    {#if friends.length > 0}
                      <span class="notification">{friends.length}</span>
                    {/if}
                  </label>
                  <!-- {/if} -->
                  <span class="glider" />
                </div>
                <div class="tabs mobile">
                  <div>
                    <input type="radio" id="radio-11" name="tabs" checked />
                    <label
                      on:click={() => changeSignatureTabFunc("pending")}
                      class="tab"
                      for="radio-11"
                      >Pending
                      {#if pendingSignature.length > 0}
                        <span class="notification"
                          >{pendingSignature.length}</span
                        >
                      {/if}
                    </label>
                    <input type="radio" id="radio-22" name="tabs" />
                    <label
                      on:click={() => changeSignatureTabFunc("completed")}
                      class="tab"
                      for="radio-22"
                      >Completed
                      {#if completedSignature.length > 0}
                        <span class="notification"
                          >{completedSignature.length}</span
                        >
                      {/if}
                    </label>
                  </div>
                  <div>
                    <input type="radio" id="radio-33" name="tabs" />
                    <label
                      on:click={() => changeSignatureTabFunc("templates")}
                      class="tab"
                      for="radio-33"
                      >Templates
                      {#if templates.length > 0}
                        <span class="notification">{templates.length}</span>
                      {/if}
                    </label>

                    <input type="radio" id="radio-44" name="tabs" />
                    <label
                      on:click={() => changeSignatureTabFunc("signatures")}
                      class="tab"
                      for="radio-44"
                      >Signatures
                      {#if quickSigns.length > 0}
                        <span class="notification">{quickSigns.length}</span>
                      {/if}
                    </label>
                  </div>
                  <div>
                    <!-- {#if user_data['auth'] == "google"} -->
                    <input type="radio" id="radio-55" name="tabs" />
                    <label
                      on:click={() => changeSignatureTabFunc("friends")}
                      class="tab"
                      for="radio-55"
                      >Team
                      {#if friends.length > 0}
                        <span class="notification">{friends.length}</span>
                      {/if}
                    </label>
                    <!-- {/if} -->
                  </div>
                </div>
              </div>
              {#if signatureTabLoading}
                <div class="w-full flex items-center justify-center">
                  <img alt="" src="/loading.gif" style="max-width: 30px;" />
                </div>
              {:else}
                <div style="max-height: 50vh; overflow: auto;">
                  {#if selectedSginTab === "pending"}
                    <div style="margin: 10px">
                      <label
                        class="whitespace-no-wrap bg-blue-500 hover:bg-blue-700 text-white
                  font-bold py-1 px-3 md:px-4 rounded mr-3 cursor-pointer md:mr-4"
                        for="pdf"
                      >
                        Choose New PDF
                      </label>
                      <input
                        placeholder="Search Pending"
                        type="text"
                        class="flex-grow bg-transparent py-1 px-2 w-full"
                        style="border: 1px solid;border-radius: 5px;margin-top: 1em;"
                        bind:value={searchKeyPending}
                      />
                    </div>
                    {#if pendingSignature.length > 0}
                      {#each pendingSignature as sign}
                        {#if searchKeyPending == "" || sign.attachmentName
                            .toLowerCase()
                            .includes(searchKeyPending.toLowerCase())}
                          <div class="signItem">
                            <div>
                              <div>{sign.attachmentName}</div>
                            </div>
                            <div class="action w-full flex items-center">
                              <span class="signed">Signed: {sign.date}</span>
                              <div class="flex items-center">
                                <span on:click={() => resendSignature(sign.id)}
                                  >Resend</span
                                >
                                <span
                                  on:click={() =>
                                    shareSignature(
                                      sign.attachmentName,
                                      "",
                                      blog.siteurl + "/s/" + sign.id
                                    )}>Share</span
                                >
                                <span
                                  class="delete"
                                  on:click={() => deleteSignature(sign.id)}
                                  >Delete</span
                                >
                              </div>
                            </div>
                          </div>
                        {/if}
                      {/each}
                    {:else}
                      <div class="text-center">There is no data.</div>
                    {/if}
                  {/if}
                  {#if selectedSginTab === "completed"}
                    <div
                      class="flex items-center justify-center p-2"
                      style="gap: 10px;"
                    >
                      <b>Notify (email)</b>
                      <div>
                        <input
                          type="radio"
                          id="available-1"
                          name="email_available"
                          checked={emailNotifyAvailable}
                        />
                        <label
                          on:click={() => getEmailNotifyAvailable("enable")}
                          class="tab"
                          for="available-1"
                          >Enable
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="available-2"
                          name="email_available"
                          checked={!emailNotifyAvailable}
                        />
                        <label
                          on:click={() => getEmailNotifyAvailable("disable")}
                          class="tab"
                          for="available-2"
                          >Disable
                        </label>
                      </div>
                    </div>
                    {#if completedSignature.length > 0}
                      <div style="margin: 10px">
                        <input
                          placeholder="Search Completed"
                          type="text"
                          class="flex-grow bg-transparent py-1 px-2 w-full"
                          style="border: 1px solid;border-radius: 5px;"
                          bind:value={searchKeyCompleted}
                        />
                      </div>
                      {#each completedSignature as sign}
                        {#if searchKeyCompleted == "" || sign.attachmentName
                            .toLowerCase()
                            .includes(searchKeyCompleted.toLowerCase())}
                          <div class="signItem">
                            <div>
                              <div>{sign.attachmentName}</div>
                            </div>
                            <div class="action w-full flex items-center">
                              <span class="signed">Signed: {sign.date}</span>
                              <div class="flex items-center">
                                <span
                                  class="sendTo"
                                  on:click={() => updateSendToId(sign.id)}
                                  >Send To</span
                                >
                                <a
                                  href={"https://qix.cloud/" + sign.signedPdf}
                                  target="_blank">View</a
                                >
                                <span
                                  class="delete"
                                  on:click={() => deleteSignature(sign.id)}
                                  >Delete</span
                                >
                              </div>
                            </div>
                            {#if users.length > 0}
                              <SendTo
                                {chatUsers}
                                {users}
                                {sign}
                                {blog}
                                {user}
                                {sendToId}
                                {merusToken}
                                on:update={sendToFunc}
                              />
                            {/if}
                          </div>
                        {/if}
                      {/each}
                    {:else}
                      <div class="text-center">There is no data.</div>
                    {/if}
                  {/if}
                  {#if selectedSginTab === "templates"}
                    <div style="margin: 10px">
                      <button
                        on:click={saveTemplate}
                        class="whitespace-no-wrap bg-blue-500 hover:bg-blue-700 text-white
                  font-bold py-1 px-3 md:px-4 rounded mr-3 cursor-pointer md:mr-4"
                        class:hidden={selectedPageIndex < 0}
                      >
                        Save as Template
                      </button>
                      <!-- <input
                        placeholder="Search Template"
                        type="text"
                        class="flex-grow bg-transparent py-1 px-2 w-full"
                        style="border: 1px solid;border-radius: 5px;margin-top: 1em;"
                        bind:value={searchKeytemplate}
                      /> -->
                    </div>
                    <PdfTemplate
                      {templates}
                      {blog}
                      {user}
                      on:select={handleLoadTemplate}
                    />
                  {/if}

                  {#if selectedSginTab === "signatures"}
                    <div style="margin: 10px">
                      <input
                        placeholder="Search Signatures"
                        type="text"
                        class="flex-grow bg-transparent py-1 px-2 w-full"
                        style="border: 1px solid;border-radius: 5px;margin-bottom: 15px;"
                        bind:value={searchKeySignature}
                      />
                      <div class="w-full flex items-center">
                        <button
                          on:click={() => showNewSignPanel(true)}
                          class="whitespace-no-wrap bg-blue-500 hover:bg-blue-700 text-white
                    font-bold py-1 px-3 md:px-4 rounded mr-3 cursor-pointer md:mr-4"
                        >
                          Add New Signature
                        </button>
                        {#if signUrl != ""}
                          <div class="w-full flex items-center">
                            <span>{signUrl}</span>
                            <img
                              src="filter.svg"
                              class="cursor-pointer"
                              style="width: 30px; margin-left: 15px;"
                              on:click={copySignUrl}
                              alt="Copy"
                            />
                            <div id="signclipboard" />
                          </div>
                        {/if}
                      </div>
                      <div class:hidden={!newSignPanel}>
                        <span
                          on:click={() => showNewSignPanel(false)}
                          class="cursor-pointer"
                          style="float: right;font-size: 1.5rem;margin-top: -1rem;"
                          >×</span
                        >
                        <div class="w-full flex items-center">
                          <input
                            placeholder="Insert Signature Name"
                            type="text"
                            class="flex-grow bg-transparent py-1 px-2 w-full mr-4"
                            style="border: 1px solid;border-radius: 5px;"
                            bind:value={newSignName}
                          />
                          <input
                            placeholder="Insert Signature Phone"
                            type="text"
                            class="flex-grow bg-transparent py-1 px-2 w-full mr-4"
                            style="border: 1px solid;border-radius: 5px;"
                            bind:value={newSignPhone}
                          />
                          <button
                            on:click={saveNewSignature}
                            class="whitespace-no-wrap bg-blue-500 hover:bg-blue-700 text-white
                        font-bold py-1 px-3 md:px-4 rounded mr-3 cursor-pointer"
                          >
                            Send
                          </button>
                        </div>
                      </div>
                    </div>
                    {#if quickSigns.length > 0}
                      {#each quickSigns as sign}
                        {#if searchKeySignature == "" || sign.name
                            .toLowerCase()
                            .includes(searchKeySignature.toLowerCase())}
                          <div class="signItem">
                            <div class="action w-full flex items-center">
                              {#if sign.image == ""}
                                <div />
                              {:else}
                                <img
                                  alt=""
                                  src="https://qix.cloud{sign.image}"
                                  style="max-width: 100px;"
                                />
                              {/if}
                              <div class="">
                                <div>{sign.name}</div>
                                <span
                                  class="delete"
                                  on:click={() => deleteQuickSignature(sign.id)}
                                  >Delete</span
                                >
                              </div>
                            </div>
                          </div>
                        {/if}
                      {/each}
                    {:else}
                      <div class="text-center">There is no data.</div>
                    {/if}
                  {/if}

                  {#if selectedSginTab === "friends"}
                    <div style="margin: 10px">
                      <input
                        placeholder="Search Team"
                        type="text"
                        class="flex-grow bg-transparent py-1 px-2 w-full"
                        style="border: 1px solid;border-radius: 5px;margin-bottom:15px;"
                        bind:value={searchKeyFriend}
                      />
                      <div class:hidden={!inviteFriendPanel}>
                        <div class="w-full flex items-center">
                          <div id="inviteclipboard" />
                          <div
                            class="flex items-center"
                            style="justify-content: space-between;width: 100%;"
                            class:hidden={inviteClipboardTxt == ""}
                          >
                            <span style="word-break: break-all;"
                              >{inviteClipboardTxt}</span
                            >
                            <img
                              src="filter.svg"
                              class="cursor-pointer"
                              style="width: 30px; margin-left: 15px;"
                              on:click={copyInviteUrl}
                              alt="Copy"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {#if friends.length > 0}
                      {#each friends as friend}
                        {#if searchKeyFriend == "" || friend.email.includes(searchKeyFriend.toLowerCase())}
                          <div
                            class="signItem flex items-center"
                            style="justify-content: space-between;"
                          >
                            <div>
                              <div class="flex items-center" style="gap: 10px;">
                                {#if friend.id === user.appId}
                                  <svg
                                    class="member__star"
                                    xmlns="http://www.w3.org/2000/svg"
                                    version="1.1"
                                    width="12"
                                    height="12"
                                    x="0"
                                    y="0"
                                    viewBox="0 0 511.987 511"
                                    style="enable-background:new 0 0 512 512"
                                    xml:space="preserve"
                                    ><g
                                      ><path
                                        fill="#ffc107"
                                        d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                                        data-original="#ffc107"
                                        class=""
                                      ></path></g
                                    ></svg
                                  >
                                {/if}
                                {friend.email}
                                <span
                                  style={Date.now() - friend.loggedIn >
                                  432000000
                                    ? "color: red;font-size: 12px;"
                                    : "font-size: 12px;"}
                                >
                                  &nbsp;&nbsp;( Logged In {timeAgo(
                                    friend.loggedIn
                                  )} )
                                </span>
                              </div>
                            </div>
                            <!-- <div class="action">
                              <div class="flex items-center">
                                <span
                                  class="delete"
                                  on:click={() => deleteFriend(friend.id)}
                                  >Delete</span
                                >
                              </div>
                            </div> -->
                          </div>
                        {/if}
                      {/each}
                    {:else}
                      <div class="text-center">There is no data.</div>
                    {/if}
                  {/if}
                </div>
              {/if}
            </div>
          {/if}
        </div>
      {:else}
        <button
          on:click={showLoginPopup}
          class="w-20 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3
            md:px-4 mr-3 md:mr-4 rounded"
        >
          Login
        </button>
      {/if}
    </div>
    {#if selectedPageIndex < 0}
      <div class="container">
        <div class="defaultPdfWrap">
          <div class="flex items-center">
            <h2>Start a New Document</h2>
            <button
              on:click={() => displayTutorial(1)}
              class="py-1 px-3 rounded"
              style="border: 1px solid #000;display: flex;background: #eee;margin-left: 15px; margin-bottom: 10px;min-width: 110px;"
            >
              <img
                alt=""
                src="/info.svg"
                style="width: 25px; margin-right: 5px;"
              /> Tutorial
            </button>
          </div>
          {#if user.id != ""}
            <div class="carousel-desktop">
              <Carousel perPage="4" loop={false}>
                <div class="firstItem" for="pdf">
                  <div>
                    <label
                      for="pdf"
                      class="cursor-pointer"
                      style="width: 100%;"
                    >
                      <img alt="" src="/newPDF.png" style="margin: auto;" />
                      <div class="title">Upload New PDF</div>
                    </label>
                  </div>
                </div>
                {#each defaultDocs as doc, index (index)}
                  <div class="item">
                    <div>
                      <img
                        src="/pdf-imgs/{doc.replace('.pdf', '.png')}"
                        alt={doc}
                      />
                      <div
                        class="title cursor-pointer"
                        class:border-green={tutorialStep == 2 && index == 0}
                        on:click={() => loadDefaultDoc(index)}
                      >
                        {doc}
                      </div>
                    </div>
                  </div>
                {/each}
                <span slot="left-control">
                  <img src="/img/left-arrow-svgrepo-com.svg" alt="" />
                </span>
                <span slot="right-control">
                  <img src="/img/right-arrow-svgrepo-com.svg" alt="" />
                </span>
              </Carousel>
            </div>
            <div class="carousel-mobile">
              <Carousel perPage="2" loop={false}>
                <div class="firstItem" for="pdf">
                  <div>
                    <label
                      for="pdf"
                      class="cursor-pointer"
                      style="width: 100%;"
                    >
                      <img alt="" src="/newPDF.png" style="margin: auto;" />
                      <div class="title">Upload New PDF</div>
                    </label>
                  </div>
                </div>
                {#each defaultDocs as doc, index (index)}
                  <div class="item">
                    <div>
                      <img
                        src="/pdf-imgs/{doc.replace('.pdf', '.png')}"
                        alt={doc}
                      />
                      <div
                        class="title cursor-pointer"
                        class:border-green={tutorialStep == 2 && index == 0}
                        on:click={() => loadDefaultDoc(index)}
                      >
                        {doc}
                      </div>
                    </div>
                  </div>
                {/each}
                <span slot="left-control">
                  <img src="/img/left-arrow-svgrepo-com.svg" alt="" />
                </span>
                <span slot="right-control">
                  <img src="/img/right-arrow-svgrepo-com.svg" alt="" />
                </span>
              </Carousel>
            </div>
          {:else}
            <div class="logged-out flex items-center">
              <Carousel perPage="2" loop={false}>
                <div class="firstItem" for="pdf">
                  <div>
                    <label
                      for="pdf"
                      class="cursor-pointer"
                      style="width: 100%;"
                    >
                      <img alt="" src="/newPDF.png" style="margin: auto;" />
                      <div class="title">Upload New PDF</div>
                    </label>
                  </div>
                </div>
                <div class="item">
                  <div>
                    <img src="/pdf-imgs/Fee_Disclosure_Statement.png" />
                    <div
                      class="title cursor-pointer"
                      class:border-green={tutorialStep == 2}
                      on:click={() => loadDefaultDoc(1)}
                    >
                      {defaultDocs[1]}
                    </div>
                  </div>
                </div>
              </Carousel>
              <div class="video">
                <iframe
                  width="1250"
                  height="703"
                  src="https://www.youtube.com/embed/Yal2OQ8Ebfs"
                  title="Sign by LegalPal quickstart"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                  style="max-width: 100%; max-height: 350px; border-radius: 10px;"
                ></iframe>
              </div>
            </div>
          {/if}
          {#if tutorialStep == 2}
            <div
              class="step step2 cursor-pointer"
              on:click={() => displayTutorial(3)}
            >
              <img alt="" src="/EmoteHi.gif" />
              <div>
                <h1>Step 1:</h1>
                <p>Select a new document.</p>
              </div>
            </div>
          {/if}
        </div>
        {#if templates.length > 0}
          <div class="defaultPdfWrap">
            <h2>Templates</h2>
            <div class="carousel-desktop">
              <Carousel perPage="4" loop={false}>
                {#each templates as template}
                  <div class="item">
                    <div>
                      <!-- <img alt="" src="/qix_sign_logo.svg" />    -->
                      <img alt="" src={template.thumbnail} />
                      <div
                        class="title cursor-pointer"
                        on:click={() => loadTemplate(template)}
                      >
                        {template.pdfName}
                      </div>
                    </div>
                  </div>
                {/each}
              </Carousel>
            </div>
            <div class="carousel-mobile">
              <Carousel perPage="2" loop={false}>
                {#each templates as template}
                  <div class="item">
                    <div>
                      <!-- <img alt="" src="/qix_sign_logo.svg" />    -->
                      <img alt="" src={template.thumbnail} />
                      <div
                        class="title cursor-pointer"
                        on:click={() => loadTemplate(template)}
                      >
                        {template.pdfName}
                      </div>
                    </div>
                  </div>
                {/each}
              </Carousel>
            </div>
          </div>
        {/if}
      </div>
    {/if}
    {#if addingDrawing}
      <div
        transition:fly={{ y: -200, duration: 500 }}
        class="fixed z-10 top-0 left-0 right-0 border-b border-gray-300 bg-white
          shadow-lg"
        style="height: 50%;"
      >
        <DrawingCanvas
          on:finish={(e) => {
            const { originWidth, originHeight, path } = e.detail;
            let scale = 1;
            if (originWidth > 500) {
              scale = 500 / originWidth;
            }
            addDrawing(originWidth, originHeight, path, scale);
            addingDrawing = false;
          }}
          on:cancel={() => (addingDrawing = false)}
        />
      </div>
    {/if}
    {#if loginPanel}
      <div
        transition:fly={{ y: -200, duration: 500 }}
        class="fixed z-10 border-b border-gray-300 bg-white shadow-lg justify-center p-5"
      >
        <div class:hidden={twoFactAuthPanel}>
          <!-- <input
            placeholder="Username or Email Address"
            type="text"
            class="flex-grow bg-transparent border rounded w-full mb-4 py-1 px-3"
            bind:value={user_name}
          />
          <input
            placeholder="Password"
            type="password"
            class="flex-grow bg-transparent border rounded w-full mb-4 py-1 px-3"
            bind:value={user_pw}
          /> -->
          {#if loginError}
            <p class="mb-4">{loginError}</p>
          {/if}
          <div class="flex w-full" style="justify-content: space-between;">
            <button
              on:click={signInWithGoogle}
              class="w-30 py-1 px-3 md:px-4 mr-3 md:mr-4 rounded"
              class:hidden={blog_slug != ""}
              style="border: 1px solid #e2e8f0;display:flex;align-items: center;font-size: 13px;"
            >
              <img
                alt=""
                src="/Google__G__Logo.svg.png"
                style="width: 17px;margin-right: 5px;"
              /> Sign in with Google
            </button>
            <button
              on:click={signInWithMicrosoft}
              class="w-30 py-1 px-3 md:px-4 mr-3 md:mr-4 rounded"
              class:hidden={blog_slug != ""}
              style="border: 1px solid #e2e8f0;"
            >
              <img alt="" src="/microsoft_logo.svg" style="width: 74px;" />
            </button>
            <div class="flex items-center">
              <button
                on:click={() => (loginPanel = false)}
                class="w-30 bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-3
                  md:px-4 mr-3 md:mr-4 rounded"
              >
                Cancel
              </button>
              <!-- <button
                on:click={checkSignIn}
                class="w-30 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3
                  md:px-4 rounded"
              >
                Sign In
              </button> -->
            </div>
          </div>
        </div>
        <div class:hidden={!twoFactAuthPanel}>
          <input
            placeholder="Verification Code"
            type="text"
            class="flex-grow bg-transparent border rounded w-full mb-4 py-1 px-3"
            bind:value={verificationCode}
          />
          <div class="flex justify-center items-center w-full">
            <button
              on:click={() => (loginPanel = false)}
              class="w-30 bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-3
                md:px-4 mr-3 md:mr-4 rounded"
            >
              Cancel
            </button>
            <button
              class="w-30 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3
                md:px-4 mr-3 md:mr-4 rounded"
            >
              Verify
            </button>
          </div>
        </div>
      </div>
    {/if}
    {#if pages.length}
      <div class="flex justify-center px-5 w-full mb-4">
        <button
          on:click={savePDF}
          class="w-30 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3
            md:px-4 mr-3 md:mr-4 rounded"
          class:cursor-not-allowed={pages.length === 0 || saving || !pdfFile}
          class:bg-blue-700={pages.length === 0 || saving || !pdfFile}
        >
          {saving ? "DOWNLOADING" : "DOWNLOAD"}
        </button>
        {#if user.id != ""}
          <label class="flex items-center justify-center h-full w-20">
            {user.name}
          </label>
          <button
            on:click={showSignatureTab}
            class="w-10 py-1 flex items-center justify-center"
          >
            <img alt="" src="/menu.png" />
          </button>
        {/if}
      </div>
      <div class="flex justify-center px-5 w-full md:hidden">
        <img src="/edit.svg" class="mr-2" alt="a pen, edit pdf name" />
        <input
          placeholder="Rename your PDF here"
          type="text"
          class="flex-grow bg-transparent"
          bind:value={pdfName}
        />
      </div>

      {#if tutorialStep == 3}
        <div
          class="step step3 cursor-pointer"
          on:click={() => displayTutorial(4)}
        >
          <img alt="" src="/EmoteHi.gif" />
          <div>
            <h1>Step 2:</h1>
            <p>Add a Signature<br />to the Document.</p>
          </div>
        </div>
      {/if}

      {#if tutorialStep == 4}
        <div
          class="step step4 cursor-pointer"
          on:click={() => displayTutorial(5)}
        >
          <img alt="" src="/EmoteHi.gif" />
          <div>
            <h1>Step 3:</h1>
            <p>Add a Name<br />to the Document.</p>
          </div>
        </div>
      {/if}

      {#if tutorialStep == 5}
        <div
          class="step step5 cursor-pointer"
          on:click={() => displayTutorial(6)}
        >
          <img alt="" src="/EmoteHi.gif" />
          <div>
            <h1>Step 4:</h1>
            <p>Share the Document.</p>
          </div>
        </div>
      {/if}

      {#if tutorialStep == 6}
        <div
          class="step step6 cursor-pointer"
          on:click={() => displayTutorial(0)}
        >
          <img alt="" src="/EmoteHi.gif" />
          <div>
            <h1>Step 5:</h1>
            <p>Open the menu<br />to track your documents.</p>
          </div>
        </div>
      {/if}
      <div class="w-full">
        {#each pages as page, pIndex (page)}
          <div
            class="p-5 w-full flex flex-col items-center overflow-hidden"
            on:mousedown={() => selectPage(pIndex)}
            on:touchstart={() => selectPage(pIndex)}
          >
            <div
              class="relative shadow-lg"
              class:shadow-outline={pIndex === selectedPageIndex}
            >
              <PDFPage
                on:measure={(e) =>
                  onMeasure(e.detail.scale, e.detail.image, pIndex)}
                {page}
              />
              <div
                class="absolute top-0 left-0 transform origin-top-left"
                style="transform: scale({pagesScale[
                  pIndex
                ]}); touch-action: none;"
              >
                {#each allObjects[pIndex] as object (object.id)}
                  {#if object.type === "image"}
                    <Image
                      on:update={(e) => updateObject(object.id, e.detail)}
                      on:delete={() => deleteObject(object.id)}
                      file={object.file}
                      payload={object.payload}
                      x={object.x}
                      y={object.y}
                      width={object.width}
                      height={object.height}
                      pageScale={pagesScale[pIndex]}
                    />
                  {:else if object.type === "Signature" || object.type === "quickSign"}
                    <Signature
                      on:update={(e) => updateObject(object.id, e.detail)}
                      on:delete={() => deleteObject(object.id)}
                      file={object.file}
                      payload={object.payload}
                      x={object.x}
                      y={object.y}
                      type={object.type}
                      width={object.width}
                      height={object.height}
                      pageScale={pagesScale[pIndex]}
                    />
                  {:else if object.type === "text"}
                    <Text
                      on:update={(e) => updateObject(object.id, e.detail)}
                      on:delete={() => deleteObject(object.id)}
                      on:selectFont={selectFontFamily}
                      text={object.text}
                      x={object.x}
                      y={object.y}
                      size={object.size}
                      lineHeight={object.lineHeight}
                      fontFamily={object.fontFamily}
                      pageScale={pagesScale[pIndex]}
                    />
                  {:else if object.type === "drawing"}
                    <Drawing
                      on:update={(e) => updateObject(object.id, e.detail)}
                      on:delete={() => deleteObject(object.id)}
                      path={object.path}
                      x={object.x}
                      y={object.y}
                      width={object.width}
                      originWidth={object.originWidth}
                      originHeight={object.originHeight}
                      pageScale={pagesScale[pIndex]}
                    />
                  {/if}
                {/each}
              </div>
            </div>
          </div>
        {/each}
      </div>
    {:else}
      <div class="w-full flex-grow flex justify-center items-center">
        <span class=" font-bold text-3xl text-gray-500"
          >Drag something here</span
        >
      </div>
    {/if}
    {#if tutorialStep > 0}
      <div
        class="w-full flex-grow flex justify-center items-center cursor-pointer tutorialWrap"
        on:click={() => displayTutorial(-1)}
      >
        {#if tutorialStep == 1}
          <div class="step step1">
            <h1>Welcome to the tutorial</h1>
            <img alt="" src="/EmoteHi.gif" />
            <p>
              Learn how to Share a document with a client or colleague in <b
                >5 steps</b
              >!
            </p>
            <br /><br />
            <button
              on:click={() => displayTutorial(2)}
              class="w-30 text-white font-bold py-1 px-3 md:px-4 mr-3 md:mr-4 rounded bg-blue-500 hover:bg-blue-700"
              >Start</button
            >
          </div>
        {/if}
      </div>
    {/if}
    <LeftMenu
      on:hide={() => displayLeftMenuFunc()}
      display={displayLeftMenu}
      user_data={user}
    />
  </main>
{:else}
  <div
    class="w-full flex-grow flex justify-center items-center"
    style="height: 100vh;position: fixed;background: #fff;top: 0;left: 0;z-index: 99;"
  >
    <img alt="" src="/EmoteHi.gif" />
  </div>
{/if}
