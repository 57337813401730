
<script lang="ts">
  import { settings, setSettings } from '../../store/auth.storeTs';
  import AutomationItem from './AutomationItem.svelte';
  import { type IMerusCaseSettings, AutomationSetting } from '../../typesTs';
  import Portal from '../other/Portal.svelte';
  import DefaultPopup from '../popups/DefaultPopup.svelte';
  import DefaultButton from '../buttons/DefaultButton.svelte';
  import DefaultLoader from '../other/DefaultLoader.svelte';
  import { createEventDispatcher } from 'svelte';
  // import TutorialStep from './TutorialStep.svelte';

  export let user;
  const dispatch = createEventDispatcher();

  let automationSettings:IMerusCaseSettings = {};
  let savedSettings:IMerusCaseSettings = {}; 
  settings.subscribe(value => {
    automationSettings = value.merusCase;
    savedSettings = value.merusCase;
  });

  let loading:boolean = false;
  let popupActive:boolean = false;

  const enable = () => {
    loading = true;
    setSettings<IMerusCaseSettings>(AutomationSetting.MerusCase, Object.assign({...automationSettings}, {
      enabled: true,
    })).finally(() => {
      loading = false;
      popupActive = false;
    });
  };

  const handleClick = () => {
    console.log('handleClick', user.settings.merusCase);
    // if (!automationSettings.enabled) {
    //   popupActive = true;
    // } else {
    //   dispatch('open');
    // }
  };

  export let tutorialStep:number = 0;
  

  $: {
    if (tutorialStep === 202) {
      popupActive = true;
      savedSettings = JSON.parse(JSON.stringify(automationSettings));
      automationSettings.enabled = false;
    } else {
      automationSettings = savedSettings;
      popupActive = false;
    }
  }

</script>

<AutomationItem enabled={(tutorialStep === 201 || tutorialStep === 202) ? false : user.merusCaseEnabled} {loading} highlighted={tutorialStep === 201} name="MerusCase" img="meruscase_icon.png" on:click={() => handleClick()} />
<Portal>
  <DefaultPopup bind:active={popupActive} zIndex="{1102}">
    <form class="meruscase-automation-popup { loading ? 'meruscase-automation-popup--loading' : '' }" on:submit|preventDefault={() => enable()}>
      {#if loading}
        <DefaultLoader class="meruscase-automation-popup__loader" />
      {/if}
      <h2 class="meruscase-automation-popup__title">Add Automation</h2>
      {#if tutorialStep === 202}
        <div class="meruscase-automation-popup__tutorial">
          <!-- <TutorialStep text="Enter credentials and click 'Enable' button" /> -->
        </div>
      {/if}
      <img class="meruscase-automation-popup__img" src="/meruscase_icon.png" alt="">
      <span class="meruscase-automation-popup__subtitle">Insert API Credentials To Enable</span>
      <input class="meruscase-automation-popup__input {tutorialStep === 202 ? 'highlighted' : ''}" bind:value="{automationSettings.token}" type="text" placeholder="API Token" required>
      <div class="meruscase-automation-popup__toggle">
        <DefaultButton class={tutorialStep === 202 ? 'highlighted' : ''} text="Enable" type="submit" />
      </div>
    </form>
  </DefaultPopup>
</Portal>

<style lang="scss" scoped>

  :global(.meruscase-automation-popup__loader) {
    position: absolute;
  }
  
  .meruscase-automation-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90vw;
    max-width: 400px;
    padding: 10px 30px 30px;

    &--loading {
      pointer-events: none;
      opacity: 0.5;
    }

    &__title {
      margin: 0 auto 10px 0;
      font-weight: 400;
      font-size: 1.2rem;
    }

    &__img {
      width: 90%;
      max-width: 60px;
      margin-bottom: 20px;
      margin-top: 20px;
    }

    &__subtitle {
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
    }

    &__input {
      border-radius: 5px;
      border: 1px solid #000;
      margin-right: 10px;
      flex-grow: 1;
      padding: 5px 10px;
      width: 90%;
      margin-bottom: 20px;
    }

    &__toggle {
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }
</style>