<script lang="ts">
  let subscribePopupView = true;
  const subscribe = () => {
    window.parent.location.href = "https://buy.stripe.com/dR6bJWa03dFe5Q43ce";
  };
  const closeSubscribe = () => {
    subscribePopupView = false;
  };
</script>

{#if subscribePopupView}
  <div class="subscribe-view-wrapper">
    <div class="subscribe-view">
      <button class="close" on:click={closeSubscribe}
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0"
          y="0"
          viewBox="0 0 329.269 329"
          style="enable-background:new 0 0 512 512"
          xml:space="preserve"
          class="svelte-2x80vs"
          ><g
            ><path
              d="M194.8 164.77 323.013 36.555c8.343-8.34 8.343-21.825 0-30.164-8.34-8.34-21.825-8.34-30.164 0L164.633 134.605 36.422 6.391c-8.344-8.34-21.824-8.34-30.164 0-8.344 8.34-8.344 21.824 0 30.164l128.21 128.215L6.259 292.984c-8.344 8.34-8.344 21.825 0 30.164a21.266 21.266 0 0 0 15.082 6.25c5.46 0 10.922-2.09 15.082-6.25l128.21-128.214 128.216 128.214a21.273 21.273 0 0 0 15.082 6.25c5.46 0 10.922-2.09 15.082-6.25 8.343-8.34 8.343-21.824 0-30.164zm0 0"
              fill="#000000"
              data-original="#000000"
              class=""
            ></path></g
          ></svg
        ></button
      >
      <img
        alt=""
        src="/legalpal.png"
        style="width: 300px; margin: auto auto 20px;"
      />
      <p class="subscribe-view__title">
        If you are enjoying our service consider an upgrade!
      </p>
      <p class="subscribe-view__title" style="color: #4299e1;">
        or maybe leave us a good review! >
      </p>
      <button
        class="w-30 text-white font-bold py-1 px-3 md:px-4 mr-3 md:mr-4 rounded bg-blue-500 hover:bg-blue-700"
        style="margin-top: 15px;padding: 10px 20px;"
        on:click={subscribe}
      >
        Subscribe
      </button>
    </div>
  </div>
{/if}

<style>
  .subscribe-view-wrapper {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 99;
    background: rgba(0, 0, 0, 0.5);
  }
  .subscribe-view {
    background-color: #fff;
    text-align: center;
    padding: 50px;
    position: relative;
    min-height: 450px;
    border-radius: 20px;
    max-width: 500px;
  }
  .subscribe-view__title {
    font-size: 1.7em;
    margin-bottom: 20px;
  }
  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 14px;
    height: auto;
    cursor: pointer;
  }
</style>
