<script lang="ts">
  import { createEventDispatcher } from 'svelte';
   //  import { theme } from '../../store/theme.store';
  const dispatch = createEventDispatcher();

  let clazz:string = '';
	export { clazz as class };
  export let active:boolean = false; 

  const close = ():void => {
    if (closable) {
      active = false;

      setTimeout(() => {
        dispatch('close');
      }, 1000)
    }
  };

  export let zIndex:number = 1001;
  export let closable:boolean = true;
  export let scrolable:boolean = true;
</script>

<div class="popup {active ? 'popup__active' : ''}" style="{'z-index:' + zIndex}">
  <button class="popup__bg" on:click={close}></button>
  <div class="popup__inner {clazz} theme-light {scrolable ? 'popup__inner--scrollable' : ''}">
    {#if closable}
      <button class="popup__close-btn theme-light" on:click={close}>
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" x="0" y="0" viewBox="0 0 329.269 329" style="enable-background:new 0 0 512 512" xml:space="preserve"><g><path d="M194.8 164.77 323.013 36.555c8.343-8.34 8.343-21.825 0-30.164-8.34-8.34-21.825-8.34-30.164 0L164.633 134.605 36.422 6.391c-8.344-8.34-21.824-8.34-30.164 0-8.344 8.34-8.344 21.824 0 30.164l128.21 128.215L6.259 292.984c-8.344 8.34-8.344 21.825 0 30.164a21.266 21.266 0 0 0 15.082 6.25c5.46 0 10.922-2.09 15.082-6.25l128.21-128.214 128.216 128.214a21.273 21.273 0 0 0 15.082 6.25c5.46 0 10.922-2.09 15.082-6.25 8.343-8.34 8.343-21.824 0-30.164zm0 0" fill="#000000" data-original="#000000" class=""></path></g></svg>
      </button>
    {/if}
    <slot></slot>
  </div>
</div>

<style lang="scss" scoped>
@import '../../style/variables.scss'; // Adjust the path as needed
  // Replace with your desired color
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    transition: opacity 0.5s, visibility 0.5s;

    &__active {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }

    &__bg {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;
      border: none;
      outline: none;
      background-color: rgba($color: #000, $alpha: 0.5);
      z-index: 1;
    }

    &__close-btn {
      position: fixed;
      top: 15px;
      right: 15px;
      display: block;
      padding: 0;
      border: none;
      cursor: pointer;
      z-index: 2;
      background-color: transparent;
      transition: opacity 0.3s, filter 0.3s;
      will-change: opacity;

      &.theme-dark {
        filter: invert(1);
      }

      &:hover {
        opacity: 0.5;
      }

      svg {
        pointer-events: none;
        width: 15px;
      }
    }

    &__active &__inner {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
    }

    &__inner {
      z-index: 2;
      transform: translateY(-20vh);
      border-radius: 15px;
      opacity: 0;
      visibility: hidden;
      background-color: #fff;
      transition: transform 0.4s, opacity 0.3s, visibility 0.3s, background-color 0.3s;
      will-change: transform, opacity;
      scrollbar-width: 0;

      &--scrollable {
        overflow-y: scroll;
      }

      &.theme-dark {
        background-color: $dark-bg;
      }

      &::-webkit-scrollbar {
        width: 0;
      }
    }
  }
</style>