<script lang="ts">
  import DefaultPopup from './DefaultPopup.svelte';
  import AutomationBlock from './../blocks/AutomationBlock.svelte';
  import { logout } from '../../store/auth.store';
    import ImageUploader from '../../ImageUploader.svelte';
    import SendTo from '../../SendTo.svelte';
    import  App  from '../../App.svelte';
    import {
    readAsArrayBuffer,
    readAsImage,
    readAsPDF,
    readAsDataURL,
  } from "../../utils/asyncReader.js";
  import { ggID, timeout } from "../../utils/helper.js";
  import prepareAssets, { fetchFont } from "../../utils/prepareAssets.js";
  import { timeAgo } from "../../store/timeAgo.js";
  import CopyClipBoard from "../../CopyClipBoard.svelte";

  import { save, getData, getPdfPageData } from "../../utils/PDF.js";

    import Template from '../../Template.svelte';
    import CheckMark from '../../CheckMark.svelte';
    let appRef;
  // import TeamBlock from './../blocks/TeamBlock.svelte';
  // import AutomationBlock from './../blocks/AutomationBlock.svelte';
  // import MessagingBlock from './../blocks/MessagingBlock.svelte';
  // import TutorialStep from './../blocks/TutorialStep.svelte';
  // import { setTheme, theme } from '../../store/theme.store';
    // import ThemeSwither from '../buttons/ThemeSwither.svelte';
  export let user;
  export let isOpen = true;
  enum Tab {
    Pending = 'messaging',
    Completed = 'completed',
    Templates = 'auto',
    Integration = 'integration',
    Team = 'team'
  }
  let logo = "/img/default_image.svg";
  let blog = { siteurl: "https://qix.cloud" };

  async function get_company_logo() {
    let body = { appId: user.appId, email: user.email };
    const req = await fetch(blog.siteurl + "/ajax/logo.php", {
      method: "POST",
      body: JSON.stringify(body),
    });
    let res = await req.json();
    logo = res.logo.replace("{{api}}", "https://api.qix.cloud/");
  }
  async function handleLogoUpload(img) {
    logo = img.replace(
      "https://drive.google.com/uc?export=view&id=",
      "https://lh3.google.com/u/0/d/"
    );
    let body = { appId: user.appId, email: user.email, logo: logo };
    const req = await fetch(blog.siteurl + "/ajax/logo.php", {
      method: "POST",
      body: JSON.stringify(body),
    });
    await req.json();
  }

  let activeTab:Tab = Tab.Pending;
  export let newProfilePanel = true; // Initialize searchKeyPending
  export let searchKeyPending; // Initialize searchKeyPending
  export let pendingSignature; // Initialize searchKeyPending
  export let signatureTabLoading; // Initialize searchKeyPending
  export let friends; // Initialize searchKeyPending
  export let showArchive; // Initialize searchKeyPending
  export let completedSignature; // Initialize searchKeyPending
  export let templates; // Initialize searchKeyPending

  export let emailNotifyAvailable;
  export let searchKeyCompleted; // Initialize searchKeyPending
  export let displayArchive;
  export let completedArchived; // Initialize searchKeyPending
  export let sendToId;
  export let showSignatureTab;
  export let completedPDFUrl;
  export let selectedPageIndex;
  export let pdfName;
  export let loadingTemplate;
  export let chatUsers; // Initialize searchKeyPending
  export let users; // Initialize searchKeyPending
  export let merusToken = ""; // Initialize searchKeyPending
  export let pdfFile; // Initialize searchKeyPending
  export let pages; // Initialize searchKeyPending
  export let allObjects; // Initialize searchKeyPending
  export let pagesScale; // Initialize searchKeyPending
  export let quickSigns; // Initialize searchKeyPending
  export let editTemplatesAvailable;
  export let multiplePdfs; 
  export let processingSaveTemplate;
  export let completedSaveTemplate; 
  export let selectedMerusUserId;
  export let selectedMerusActivityTypeId;
  export let selectedSharepoint;
  export let signColor; // Initialize searchKeyPending
  export let templateId; // Initialize searchKeyPending
  export let pdfBlobFile; // Initialize searchKeyPending
  export let pdfFiles;
  export let tempThumb;
  export let sendPDFType; // Initialize searchKeyPending
  export let layerPos;
  export let addTextMenu;
  export let currentFont;
  export let signatureMenu;
  export let tutorialNumber;
  export let teamViewCompletedDocAvailable;
  export let searchKeyFriend;
  export let inviteFriendPanel;
  export let inviteClipboardTxt;
  export let clipboardTxt;
  export let merusCasesEnabled;
  export let fileVineEnabled;
  export let fluentCaseToken;

  async function setFluentCaseToken(token = "") {
    let body = { user_id: user.appId };
    if (token != "") {
      body = { user_id: user.appId, token: token };
    }
    const req = await fetch(
      blog.siteurl + "/ajax/fluentCaseToken.php",
      {
        method: "POST",
        body: JSON.stringify(body),
      }
    );
    let res = await req.json();
    fluentCaseToken = res.token != "" ? token : "";

  }

  const copyInviteUrl = () => {
    clipboardTxt = inviteClipboardTxt;
    const app = new CopyClipBoard({
      target: document.getElementById("inviteclipboard"),
      props: { clipboardTxt },
    });

    app.$destroy();
    //clipboardTxt = "";
    //usersPanel = false;
    alert("Copied.");
  };

  async function getTeamViewCompletedDocAvailable(status = "") {
    let body = { user_id: user.appId };
    if (status != "") {
      body = { user_id: user.appId, available: status };
    }
    const req = await fetch(
      blog.siteurl + "/ajax/view_completed_documents_available.php",
      {
        method: "POST",
        body: JSON.stringify(body),
      }
    );
    let res = await req.json();
    teamViewCompletedDocAvailable = res.available == "disable" ? false : true;
  }

  async function editTemplatesAvailableFunc(status = "") {
    let body = { user_id: user.appId };
    if (status != "") {
      body = { user_id: user.appId, available: status };
    }
    const req = await fetch(
      blog.siteurl + "/ajax/edit_template_available.php",
      {
        method: "POST",
        body: JSON.stringify(body),
      }
    );
    let res = await req.json();
    editTemplatesAvailable = res.available == "disable" ? false : true;
  }
  async function getPageDimension() {
    const pageData = await getPdfPageData(pdfFile);

    for (const [file] of pdfFiles) {
      const data = await getPdfPageData(file);
      pageData.push(...data);
    }

    return pageData;
  }
  async function getSignatureOptions(contact_id = 0) {
    const prePdfData = await getData(pdfFile, allObjects, pdfName, pagesScale);
    //let pdfData = "data:application/pdf;base64,"  +  prePdfData[0];
    let pdfData = prePdfData[0];
    pdfBlobFile = pdfData;
    let optionData = prePdfData[1];
    if (pdfFiles.length > 0) {
      let tempSignatures = [];
      const pageData = await getPageDimension();
      for (let pageIndex = 0; pageIndex < allObjects.length; pageIndex++) {
        const objects = allObjects[pageIndex];
        const pageWidth = pageData[pageIndex].width;
        const pageHeight = pageData[pageIndex].height;
        for (let i = 0; i < objects.length; i++) {
          const object = objects[i];
          if (object.type === "Signature") {
            let { file, x, y, width, height } = object;
            let tmp = {
              type: object.type,
              url: file,
              x,
              y,
              width,
              height,
              pageIndex,
              pageWidth,
              pageHeight,
            };
            tempSignatures.push(tmp);
          } else if (object.type === "quickSign") {
            let { file, x, y, width, height } = object;
            let tmp = {
              type: object.type,
              url: file,
              x,
              y,
              width,
              height,
              pageIndex,
              pageWidth,
              pageHeight,
            };
            tempSignatures.push(tmp);
          } else if (object.type === "text") {
            let { text, x, y, width } = object;
            let tmp = {
              type: object.type,
              text: text,
              x,
              y,
              width,
              pageIndex,
              pageWidth,
              pageHeight,
            };
            tempSignatures.push(tmp);
          }
        }
      }
      optionData = tempSignatures;
    }
    let sign1 = "";
    let sign2 = "";
    let sign3 = "";
    let sign4 = "";
    let sign5 = "";
    let sign6 = "";
    for (let i = 0; i < optionData.length; i++) {
      const pageNum = optionData[i].pageIndex + 1;
      let pageWidth = optionData[i].pageWidth;
      let pageHeight = optionData[i].pageHeight;
      if (pageWidth == 792 && pageHeight == 612) {
        if (optionData[0].pageWidth == 612 && optionData[0].pageHeight == 792) {
          pageWidth = 612;
          pageHeight = 792;
        }
      }
      if (optionData[i].type == "text") {
        //console.log(optionData[i].pageWidth, optionData[i].pageHeight);
        let posX = optionData[i].x / pageWidth;
        let posY = (optionData[i].y + 15) / pageHeight;
        if (optionData[i].text == "Initial") {
          if (sign2 == "") {
            sign2 += pageNum + ":" + posX + ":" + posY;
          } else {
            sign2 += "," + pageNum + ":" + posX + ":" + posY;
          }
        } else if (optionData[i].text == "Date") {
          if (sign3 == "") {
            sign3 += pageNum + ":" + posX + ":" + posY;
          } else {
            sign3 += "," + pageNum + ":" + posX + ":" + posY;
          }
        } else if (optionData[i].text == "Name") {
          if (sign4 == "") {
            sign4 += pageNum + ":" + posX + ":" + posY;
          } else {
            sign4 += "," + pageNum + ":" + posX + ":" + posY;
          }
        } else {
          //console.log("optionData", i, optionData);
          if (sign5 == "") {
            sign5 +=
              pageNum + ":" + posX + ":" + posY + ":" + optionData[i].text;
          } else {
            sign5 +=
              "," +
              pageNum +
              ":" +
              posX +
              ":" +
              posY +
              ":" +
              optionData[i].text.replace(/,/g, "-*-").replace(/:/g, "_*_");
          }
        }
      } else {
        let posX = optionData[i].x / pageWidth;
        let posY = optionData[i].y / (pageHeight - 23);
        if (optionData[i].type == "quickSign") {
          let path = optionData[i].url.replace(blog.siteurl, "");
          if (sign6 == "") {
            sign6 += pageNum + ":" + posX + ":" + posY + ":" + path;
          } else {
            sign6 += "," + pageNum + ":" + posX + ":" + posY + ":" + path;
          }
        }
        if (optionData[i].type == "Signature") {
          if (sign1 == "") {
            sign1 += pageNum + ":" + posX + ":" + posY;
          } else {
            sign1 += "," + pageNum + ":" + posX + ":" + posY;
          }
        }
      }
    }
    let timestamp = 0;
    let option1 = sign1 + "--*--" + sign3;
    let option2 =
      sign2 +
      "--*--" +
      sign4 +
      "--*--" +
      sign5 +
      "--*--letter--*--" +
      timestamp +
      "--*--" +
      sign6;
    let display_name = "google_" + user.name;
    let body = {
      author_id: user.id,
      author: display_name,
      contact_id: contact_id,
      pdfData: pdfData,
      pdfName: pdfName,
      option1: option1,
      option2: option2,
      sendPDFType: sendPDFType,
      allObjects: allObjects,
    };
    return body;
  }
  async function saveTemplate() {
    processingSaveTemplate = true;
    completedSaveTemplate = false;
    let body = await getSignatureOptions();
    body.templateId = templateId;
    body.thumbnail = tempThumb;
    body.auth = "google";
    //console.log("body", body);
    const res = await fetch(
      blog.siteurl +
        "/wp-admin/admin-ajax.php?action=saveTemplateFromCloudFlare",
      {
        method: "POST",
        body: JSON.stringify(body),
      }
    );
    let data = await res.json();
    templateId = data;
    processingSaveTemplate = false;
    completedSaveTemplate = true;
    showSignatureTab = false;
  }
  async function handleLoadTemplate(event) {
    loadTemplate(event.detail.template);
  }
  async function loadTemplate(template) {
    loadingTemplate = true;
    selectedMerusUserId = template.merusUserId;
    selectedMerusActivityTypeId = template.activity_type_id;
    selectedSharepoint = template.sharepoint;
    showSignatureTab = false;
    let body = { url: template.pdfUrl, auth: "google" };
    const res = await fetch(
      blog.siteurl + "/wp-admin/admin-ajax.php?action=loadTemplateCloudFlare",
      {
        method: "POST",
        body: JSON.stringify(body),
      }
    );
    let pdfBlob = await res.blob();
    selectedPageIndex = 0;
    await addPDF(pdfBlob);
    pdfName = template.pdfName;
    let Objs = JSON.parse(template.allObjects);
    for (var i = 0; i < Objs.length; i++) {
      for (var j = 0; j < Objs[i].length; j++) {
        let obj = Objs[i][j];
        layerPos = { x: obj.x, y: obj.y };
        selectedPageIndex = i;
        if (obj.type == "Signature") {
          await addSignImage(obj.file, 1);
        }
        if (obj.type == "quickSign") {
          await addQuickSignImage(obj.file, 1);
        }
        if (obj.type == "text") {
          addTextField(obj.text, true);
        }
      }
    }
    //console.log(selectedPageIndex);
    templateId = template.id;
    template.loaded = true;
    templates = templates.map((temp, tIndex) =>
      temp.id == templateId ? template : temp
    );
    setTimeout(function () {
      window.scrollTo({ top: 0, behavior: "auto" });
      loadingTemplate = false;
    }, 1000);
  }
  const genID = ggID();
  function addTextField(text = "New Text Field", pos = false) {
    addTextMenu = false;
    const id = genID();
    fetchFont(currentFont);
    const object = {
      id,
      text,
      type: "text",
      size: 16,
      width: 0, // recalculate after editing
      lineHeight: 1.4,
      fontFamily: currentFont,
      x: 0,
      y: 0,
    };
    if (pos) {
      object.x = layerPos.x;
      object.y = layerPos.y;
    }
    allObjects = allObjects.map((objects, pIndex) =>
      pIndex === selectedPageIndex ? [...objects, object] : objects
    );
  }
  async function addQuickSignImage(url, p) {
    try {
      // get dataURL to prevent canvas from tainted
      const file = url;
      const img = await readAsImage(url);
      const id = genID();
      const { width, height } = img;
      signatureMenu = false;
      const object = {
        id,
        type: "quickSign",
        width,
        height,
        x: layerPos.x,
        y: layerPos.y,
        payload: img,
        file,
      };
      if (p == 0) {
        object.x = 0;
        object.y = 0;
      }
      allObjects = allObjects.map((objects, pIndex) =>
        pIndex === selectedPageIndex ? [...objects, object] : objects
      );
    } catch (e) {
      console.log(`Fail to add image.`, e);
    }
  }

  async function addSignImage(url = "", p) {
    try {
      if (url == "") {
        if (signColor == 0) {
          url = "https://qix.cloud/img/sign_hear.png";
        } else {
          url = "https://qix.cloud/img/sign_hear.png";
        }
      }
      // get dataURL to prevent canvas from tainted
      const file = url;
      const img = await readAsImage(url);
      const id = genID();
      const { width, height } = img;
      signatureMenu = false;
      const object = {
        id,
        type: "Signature",
        width,
        height,
        x: layerPos.x,
        y: layerPos.y,
        payload: img,
        file,
      };
      if (p == 0) {
        object.x = 0;
        object.y = 0;
      }
      allObjects = allObjects.map((objects, pIndex) =>
        pIndex === selectedPageIndex ? [...objects, object] : objects
      );
    } catch (e) {
      console.log(`Fail to add image.`, e);
    }
  }
  // Initialize searchKeyPending
   // Initialize searchKeyPending
   function isJSON(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }
   async function viewCompletedDoc(sign) {
    const pdfs = sign.signedPdf;
    if (isJSON(pdfs)) {
      let pdfData = JSON.parse(pdfs);
      pdfData.forEach((data, index) => {
        setTimeout(() => {
          window.open(blog.siteurl + data.link, "_blank");
        }, index * 500); // 500ms delay between tabs
      });
    } else {
      window.open(blog.siteurl + pdfs, "_blank");
    }
  }
  async function editCompletedDoc(pdfs, name,attachmentUrl) {
    showSignatureTab = false;
    loadingTemplate = true;
    completedPDFUrl = attachmentUrl;
    let pdfUrl = pdfs;
    if (isJSON(pdfs)) {
      let pdfData = JSON.parse(pdfs);
      pdfUrl = blog.siteurl + pdfData[0].link;
      completedPDFUrl = pdfUrl;
    }
    let body = { url: pdfUrl, auth: "google" };
    const res = await fetch(
      blog.siteurl + "/wp-admin/admin-ajax.php?action=loadTemplateCloudFlare",
      {
        method: "POST",
        body: JSON.stringify(body),
      }
    );
    let pdfBlob = await res.blob();
    selectedPageIndex = 0;
    await addPDF(pdfBlob);
    pdfName = name;
    loadingTemplate = false;
  }

  async function addPDF(file) {
    try {
      getSignatures();
      const pdf = await readAsPDF(file);
      pdfName = file.name;
      pdfFile = file;
      const numPages = pdf.numPages;
      pages = Array(numPages)
        .fill()
        .map((_, i) => pdf.getPage(i + 1));
      allObjects = pages.map(() => []);
      pagesScale = Array(numPages).fill(1);
    } catch (e) {
      console.log("Failed to add pdf.");
      throw e;
    }
  }
  async function getSignatures() {
    if (quickSigns.length < 1) {
      let body = {
        auth: "google",
        user_id: user.id,
        email: user.email,
      };
      const signs_req = await fetch(
        blog.siteurl + "/wp-admin/admin-ajax.php?action=getSignItQuickSign",
        {
          method: "POST",
          body: JSON.stringify(body),
        }
      );
      quickSigns = await signs_req.json();
    }
  }
  
  function updateSendToId(id) {
    sendToId = sendToId == id ? 0 : id;
    // sendToType = "";
    // merusToken = "";
    // caseFileId = "";
    // merusTokenPanel = false;
  }
  async function deleteArchivedSignature(id) {
    if (confirm("Are you sure you want to delete the signature?") == true) {
      signatureTabLoading = true;
      let body = {
        id: id,
        auth: "google",
        user_id: user.id,
        email: user.email,
        team: friends,
      };
      const req = await fetch(
        blog.siteurl +
          "/wp-admin/admin-ajax.php?action=deleteArchivedSignature",
        {
          method: "POST",
          body: JSON.stringify(body),
        }
      );
      completedArchived = await req.json();
      signatureTabLoading = false;
    }
  }

  async function getEmailNotifyAvailable(status = "") {
    let body = { user_id: user.appId, available: "" };
    if (status != "") {
      body = { user_id: user.appId, available: status };
    }
    const req = await fetch(
      blog.siteurl + "/ajax/sender_notify_available.php",
      {
        method: "POST",
        body: JSON.stringify(body),
      }
    );
    let res = await req.json();
    emailNotifyAvailable = res.available == "disable" ? false : true;
  }
  async function sendToFunc(event) {
    if (event.detail.error == "") {
      updateSendToId(event.detail.signId);
      alert("Sent successfully!");
    } else {
      alert(event.detail.error);
    }
  }
  async function resendSignature(id) {
    if (confirm("Are you sure you want to resend the signature?") == true) {
      signatureTabLoading = true;
      let body = { id: id };
      const req = await fetch(
        blog.siteurl + "/wp-admin/admin-ajax.php?action=resendSignatureProcess",
        {
          method: "POST",
          body: JSON.stringify(body),
        }
      );
      alert("Sent!");
    }
  }
  async function deleteSignature(id) {
    if (confirm("Are you sure you want to delete the signature?") == true) {
      signatureTabLoading = true;
      let body = {
        id: id,
        auth: "google",
        user_id: user.id,
        email: user.email,
        team: friends,  
      };
      const req = await fetch(
        blog.siteurl + "/wp-admin/admin-ajax.php?action=deleteSignatureProcess",
        {
          method: "POST",
          body: JSON.stringify(body),
        }
      );
      let data = await req.json();
      pendingSignature = data.pending;
      showArchive = false;
      completedSignature = data.completed;
      templates = data.templates;
      signatureTabLoading = false;
    }
  }
  async function shareSignature(title, text, url) {
    const shareData = {
      title: title,
      text: text,
      url: url,
    };
    try {
      await navigator.share(shareData);
      //alert('Shared successfully');
    } catch (err) {
      alert(err);
    }
  }
  $: activeTabClass = (tab:Tab):string => {
    return activeTab == tab ? 'main-menu-popup__tab--active' : '';
  };

  $: activeViewPosition = (tab:Tab):string => {
    if (activeTab == Tab.Pending) {
      if (tab == Tab.Pending) {
        return '';
      } else {
        return 'main-menu-popup__view--right';
        
      }
    } else if (activeTab == Tab.Completed) {
      if (tab == Tab.Completed) {
        return '';
      } else if (tab != Tab.Pending) {
        return 'main-menu-popup__view--right';
      } else {
        return 'main-menu-popup__view--left';
      }
    }
    else if (activeTab == Tab.Templates) {
      if (tab == Tab.Templates) {
        return '';
      } else if (tab != Tab.Pending && tab != Tab.Completed) {
        return 'main-menu-popup__view--right';
      } else {
        return 'main-menu-popup__view--left';
      }
    }
    else if (activeTab == Tab.Integration) {
      if (tab == Tab.Integration) {
        return '';
      } else if (tab != Tab.Pending && tab != Tab.Completed && tab != Tab.Templates) {
        return 'main-menu-popup__view--right';
      } else {
        return 'main-menu-popup__view--left';
      }
    } 
    else if (activeTab == Tab.Team) {
      if (tab == Tab.Team) {
        return '';
      } else {
        return 'main-menu-popup__view--left';
      }
    }
  };

  $: indicatorClass = 
    activeTab == Tab.Pending 
      ? 'main-menu-popup__tab-indicator--pending' 
      : activeTab == Tab.Completed 
        ? 'main-menu-popup__tab-indicator--completed' 
        : activeTab == Tab.Templates 
          ? 'main-menu-popup__tab-indicator--templates' 
          : activeTab == Tab.Integration 
            ? 'main-menu-popup__tab-indicator--integration' 
            : activeTab == Tab.Team 
              ? 'main-menu-popup__tab-indicator--team' 
              : '';

  export let tutorialStep:number = 0;

  // $: {
  //   if (tutorialStep === 101) {
  //     activeTab = Tab.Messaging
  //   }

  //   if (tutorialStep === 201) {
  //     activeTab = Tab.Automation
  //   }

  //   if (tutorialStep === 301) {
  //     activeTab = Tab.Automation
  //   }

  //   if (tutorialStep === 401) {
  //     activeTab = Tab.Team
  //   }
  // }
  get_company_logo();
  $: console.log('isOpen changed:', isOpen);
  $: console.log('signatureTabLoading changed:', signatureTabLoading);
  $: console.log('newProfilePanel changed:', newProfilePanel);

</script>
<div style="display: none;">
  <App bind:this={appRef} />
</div>
<div>
  {#if true}
    <DefaultPopup class="main-menu-popup" bind:active={newProfilePanel} zIndex="{700}">
      <div class="main-menu-popup__inner theme-light">
        <div class="main-menu-popup__user">
          {#if user.google } 
            <img class="main-menu-popup__user-type" src="/Google__G__Logo.svg.png" alt="">
          {:else if user.microsoft }
            <img class="main-menu-popup__user-type" src="/microsoft.svg" alt="">
          {/if}
          <span class="main-menu-popup__user-email transition-color">
            { user.email }
          </span>
          <button class="main-menu-popup__logout theme-light" on:click={logout}>Log Out</button>
          <!-- <span class="transition-color">Theme:&nbsp;&nbsp;</span> -->
        </div>
        
        <div
        class="w-full flex items-center"
        style="padding-left: 10px;font-size: .8em"
      >
        AppID: {user.appId}
      </div>
      <div
        class="w-full flex items-center"
        style="padding: 15px 0 15px 10px;"
      >
        <ImageUploader
          src={logo}
          {user}
          on:upload={(e) => handleLogoUpload(e.detail.image)}
        />
        <div style="margin-left: 20px;font-size: 1.3em;">
          Company Logo
        </div>
      </div>
        <div class="main-menu-popup__tabs theme-light">
          <div class="main-menu-popup__tab-indicator theme-light {indicatorClass}">&nbsp;</div>
          <button 
            class="main-menu-popup__tab theme-light {tutorialStep === 401 ? 'highlighted' : ''} {activeTabClass(Tab.Pending)}"
            on:click={() => activeTab = Tab.Pending}
          >
            Pending
          </button>
          <button 
            class="main-menu-popup__tab theme-light {activeTabClass(Tab.Completed)}"
            on:click={() => activeTab = Tab.Completed}
          >
            Completed
          </button>
          <button 
            class="main-menu-popup__tab theme-light {tutorialStep === 201 ? 'highlighted' : ''} {activeTabClass(Tab.Templates)}"
            on:click={() => activeTab = Tab.Templates}
          >
           Templates
          </button>
          <button 
            class="main-menu-popup__tab theme-light {tutorialStep === 201 ? 'highlighted' : ''} {activeTabClass(Tab.Integration)}"
            on:click={() => activeTab = Tab.Integration}
          >
           Integration
          </button>
          <button 
            class="main-menu-popup__tab theme-light {tutorialStep === 201 ? 'highlighted' : ''} {activeTabClass(Tab.Team)}"
            on:click={() => activeTab = Tab.Team}
          >
           Team
          </button>
        </div>
        <div class="main-menu-popup__body">
          <!-- {#if tutorialStep === 101}
            <div class="main-menu-popup__messaging-tutorial">
              <TutorialStep text="Open 'Messaging' tab and select 'Twilio'" />
            </div>
          {/if}
          {#if tutorialStep === 102}
            <div class="main-menu-popup__messaging-tutorial">
              <TutorialStep text="Open 'Messaging' tab and select 'Twilio'" />
            </div>
          {/if} -->
          <div class="main-menu-popup__view {activeViewPosition(Tab.Pending)}">
            <div class="main-menu-popup__automation">
              <!-- <MessagingBlock tutorialStep={tutorialStep} on:close={() => active = false} /> -->
                {#if signatureTabLoading}
                <div class="w-full flex items-center justify-center">
                  <img alt="" src="/EmoteHi.gif" style="max-width: 100px;" />
                </div>
                {:else}
                <div style="margin: 10px">
                  <label
                    class="whitespace-no-wrap bg-blue-500 hover:bg-blue-700 text-white
              font-bold py-1 px-3 md:px-4 rounded mr-3 cursor-pointer md:mr-4"
                    for="pdf"
                  >
                    Choose New PDF
                  </label>
                    <input
                    placeholder="Search Pending"
                    type="text"
                    class="flex-grow bg-transparent py-1 px-2 w-full"
                    style="border: 1px solid;border-radius: 5px;margin-top: 1em;"
                    bind:value={searchKeyPending}
                  />
                </div>
                {#if pendingSignature.length > 0}
                  {#each pendingSignature as sign}
                    {#if searchKeyPending == "" || sign.attachmentName
                        .toLowerCase()
                        .includes(searchKeyPending.toLowerCase())}
                      <div class="signItem">
                        <div>
                          <div>{sign.attachmentName}</div>
                        </div>
                        <div>
                          <div>{sign.author_email}</div>
                        </div>
                        <div class="action w-full flex items-center">
                          <span class="signed">Signed: {sign.date}</span>
                          <div class="flex items-center">
                            <span on:click={() => resendSignature(sign.id)}
                              >Resend</span
                            >
                            <span
                              on:click={() =>
                                shareSignature(
                                  sign.attachmentName,
                                  "",
                                  blog.siteurl + "/s/" + sign.id
                                )}>Share</span
                            >
                            <span
                              class="delete"
                              on:click={() => deleteSignature(sign.id)}
                              >Delete</span
                            >
                          </div>
                        </div>
                      </div>
                    {/if}
                  {/each}
                {:else}
                  <div class="text-center">There is no data.</div>
                {/if}
              {/if}
            </div>          
          </div>
          <div class="main-menu-popup__view {activeViewPosition(Tab.Completed)}">
            <div class="main-menu-popup__automation">
              {#if signatureTabLoading}
              <div class="w-full flex items-center justify-center">
                <img alt="" src="/EmoteHi.gif" style="max-width: 100px;" />
              </div>
              {:else}
              <div
              class="flex items-center justify-center p-2"
              style="gap: 10px;"
            >
              <b>Notify (email)</b>
              <div>
                <input
                  type="radio"
                  id="available-1"
                  name="email_available"
                  checked={emailNotifyAvailable}
                />
                <label
                  on:click={() => getEmailNotifyAvailable("enable")}
                  class="tab"
                  for="available-1"
                  >Enable
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  id="available-2"
                  name="email_available"
                  checked={!emailNotifyAvailable}
                />
                <label
                  on:click={() => getEmailNotifyAvailable("disable")}
                  class="tab"
                  for="available-2"
                  >Disable
                </label>
              </div>
            </div>
            <div style="margin: 10px;position:relative;">
              <input
                placeholder="Search Completed"
                type="text"
                class="flex-grow bg-transparent py-1 px-2 w-full"
                style="border: 1px solid;border-radius: 5px;"
                bind:value={searchKeyCompleted}
              />
              <img
                alt=""
                class="cursor-pointer"
                class:border-green={(tutorialStep == 3)}
                src="/archive_24dp.svg"
                style="position: absolute;top: 5px;right: 5px;"
                on:click={displayArchive}
              />
            </div>
            {#if showArchive}
              <div style="font-weight: 600;padding: 10px;">
                Archived Documents
              </div>
              {#if completedArchived.length > 0}
                {#each completedArchived as sign}
                  {#if searchKeyCompleted == "" || sign.pdfName
                      .toLowerCase()
                      .includes(searchKeyCompleted.toLowerCase())}
                    <div class="signItem">
                      <div>
                        <div>{sign.pdfName}</div>
                      </div>
                      <div class="action w-full flex items-center">
                        <span class="signed">Signed: {sign.date}</span>
                        <div class="flex items-center">
                          <span on:click={() => viewCompletedDoc(sign)}
                            >View</span
                          >
                          <span
                            class="delete"
                            on:click={() =>
                              deleteArchivedSignature(sign.id)}
                            >Delete</span
                          >
                        </div>
                      </div>
                    </div>
                  {/if}
                {/each}
              {:else}
                <div class="text-center">There is no data.</div>
              {/if}
            {:else if completedSignature.length > 0}
              <div style="font-weight: 600;padding: 10px;">
                Completed Documents
              </div>
              {#each completedSignature as sign}
                {#if searchKeyCompleted == "" || sign.attachmentName
                    .toLowerCase()
                    .includes(searchKeyCompleted.toLowerCase())}
                  <div class="signItem">
                    <div>
                      <div>{sign.attachmentName}</div>
                    </div>
                    <div>
                      <div>{sign.author_email}</div>
                    </div>
                    <div class="action w-full flex items-center">
                      <span class="signed">Signed: {sign.date}</span>
                      <div class="flex items-center">
                        <span
                          on:click={() =>
                            editCompletedDoc(
                              sign.signedPdf,
                              sign.attachmentName,
                              sign.attachmentUrl
                            )}>Edit</span
                        >
                        <span
                          class="sendTo"
                          on:click={() => updateSendToId(sign.id)}
                          >Send To</span
                        >
                        <!-- <a
                          href={"https://qix.cloud/" + sign.signedPdf}
                          target="_blank">View</a
                        > -->
                        <span on:click={() => viewCompletedDoc(sign)}
                          >View</span
                        >
                        <span
                          class="delete"
                          on:click={() => deleteSignature(sign.id)}
                          >Delete</span
                        >
                      </div>
                    </div>
                    {#if users.length > 0}
                      <SendTo
                        {chatUsers}
                        {users}
                        {sign}
                        {blog}
                        {user}
                        {sendToId}
                        {merusToken}
                        on:update={sendToFunc}
                      />
                    {/if}
                  </div>
                {/if}
              {/each}
            {:else}
              <div class="text-center">There is no data.</div>
            {/if}
              {/if}
            </div>          
          </div>
          <div class="main-menu-popup__view {activeViewPosition(Tab.Templates)}">
            <div class="main-menu-popup__automation">
              {#if signatureTabLoading}
              <div class="w-full flex items-center justify-center">
                <img alt="" src="/EmoteHi.gif" style="max-width: 100px;" />
              </div>
              {:else}
              {#if (user.id == user.appId || editTemplatesAvailable) && multiplePdfs.length == 0}
              <div style="margin: 10px">
                {#if processingSaveTemplate}
                  <button
                    class="whitespace-no-wrap py-1 px-3 md:px-4 rounded mr-3 cursor-pointer md:mr-4"
                    class:hidden={selectedPageIndex < 0}
                    style="border: 1px solid grey;"
                  >
                    <img
                      alt=""
                      src="/loading.gif"
                      style="max-width: 30px;"
                    />
                  </button>
                {:else if completedSaveTemplate}
                  <CheckMark />
                {:else}
                  <button
                    on:click={saveTemplate}
                    class="whitespace-no-wrap bg-blue-500 hover:bg-blue-700 text-white
      font-bold py-1 px-3 md:px-4 rounded mr-3 cursor-pointer md:mr-4"
                    class:border-green={tutorialStep == 6 }
                    class:hidden={selectedPageIndex < 0}
                  >
                    Save as Template
                  </button>
                {/if}
              </div>
            {/if}
            <Template
              {chatUsers}
              {merusToken}
              {templates}
              {blog}
              {user}
              {signColor}
              {editTemplatesAvailable}
              {tutorialStep}
              {tutorialNumber}
              on:select={handleLoadTemplate}
            />
              {/if}
            </div>          
          </div>
          <div class="main-menu-popup__view {activeViewPosition(Tab.Integration)}">
            <div class="main-menu-popup__automation">
              {#if signatureTabLoading}
              <div class="w-full flex items-center justify-center">
                <img alt="" src="/EmoteHi.gif" style="max-width: 100px;" />
              </div>
              {:else}
              <AutomationBlock bind:user={user} bind:fluentCaseToken={fluentCaseToken} tutorialStep={tutorialStep} on:close={() => isOpen = false} />
                <!-- <div style="margin: 10px">
                {#if merusCasesEnabled || fileVineEnabled } 
                  <input
                    placeholder="Enter FluentCase API Token"
                    class="flex-grow bg-transparent py-1 px-2 w-full"
                    style="border: 1px solid;border-radius: 5px;margin-bottom: 15px;"
                    bind:value={fluentCaseToken}
                  />
                  <button on:click={setFluentCaseToken(fluentCaseToken)} class="whitespace-no-wrap bg-blue-500 hover:bg-blue-700 text-white
                  font-bold py-1 px-3 md:px-4 rounded mr-3 cursor-pointer md:mr-4">
                    Save FluentCase Token
                  </button>
                {/if}
              </div> -->
              {/if}
            </div>          
          </div>
          <div class="main-menu-popup__view {activeViewPosition(Tab.Team)}">
            <!-- {#if tutorialStep === 401}
              <div class="main-menu-popup__messaging-tutorial">
                <TutorialStep text="Open 'Team' tab and copy the invite-link to share with someone you want to invite" />
              </div>
            {/if} -->
            <div class="main-menu-popup__team">
              {#if signatureTabLoading}
              <div class="w-full flex items-center justify-center">
                <img alt="" src="/EmoteHi.gif" style="max-width: 100px;" />
              </div>
              {:else}
              <div style="margin: 10px">
                {#if user.id == user.appId}
                  <div
                    class="flex justify-center"
                    style="gap: 10px;margin-bottom: 15px;"
                  >
                    <b>Edit Templates (staff)</b>
                    <div>
                      <input
                        type="radio"
                        id="available-1"
                        name="email_available"
                        checked={editTemplatesAvailable}
                        on:click={() =>
                          editTemplatesAvailableFunc("enable")}
                      />
                      <label
                        on:click={() =>
                          editTemplatesAvailableFunc("enable")}
                        class="tab"
                        for="available-1"
                        >Enable
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="available-2"
                        name="email_available"
                        checked={!editTemplatesAvailable}
                        on:click={() =>
                          editTemplatesAvailableFunc("disable")}
                      />
                      <label
                        on:click={() =>
                          editTemplatesAvailableFunc("disable")}
                        class="tab"
                        for="available-2"
                        >Disable
                      </label>
                    </div>
                  </div>
                {/if}
                                    <div
                class="flex items-center justify-center p-2"
                style="gap: 10px;"
              >
                <b>View Completed Documents</b>
                <div>
                  <input
                    type="radio"
                    id="view-completed-available-1"
                    name="view_completed_documents_available"
                    checked={teamViewCompletedDocAvailable}
                    on:change={() => getTeamViewCompletedDocAvailable("enable")}
                  />
                  <label class="tab" for="view-completed-available-1">Enable</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="view-completed-available-2"
                    name="view_completed_documents_available"
                    checked={!teamViewCompletedDocAvailable}
                    on:change={() => getTeamViewCompletedDocAvailable("disable")}
                  />
                  <label class="tab" for="view-completed-available-2">Disable</label>
                </div>
              </div>
                <input
                  placeholder="Search Team"
                  type="text"
                  class="flex-grow bg-transparent py-1 px-2 w-full"
                  style="border: 1px solid;border-radius: 5px;margin-bottom:15px;"
                  bind:value={searchKeyFriend}
                />
                <div class:hidden={!inviteFriendPanel}>
                  <div class="w-full flex items-center">
                    <div id="inviteclipboard" />
                    <div
                      class="flex items-center"
                      class:border-green={tutorialStep == 3 && (tutorialNumber == 6)}
                      style="justify-content: space-between;width: 100%;"
                      class:hidden={inviteClipboardTxt == ""}
                    >
                      <span style="word-break: break-all;"
                        >{inviteClipboardTxt}</span
                      >
                      <img
                        src="filter.svg"
                        class="cursor-pointer"
                        style="width: 30px; margin-left: 15px;"
                        on:click={copyInviteUrl}
                        alt="Copy"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {#if friends.length > 0}
                {#each friends as friend}
                  {#if searchKeyFriend == "" || friend.email.includes(searchKeyFriend.toLowerCase())}
                    <div
                      class="signItem flex items-center"
                      style="justify-content: space-between;"
                    >
                      <div>
                        <div class="flex items-center" style="gap: 10px;">
                          {#if friend.id === user.appId}
                            <svg
                              class="member__star"
                              xmlns="http://www.w3.org/2000/svg"
                              version="1.1"
                              width="12"
                              height="12"
                              x="0"
                              y="0"
                              viewBox="0 0 511.987 511"
                              style="enable-background:new 0 0 512 512"
                              xml:space="preserve"
                              ><g
                                ><path
                                  fill="#ffc107"
                                  d="M510.652 185.902a27.158 27.158 0 0 0-23.425-18.71l-147.774-13.419-58.433-136.77C276.71 6.98 266.898.494 255.996.494s-20.715 6.487-25.023 16.534l-58.434 136.746-147.797 13.418A27.208 27.208 0 0 0 1.34 185.902c-3.371 10.368-.258 21.739 7.957 28.907l111.7 97.96-32.938 145.09c-2.41 10.668 1.73 21.696 10.582 28.094 4.757 3.438 10.324 5.188 15.937 5.188 4.84 0 9.64-1.305 13.95-3.883l127.468-76.184 127.422 76.184c9.324 5.61 21.078 5.097 29.91-1.305a27.223 27.223 0 0 0 10.582-28.094l-32.937-145.09 111.699-97.94a27.224 27.224 0 0 0 7.98-28.927zm0 0"
                                  data-original="#ffc107"
                                  class=""
                                ></path></g
                              ></svg
                            >
                          {/if}
                          {friend.email}
                          <span
                            style={Date.now() - friend.loggedIn >
                            432000000
                              ? "color: red;font-size: 12px;"
                              : "font-size: 12px;"}
                          >
                            &nbsp;&nbsp;( Logged In {timeAgo(
                              friend.loggedIn
                            )} )
                          </span>
                        </div>
                      </div>
                      <!-- <div class="action">
                        <div class="flex items-center">
                          <span
                            class="delete"
                            on:click={() => deleteFriend(friend.id)}
                            >Delete</span
                          >
                        </div>
                      </div> -->
                    </div>
                  {/if}
                {/each}
              {:else}
                <div class="text-center">There is no data.</div>
              {/if}
              {/if}
            </div>
          </div>
        </div>
        
      </div>
    </DefaultPopup>
  {/if}
</div>

<style lang="scss" scoped>

  @import '../../style/variables.scss'; // Adjust the path as needed

  :global(.main-menu-popup) {
    max-width: 1100px;
    padding: 15px 25px;
    width: calc(100% - 30px);
    height: 80vh;

    @media (max-width: $laptop-width) and (min-width: $phone-width-lg) {
      height: 90vh;
    }

    @media(max-width: $phone-width-lg) {
      padding: 15px 15px;
    }
  }

  .transition-color {
    transition: color 0.3s;
  }

  .main-menu-popup {

    &__inner {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &__user {
      margin: 10px 0 30px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-right: auto;

      @media (max-width: $laptop-width) {
        margin-bottom: 10px;
      }
    }

    &__user-type {
      width: 22px;
      height: auto;
      aspect-ratio: 1 / 1;
      margin-right: 15px;

      @media (max-width: $laptop-width) {
        width: 22px * $laptop-width-mp;
      }
    }

    &__logout {
      width: 100%;
      margin-left: 20px;
      background-color: #fff;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      font-weight: 600;
      color: #009AE4;
      cursor: pointer;
      margin-right: 60px;
      transition: background-color 0.3s; 

      &:hover {
        background-color: #e4e4e4;
      }

      &.theme-dark {
        background-color: $dark-bg;

        &:hover {
          background-color: $dark-bg-darker;
        }
      }
    }

    &__tabs {
      display: flex;
      align-items: center;
      position: relative;
      margin-right: auto;
      margin-bottom: 30px;
      padding-left: 5px;

      @media (max-width: $laptop-width) {
        margin-bottom: 30px;
        padding-left: 0;
      }

      @media(max-width: $phone-width-lg) {
        margin-bottom: 50px;
      }
    }

    &__tab {
      width: 33.333%;
      max-width: 200px;
      padding: 10px 50px;
      font-size: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000;
      transition: color 0.4s;
      position: relative;
      z-index: 2;
      cursor: pointer;
      background-color: transparent;
      border: none;
      outline: none;
      font-weight: 500;
      transition: color 0.3s;

      &.theme-dark {
        color: #fff;
      }

      @media(max-width: $laptop-width) {
        font-size: 0.9em;
        padding: 7px 25px;
      }

      &--active, &:hover  {
        color: #00bbf9;
      }
    }

    &__tab-indicator {
      color: transparent;
      width: 20%;
      max-width: 200px;
      padding: 10px 20px;
      background-color: #def2fd;
      border-radius: 30px;
      position: absolute;
      z-index: 1;
      left: 0;
      transition: transform 0.4s, background-color 0.3s;

      &.theme-dark {
        background-color: $dark-bg-darker;
      }

      @media(max-width: $laptop-width) {
        font-size: 0.9em;
        padding: 7px 25px;
      }
    
      &--completed {
        transform: translateX(100%);
      }

      &--templates {
        transform: translateX(210%);
      }
      &--integration {
        transform: translateX(315%);
      }
      &--team {
        transform: translateX(410%);
      }
    }

    &__body {
      position: relative;
      width: 100%;
      flex-grow: 1;
      overflow: auto;
      overflow-x: hidden;
      scrollbar-width: none;
      -moz-scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #c4c4c4;
      }

    }

    &__view {
      position: absolute;
      width: 100%;
      min-height: 100%;
      top: 0;
      left: 0;
      display: flex;
      transition: transform 0.4s;

      &--left {
        transform: translateX(-150%);
      }

      &--right {
        transform: translateX(150%);
      }
    }

    &__messaging-tutorial {
      position: absolute;
      bottom: 20px;
      left: 20px;
      z-index: 10;
      max-width: 70%;
    }

    &__team {
      max-width: 1700px;
      width: 100%;
      margin: 0 auto;
    }

    &__automation {
      max-width: 970px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
    }
  }
</style>

