<svelte:options immutable={true} />

<script lang="ts">
  import { onMount, createEventDispatcher } from "svelte";
  import { pannable } from "./utils/pannable.js";
  export let display;
  export let user_data;
  const dispatch = createEventDispatcher();
  const currentYear = new Date().getFullYear();
  async function render() {}
  onMount(render);
  function onHide() {
    dispatch("hide");
  }
  function onReload() {
    location.reload();
  }
  function gotoPage(link) {
    if (link == "") {
      window.parent.location.href = "https://legalpal.app/";
      //} else if (link == "sign") {
      //  window.parent.location.href = "https://legalpal.app/" + link;
      //} else if (user.appId != "") {
      //  window.parent.location.href = "https://legalpal.app/" + link; // + "?gid=" + user.appId;
    } else {
      window.parent.location.href = "https://legalpal.app/" + link;
    }
  }
</script>

<button class="main-sidebar__open" on:click={onHide}>
  <svg
    class="main-sidebar__open-icon"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0"
    y="0"
    viewBox="0 0 6.35 6.35"
    style="enable-background:new 0 0 512 512"
    xml:space="preserve"
    ><g
      ><path
        d="M2.258 1.315a.265.265 0 0 0-.174.469L3.703 3.17l-1.62 1.386a.265.265 0 1 0 .345.4L4.28 3.373a.265.265 0 0 0 0-.403L2.428 1.382a.265.265 0 0 0-.17-.067z"
        fill="#000000"
        data-original="#000000"
        class=""
      /></g
    ></svg
  >
</button>
<div
  class="left-menu-wrap fixed left-0 top-0 select-none"
  class:pending={!display}
  on:mouseleave={onHide}
>
  <div class="w-full main-sidebar">
    <div
      class="w-full flex items-center"
      style="justify-content: flex-end; font-size: 25px;"
    >
      <span on:click={onHide} class="px-1 cursor-pointer">×</span>
    </div>
    <div
      class="w-full flex items-center cursor-pointer logo"
      style="margin-bottom: 50px;"
      on:click={() => gotoPage("")}
    >
      <img alt="" class="qix-logo" src="/img/logo.svg" />
    </div>
    <a
      class="w-full flex items-center item cursor-pointer"
      on:click|preventDefault={() => gotoPage("sign")}
      href="https://legalpal.app/sign"
    >
      <img src="/img/signAsset.svg" alt="" />Sign
    </a>
    <a
      class="w-full flex items-center item cursor-pointer"
      on:click|preventDefault={() => gotoPage("chat")}
      href="https://legalpal.app/chat"
    >
      <img src="/img/chatAsset.svg" alt="" />Chat
    </a>
    <a
      class="w-full flex items-center item cursor-pointer"
      on:click|preventDefault={() => gotoPage("app/")}
      href="https://legalpal.app/app"
    >
      <img src="/img/appAsset.svg" alt="" />App
    </a>
    <a
      class="w-full flex items-center item cursor-pointer"
      on:click|preventDefault={() => gotoPage("api")}
      href="https://legalpal.app/api"
    >
      <img src="/img/cloudAsset.svg" alt="" />Api
    </a>
    <div
      class="w-full flex items-center item cursor-pointer"
      on:click|preventDefault={() =>
        (window.parent.location.href = "https://legalpal.app/account")}
    >
      <img alt="" src="account_icon.png" />Account
    </div>
    <div
      class="w-full flex items-center item cursor-pointer"
      on:click|preventDefault={() =>
        (window.parent.location.href = "https://legalpal.app/help")}
    >
      <img alt="" src="help.png" />Help
    </div>
  </div>
  <div class="menu-footer">
    <a
      on:click|preventDefault={() =>
        (window.parent.location.href = "https://legalpal.app/privacy-policy")}
      style="cursor: pointer;">Privacy Policy</a
    >
    <div>Copyright LegalPal Inc.<br />{currentYear}</div>
  </div>
</div>

<style>
  .qix-logo {
    width: 65px;
  }

  .main-sidebar a {
    color: #000;
  }

  .main-sidebar__open {
    position: fixed;
    bottom: 20px;
    left: 0;
    background-color: #fff;
    width: 70px;
    height: 70px;
    box-shadow: 5px 0 10px gray;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    transform: scale(1) translate(-50%);
    cursor: pointer;
    border: none;
    outline: none;
    transition:
      background-color 0.3s,
      opacity 0.4s,
      visibility 0.4s,
      transform 0.4s;
    transform-origin: 20% 50%;
  }
  @media (max-width: 768px) {
    .main-sidebar__open {
      bottom: 70px;
    }
  }
  .main-sidebar__open:hover {
    background-color: #e8e8e8;
  }
  .main-sidebar__open-icon {
    width: 20px;
    height: auto;
    pointer-events: none;
  }
  .menu-footer {
    position: absolute;
    bottom: 20px;
    left: 0;
    text-align: center;
    width: 100%;
  }
  .menu-footer > a {
  }
  .menu-footer > div {
    margin-top: 10px;
    font-size: 12px;
  }
  .left-menu-wrap {
    height: 100vh;
    width: 200px;
    background: #fff;
    z-index: 99;
    box-shadow: 5px 0px 10px 0px grey;
    transition: 0.5s;
  }
  .left-menu-wrap.pending {
    left: -300px;
  }
  .logo {
    padding: 0 15px;
  }
  .item {
    padding: 15px;
  }
  .item:hover {
    background: #e8e8e8;
  }
  .item img {
    margin-right: 10px;
    width: 30px;
  }
</style>
