import { writable } from 'svelte/store';
import { type IChat, type ITwilioSettings, type IUser, type IFullChat, type IChatsSettings, type IMessage, type ITag, type IEvent, type IResProject, type INotifySettings, type IChatMember, type IResChatData } from './../typesTs';
import { user as storeUser } from './auth.store';
import { team as storeTeam } from './team.store';
//@ts-ignore
import Predictionary from 'predictionary' //only if installed via npm


const VITE_API_LINK = "https://api.qix.cloud/";
const VITE_AUTH_M_REDIRECT_URI = "https://api.qix.cloud/microsoftRedirectSign";
const VITE_AUTH_REDIRECT_URI = "https://api.qix.cloud/googleRedirectSign";
//const VITE_BASE_LINK = "https://qixcloud-chat.pages.dev";
const VITE_ORIGIN_LINK = "https://legalpal.app/sign";

let user:IUser;
let team:IUser[] = [];

storeUser.subscribe((value) => {
  user = value;
});

storeTeam.subscribe((value) => {
  team = value;
});

export function isValidTwilioPhoneNumber(phoneNumber: string): boolean {
  // E.164 format: + followed by 10 to 15 digits
  const e164Regex = /^\+?[1-9]\d{9,14}$/;

  return e164Regex.test(phoneNumber);
}

export function chatPhoneCanNotBeReached(chat: IChat): boolean {
  return ['30003','30005','30006'].includes(chat.twilioLastErr)
}

export function chatPhoneIsBlocked(chat: IChat): boolean {
  return ['21610','30004'].includes(chat.twilioLastErr)
}

let messagesStream:EventSource;
export const subscribeMessageStream = ():void => {
  if (user) {
    messagesStream = new EventSource(VITE_API_LINK + 'subscribeMessagesStream?au=' + localStorage.getItem('token'));

    messagesStream.addEventListener('message', (message) => {
      if (message.data) {
        const data = JSON.parse(message.data);

        if (data.message && currentChatCopy && data.conversationId === currentChatCopy.chat.id) {
          const newMessage = data.message as IMessage;

          if (newMessage.author != user.id || newMessage.source === 'server') {
            currentChatCopy.messages.push(newMessage);
            currentChatCopy.chat.chatStatus = 'active';
            const savedChat = currentChatCopy.chat;

            readChat(currentChatCopy.chat.id).finally(() => {
              currentChat.set({
                chat: savedChat,
                messages: currentChatCopy.messages
              });
            });
          }
          
        } else if (!currentChatCopy && data.conversationId && data.message) {
          const newMessage = data.message as IMessage;
          const chatIndex = chatsCopy.findIndex(chat => chat.id === data.conversationId);

          if (chatIndex > -1) {
            chatsCopy[chatIndex].lastMessage = {
              text: data.message.text,
              author: data.message.author
            };

            chatsCopy[chatIndex].chatStatus = 'active';

            if (chatsCopy[chatIndex].chatType === 'chatWithClient') {

              // if (chatsCopy[chatIndex].members[newMessage.author].role === 'client' && chatsCopy[chatIndex].members[newMessage.author]) {
              //   chatsCopy[chatIndex].lastActivity = data.message.date;

              //   if (!chatsCopy[chatIndex].members[user.id]) {
              //     chatsCopy[chatIndex].members[user.id] = {
              //       status: 'unread',
              //       role: 'team',
              //       newMessages: 0,
              //     }
              //   }
                
              //   chatsCopy[chatIndex].members[user.id].status = 'unread';
              //   chatsCopy[chatIndex].members[user.id].newMessages++;

              //   if (!chatsCopy[chatIndex].members[chatsCopy[chatIndex].appId]) {
              //     chatsCopy[chatIndex].members[chatsCopy[chatIndex].appId] = {
              //       status: 'unread',
              //       role: 'team',
              //       newMessages: 0,
              //     }
              //   }
                
              //   chatsCopy[chatIndex].members[chatsCopy[chatIndex].appId].status = 'unread';
              //   chatsCopy[chatIndex].members[chatsCopy[chatIndex].appId].newMessages++;
              // } else {
              //   if (!chatsCopy[chatIndex].members[user.id]) {
              //     chatsCopy[chatIndex].members[user.id] = {
              //       status: 'replied',
              //       role: 'team',
              //       newMessages: 0,
              //     }
              //   }

              //   if (chatsCopy[chatIndex].members[user.id].status != 'assigned') {
              //     chatsCopy[chatIndex].members[user.id].status = 'replied';
              //   }

              //   chatsCopy[chatIndex].members[user.id].newMessages = 0;

              //   if (!chatsCopy[chatIndex].members[chatsCopy[chatIndex].appId]) {
              //     chatsCopy[chatIndex].members[chatsCopy[chatIndex].appId] = {
              //       status: 'replied',
              //       role: 'team',
              //       newMessages: 0,
              //     }
              //   }

              //   if (chatsCopy[chatIndex].members[chatsCopy[chatIndex].appId].status != 'assigned') {
              //     chatsCopy[chatIndex].members[chatsCopy[chatIndex].appId].status = 'replied';
              //   }

              //   chatsCopy[chatIndex].members[chatsCopy[chatIndex].appId].newMessages = 0;
              // }

              getChat(data.conversationId);
            } else {
              chatsCopy[chatIndex].lastActivity = data.message.date;
              chatsCopy[chatIndex].members[user.id].status = 'unread';
              chatsCopy[chatIndex].members[user.id].newMessages++;
            }

            chats.set(chatsCopy);

          }
        } else if (data.other && data.other.event && data.conversationId && data.other.user) {
          if (data.other.event === 'typing' && data.other.user) {
            if (!chatsTypingCopy[data.conversationId]) {
              chatsTypingCopy[data.conversationId] = {};
            }

            chatsTypingCopy[data.conversationId][data.other.user] = {
              time: Date.now(),
              text: data.other.text || '',
            }
            chatsTyping.set(chatsTypingCopy);
          }
        }
      }
    });

    messagesStream.addEventListener('error', err => {
      messagesStream.close();

      setTimeout(() => {
        subscribeMessageStream();
      }, 1000);
      console.error(err);
    }, {once: true});
  }
};

export const chats = writable<IChat[]>([]);
export const teamChats = writable<IChat[]>([]);
export const lastChatsGetRequest = writable<number>(0);
let chatsCopy:IChat[] | null = null;
let chatsMap:Map<string, IChat> = new Map();
chats.subscribe((value:IChat[]) => {
  chatsCopy = value;
  chatsMap = new Map(value.map(el => [el.id, el]));
});

export const chatsTyping = writable<{[key:string]: {[key:string]: {time: number, text:string}}}>({});
let chatsTypingCopy:{[key:string]: {[key:string]: {time: number, text:string}}} = {};

setInterval(() => {
  const now = Date.now();

  Object.keys(chatsTypingCopy).forEach(chat => {
    Object.keys(chatsTypingCopy[chat]).forEach(member => {
      if ((now - chatsTypingCopy[chat][member].time > 60_000) || !chatsTypingCopy[chat][member].text) {
        delete chatsTypingCopy[chat][member];
        chatsTyping.set(chatsTypingCopy);
      } 
    })
  })
}, 1000);

chatsTyping.subscribe(value => {
  chatsTypingCopy = value;
})

export const eventTypes = writable<string[]>([]);
export const eventsList = writable<IEvent[]>([]);

export const selectedTags = writable<ITag[]>(JSON.parse(String(localStorage.getItem('selectedTags') || '[]')) || []);
export const selectedAssigned = writable<string[]>(JSON.parse(String(localStorage.getItem('selectedAssigned') || '[]')) || []);
export const selectedCaseType = writable<string>(String(localStorage.getItem('selectedCaseType') || '') || '');
export const selectedCasePhase = writable<string>(String(localStorage.getItem('selectedCasePhase') || '') || '');

selectedTags.subscribe(value => {
  localStorage.setItem('selectedTags', JSON.stringify(value) || '');
});

selectedAssigned.subscribe(value => {
  localStorage.setItem('selectedAssigned', JSON.stringify(value) || '');
});

selectedCaseType.subscribe(value => {
  localStorage.setItem('selectedCaseType', value || '');
});

selectedCasePhase.subscribe(value => {
  localStorage.setItem('selectedCasePhase', value || '');
});

export const fetchedTeamChats = writable<boolean>(false);
export const fetchedFull = writable<boolean>(false);
let fetchedFullCopy:boolean = false;

fetchedFull.subscribe(value => {
  fetchedFullCopy = value;
});

export const getChats = (status?:string, full?:boolean, force?:boolean):Promise<void> => {
  return new Promise((resolve, reject) => {
    if (fetchedFullCopy && full && !force) {
      resolve();
    } else {
      fetch(VITE_API_LINK + `chats?status=${status || 'active'}&full=${full ? 'true' : ''}`, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
      }).then((res) => {
        if (res.status >= 300) {
          reject();
        } else {
          return res.json();
        }
      }).then((res:IChat[]) => {
        const result:IChat[] = [];
  
        eventTypes.set(Array.from(new Set(res.map(el => el.events.map(e => e.eventType)).flat(1).filter(e => e.length))));
        eventsList.set(res.map(el => el.events.map(e => Object.assign({...e}, {chatId: el.id}))).flat(1).filter(el => el.name != 'Sample Event'));
  
        res.forEach((el:IChat) => {
          const realMembers = JSON.parse(JSON.stringify(el.members));
          const members = Object.values(el.members);
          const chatWith = members.find(member => member.role === 'client' && member.id != user.id) || members.find(member => member.id != user.id);
  
          if (el.members[user.id]?.status === 'assigned' && el.members[el.appId]) {
            el.members[el.appId].status = 'assigned';
          }
  
          if (el.members[el.appId]?.status === 'assigned' && el.members[user.id]?.status !== 'assigned') {
            el.members[el.appId].status = el.members[user.id]?.status;
          }
  
          if (el.members[el.appId]?.status === 'assigned' && !el.members[user.id]) {
            el.members[el.appId].status = el.members[el.appId].newMessages ? 'unread' : 'new';
          }

          if (chatWith) {
  
            const chat:IChat = {
              id: el.id,
              appId: el.appId,
              img: String(el.img || ''),
              chatStatus: String(el.chatStatus || ''),
              chatType: String(el.chatType),
              language: String(el.language || ''),
              fullName: String(el.fullName || ''),
              phone: String(el.phone || ''),
              email: String(el.email || ''),
              birthday: String(el.birthday || ''),
              accountStatus: String(el.accountStatus || ''),
              lastActivity: +el.lastActivity || 0,
              lastMessage: {
                text: String(el.lastMessage.text || ''),
                author: String(el.lastMessage.author || ''),
              },
              with: String(chatWith.id || ''),
              members: el.members,
              realMembers: realMembers,
              noteids: el.noteids || [],
              tags: el.tags || [],
              cases: el.cases || [],
              party: el.party || [],
              events: el.events || [],
              advanced: el.advanced || {},
              notifyExcludedEventTypes: el.notifyExcludedEventTypes,
              device: el.device,
              creationTime: el.creationTime,
              appInstalled: el.appInstalled,
              caseFileIdsMetaMap: el.caseFileIdsMetaMap,
              caseFileIdsNameMap: el.caseFileIdsNameMap,
              fileVineProjectIdsMetaMap: el.fileVineProjectIdsMetaMap,
              notes: el.notes || [],
              assignedTo: el.assignedTo,
              twilioLastErr: el.twilioLastErr,
            }

            result.push(chat);

            if (currentChatCopy && currentChatCopy.chat && el.id === currentChatCopy.chat.id) {
              setCurrentChat(chat);
            }
          }
        })

        lastChatsGetRequest.set(Date.now());
        
        if (full) {
          chats.set(result);
          fetchedFull.set(true);
        } else {
          const newChats = [...chatsCopy];

          result.forEach(el => {
            if (!chatsMap.has(el.id)) {
              newChats.push(el);
            }
          });

          chats.set(newChats);
        }

        resolve();
      }).catch(() => {
        reject();
      });
    }
  });
};

export const getTeamChats = ():Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + `chats?team=true`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
    }).then((res) => {
      if (res.status >= 300) {
        reject();
      } else {
        return res.json();
      }
    }).then((res:IChat[]) => {
      const result:IChat[] = [];

      
      res.forEach((el:IChat) => {
        const realMembers = JSON.parse(JSON.stringify(el.members));
        const members = Object.values(el.members);
        const chatWith = members.find(member => member.role === 'team');

        if (chatWith) {
          const chat:IChat = {
            id: el.id,
            appId: el.appId,
            img: el.img,
            chatStatus: String(el.chatStatus),
            chatType: el.chatType,
            language: String(el.language || ''),
            fullName: String(el.fullName || ''),
            phone: String(el.phone || ''),
            email: String(el.email || ''),
            birthday: String(el.birthday || ''),
            accountStatus: String(el.accountStatus || ''),
            lastActivity: el.lastActivity,
            lastMessage: el.lastMessage,
            with: String(chatWith.id),
            members: el.members,
            realMembers: realMembers,
            tags: el.tags || [],
            cases: el.cases || [],
            party: el.party || [],
            events: el.events || [],
            advanced: el.advanced || {},
            notifyExcludedEventTypes: el.notifyExcludedEventTypes,
            device: el.device,
            creationTime: el.creationTime,
            appInstalled: el.appInstalled,
          }

          result.push(chat);
        }
      })
      
      fetchedTeamChats.set(true);
      teamChats.set(result);

      resolve();
    }).catch((err) => {
      console.error(err)
      reject();
    });
  });
};

export const getChat = (id:string):Promise<IChat> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + 'chat/' + id, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
    }).then((res) => {
      if (res.status >= 300) {
        reject();
      } else {
        return res.json();
      }
    }).then((res:IChat) => {
      if (res && chatsCopy) {
        const realMembers = JSON.parse(JSON.stringify(res.members));
        const chatWith = (Object.values(res.members).find(member => member.role === 'client' && member.id != user.id) || Object.values(res.members).find(member => member.id != user.id));

        Object.assign(res, { with: chatWith.id, realMembers });

        const chatIndex = chatsCopy.findIndex(chat => chat.id === id);

        if (chatIndex > -1) {
          chatsCopy[chatIndex] = res;
        } else {
          chatsCopy.push(res);
        }

        chats.set(chatsCopy);
        eventTypes.set(Array.from(new Set(chatsCopy.map(el => el.events.map(e => e.eventType)).flat(1).filter(e => e.length))));
        eventsList.set(chatsCopy.map(el => el.events.map(e => Object.assign({...e}, {chatId: el.id}))).flat(1).filter(el => el.name != 'Sample Event'));

        if (currentChatCopy?.chat?.id === id) {
          setCurrentChat(res);
        }
        
        resolve(res);
      } else {
        reject();
      }
    }).catch((err) => {
      console.error(err);
      reject();
    });
  });
};

export const addChat = (phone:string, email?:string, name?:string, ignore?:boolean, caseFileId?: string, fileVineProjectId?: string):Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + 'chat', {
      method: 'POST',
      body: JSON.stringify({
        phone: String(phone),
        email,
        name,
        caseFileId,
        fileVineProjectId,
      }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
    }).then((res) => {
      if (res.status >= 300) {
        reject();
      } else if (!ignore) {
        getChats(undefined, true, true).finally(() => {
          resolve();
        });
      } else {
        resolve();
      }
    }).catch(() => {
      reject();
    });
  });
};

export const editChat = (chat:IChat):Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + 'chat', {
      method: 'PUT',
      body: JSON.stringify({
        id: chat.id,
        img: chat.img,
        language: String(chat.language || ''),
        fullName: String(chat.fullName || ''),
        phone: String(chat.phone || ''),
        email: String(chat.email || ''),
        birthday: String(chat.birthday || ''),
        accountStatus: String(chat.accountStatus || ''),
        cases: chat.cases || [],
        party: chat.party || [],
        tags: chat.tags || [],
        events: chat.events || [],
        advanced: chat.advanced || { fileVineProjectIds: [], caseFileIds: [] },
        notifyExcludedEventTypes: chat.notifyExcludedEventTypes,
        noteids: chat.noteids || [],
        notes: chat.notes || [],
      }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
    }).then((res) => {
      if (res.status >= 300) {
        reject();
      } else {
        getChat(chat.id).then((newChat) => {
          setCurrentChat(newChat);
          resolve();
        }).catch(err => {
          console.error(err);
          reject();
        });
      }
    }).catch(() => {
      reject();
    });
  });
};

export const editChatCaseFileNamesMap = (chatId: string, map: {[key: string]: string}):Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + 'chat', {
      method: 'PUT',
      body: JSON.stringify({
        id: chatId,
        caseFileIdsNameMap: map,
      }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
    }).then((res) => {
      if (res.status >= 300) {
        reject();
      } else {
        resolve();
      }
    }).catch(() => {
      reject();
    });
  });
};

export const editChatCaseFileMetaMap = (chatId: string, map: {[key: string]: {type?: string, phase: string}}):Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + 'chat', {
      method: 'PUT',
      body: JSON.stringify({
        id: chatId,
        caseFileIdsMetaMap: map,
      }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
    }).then((res) => {
      if (res.status >= 300) {
        reject();
      } else {
        getChat(chatId)
          .finally(() => {
            resolve();
          })
      }
    }).catch(() => {
      reject();
    });
  });
};

export const editChatFileVineProjectIdsNameMap = (chatId: string, map: {[key: string]: string}):Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + 'chat', {
      method: 'PUT',
      body: JSON.stringify({
        id: chatId,
        fileVineProjectIdsNameMap: map,
      }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
    }).then((res) => {
      if (res.status >= 300) {
        reject();
      } else {
        resolve();
      }
    }).catch(() => {
      reject();
    });
  });
};

export const editChatFileVineProjectIdsMetaMap = (chatId: string, map: {[key: string]: {type?: string, phase?: string}}):Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + 'chat', {
      method: 'PUT',
      body: JSON.stringify({
        id: chatId,
        fileVineProjectIdsMetaMap: map,
      }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
    }).then((res) => {
      if (res.status >= 300) {
        reject();
      } else {
        getChat(chatId)
          .finally(() => {
            resolve();
          })
      }
    }).catch(() => {
      reject();
    });
  });
};


export const deleteChat = (id:string):Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + 'chat/' + id, {
      method: 'DELETE',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
    }).then((res) => {
      if (res.status >= 300) {
        reject();
      } else {
        return res.json();
      }
    }).then(() => {
      currentChat.set(null);
      const chatIndex = chatsCopy.findIndex(chat => chat.id === id);

      if (chatIndex > -1) {
        chatsCopy.splice(chatIndex, 1);
      }

      chats.set(chatsCopy);
      eventTypes.set(Array.from(new Set(chatsCopy.map(el => el.events.map(e => e.eventType)).flat(1).filter(e => e.length))));
      eventsList.set(chatsCopy.map(el => el.events.map(e => Object.assign({...e}, {chatId: el.id}))).flat(1).filter(el => el.name != 'Sample Event'));

      chats.set(chatsCopy);
      resolve();
    }).catch((err) => {
      console.error(err);
      reject();
    });
  });
};

export const readChat = (id:string):Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + 'chatRead', {
      method: 'POST',
      body: JSON.stringify({
        chat: String(id),
        userId: user.appId
      }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
    }).then((res) => {
      if (res.status >= 300) {
        reject();
      } else {
        resolve();
      }
    }).catch(() => {
      reject();
    });
  });
};

export const assignChat = (chat:string, to:string):Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + 'assignChat', {
      method: 'POST',
      body: JSON.stringify({
        chat,
        to
      }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
    }).then((res) => {
      if (res.status >= 300) {
        reject();
      } else {
        resolve();
      }
    }).catch(() => {
      reject();
    });
  });
};

export const unassignChat = (chat:string, from:string):Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + 'assignChat', {
      method: 'DELETE',
      body: JSON.stringify({
        chat,
        user: from
      }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
    }).then((res) => {
      if (res.status >= 300) {
        reject();
      } else {
        resolve();
      }
    }).catch(() => {
      reject();
    });
  });
};

export const activateChat = (chat:string):Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + 'activateChat', {
      method: 'POST',
      body: JSON.stringify({
        chat,
      }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
    }).then((res) => {
      if (res.status >= 300) {
        reject();
      } else {
        getChat(chat).finally(() => {
          resolve();
        });
      }
    }).catch(() => {
      reject();
    });
  });
};

export const archiveChat = (chat:string):Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + 'archiveChat', {
      method: 'POST',
      body: JSON.stringify({
        chat,
      }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
    }).then((res) => {
      if (res.status >= 300) {
        reject();
      } else {
        getChat(chat).finally(() => {
          resolve();
        });
      }
    }).catch(() => {
      reject();
    });
  });
};

export const currentChat = writable<IFullChat | null>(null);
let currentChatCopy:IFullChat | null = null;
currentChat.subscribe((value:IFullChat | null) => {
  currentChatCopy = value;
});

export const setCurrentChat = (chat:IChat):void => {
  if (currentChatCopy?.chat?.id !== chat.id) {
    readChat(chat.id);
  }

  currentChat.set({
    chat,
    messages: currentChatCopy && currentChatCopy.chat && currentChatCopy.chat.id === chat.id ? currentChatCopy.messages || [] : [],
  });
};

export const setCurrentChatById = (chatId:string):void => {
  const chat = chatsCopy.find(_chat => _chat.id === chatId);

  if (chat) {
    if (currentChatCopy?.chat?.id !== chatId) {
      readChat(chatId);
    }

    currentChat.set({
      chat,
      messages: currentChatCopy && currentChatCopy.chat && currentChatCopy.chat.id === chat.id ? currentChatCopy.messages || [] : [],
    });
  } else {
    currentChat.set({
      chat: {
        id: '',
        appId: '',
        chatStatus: '',
        chatType: '',
        tags: [],
        cases: [],
        party: [],
        events: [],
        lastMessage: {
          text: '',
          author: '',
        },
        lastActivity: 0,
        fullName: '...',
        advanced: {},
        with: '',
        members: {},
      },
      messages: [],
    });

    getChat(chatId).then(() => {
      const chat = chatsCopy.find(_chat => _chat.id === chatId);

      if (currentChatCopy?.chat?.id !== chatId) {
        readChat(chatId);
      }
  
      currentChat.set({
        chat,
        messages: (currentChatCopy && currentChatCopy.chat && currentChatCopy.chat.id === chat.id ? currentChatCopy.messages || [] : []) || [],
      });
    })
  }
};

export const getMessages = (chat:IChat):Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + 'messages?all=true&chat=' + chat.id, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
    }).then((res) => {
      if (res.status >= 300) {
        reject();
      } else {
        return res.json();
      }
    }).then((res) => {
      if (currentChatCopy?.chat?.id === chat.id) {
        currentChat.set(Object.assign(currentChatCopy, {
          messages: res || [],
        }));
      }
      resolve();
    }).catch(() => {
      reject();
    });
  });
};

export const sendMessage = (message:string, twilio:string, attachment?: { content: string, mimeType: string }, time?:number, chatId?:string, noteId?:string, projectId?:string, crop?: boolean):Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + 'message', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
      body: JSON.stringify({
        chat: chatId || currentChatCopy.chat.id,
        message,
        twilio,
        attachment: attachment && attachment.content,
        attachmentMimeType: attachment && attachment.mimeType,
        time,
        noteId: noteId,
        projectId: projectId,
        sendToFileVine: !!(!!noteId && !!projectId),
        crop
      }),
    }).then((res) => {
      if (res.status >= 300) {
        reject();
      } else {
        return res.json()
      }
    }).then((res) => {

      if (chatId) {
        getChat(chatId);
      }

      if (currentChatCopy && currentChatCopy.messages) {
        currentChatCopy.messages.push({
          id: res.id || '',
          attachment: res.attachment || '',
          author: res.author || '',
          authorLabel: res.authorLabel || '',
          text: res.text || '',
          date: res.date || 0,
          source: res.source,
          attachmentMimeType: res.attachmentMimeType || '',
          noteId,
          projectId
        });
  
        currentChat.set(currentChatCopy);
        resolve();
      } else {
        resolve();
      }
    }).catch(() => {
      reject();
    });
  });
};

export const sendTypingEvent = (text?:string):Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + 'chat/' + currentChatCopy.chat.id + '/typingEvent', {
      method: 'POST',
      body: JSON.stringify({
        users: team.map(member => member.id).filter(member => member != user.id),
        text: text || ''
      }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
    }).then((res) => {
      resolve();
    }).catch(() => {
      reject();
    });
  });
};

export const editMessage = (messageId:string, date?:number):Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + 'message', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
      body: JSON.stringify({
        chatId: currentChatCopy.chat.id,
        messageId,
        date,
      }),
    }).then((res) => {
      if (res.status >= 300) {
        reject();
      } else {
        return res.json()
      }
    }).then((res) => {
      getMessages(currentChatCopy.chat).finally(() => {
        resolve();
      });
    }).catch(() => {
      reject();
    });
  });
};

export const sendFileToMerusCase = (fileLink:string, fileMimeType:string, caseFileId:string):Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + 'sendMerusCaseFile', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
      body: JSON.stringify({
        fileLink,
        caseFileId,
        fileMimeType,
      }),
    }).then((res) => {
      resolve();
    }).catch(() => {
      reject();
    });
  });
};

export const sendChatToMerusCase = (chatId:string, caseFileId:string):Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + `chat/${chatId}/merusCase/${caseFileId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
    }).then((res) => {
      resolve();
    }).catch(() => {
      reject();
    });
  });
};

export const sendFileToFileVine = (fileLink:string, fileMimeType:string, projectId:string):Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + 'sendFileVineFile', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
      body: JSON.stringify({
        fileLink,
        projectId,
        fileMimeType,
      }),
    }).then((res) => {
      resolve();
    }).catch(() => {
      reject();
    });
  });
};

export const sendChatToFileVine = (chatId:string, projectId:string):Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + `chat/${chatId}/fileVine/${projectId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
    }).then((res) => {
      resolve();
    }).catch(() => {
      reject();
    });
  });
};

export const sendFileToGoogleDrive = (fileLink:string, fileMimeType:string):Promise<string> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + 'sendGoogleDriveFile', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
      body: JSON.stringify({
        fileLink,
        fileMimeType,
      }),
    }).then((res) => {
      if (res.status >= 300) {
        reject();
      } else {
        return res.json()
      }
    }).then((res) => {
      resolve(res ? res : 'Error');
    }).catch(() => {
      reject();
    });
  });
};

export const sendFileToOneDrive = (fileLink:string, fileMimeType:string):Promise<string> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + 'sendOneDriveFile', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
      body: JSON.stringify({
        fileLink,
        fileMimeType,
      }),
    }).then((res) => {
      if (res.status >= 300) {
        reject();
      } else {
        return res.json()
      }
    }).then((res) => {
      resolve(res ? res : 'Error');
    }).catch(() => {
      reject();
    });
  });
};

export const sendBase64FileToGoogleDrive = (file:string, fileMimeType:string):Promise<string> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + 'sendGoogleDriveBase64File', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
      body: JSON.stringify({
        file,
        fileMimeType,
      }),
    }).then((res) => {
      if (res.status >= 300) {
        reject();
      } else {
        return res.json()
      }
    }).then((res) => {
      resolve(res ? res : 'Error');
    }).catch(() => {
      reject();
    });
  });
};

export const sendBase64FileToOneDrive = (file:string, fileMimeType:string):Promise<string> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + 'sendOneDriveBase64File', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
      body: JSON.stringify({
        file,
        fileMimeType,
      }),
    }).then((res) => {
      if (res.status >= 300) {
        reject();
      } else {
        return res.json()
      }
    }).then((res) => {
      resolve(res ? res : 'Error');
    }).catch(() => {
      reject();
    });
  });
};

export const getFileFiveProjects = (email:string, phone:string):Promise<IResProject[]> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + 'getFileFiveProjects?email=' + email + '&phone=' + phone, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
    }).then((res) => {
      if (res.status >= 300) {
        reject();
      } else {
        return res.json();
      }
    }).then((res) => {
      resolve(res);
    }).catch(() => {
      reject();
    });
  });
};

export const getMerusCaseCaseFile = (caseId: string):Promise<any> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + 'getMerusCaseCaseFile/' + caseId, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
    }).then((res) => {
      if (res.status >= 300) {
        reject();
      } else {
        return res.json();
      }
    }).then((res) => {
      resolve(res);
    }).catch(() => {
      reject();
    });
  });
};

export const getFileVineProject = (projectId: string):Promise<any> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + 'getFileVineProject/' + projectId, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
    }).then((res) => {
      if (res.status >= 300) {
        reject();
      } else {
        return res.json();
      }
    }).then((res) => {
      resolve(res);
    }).catch(() => {
      reject();
    });
  });
};

export const sendVideoChatLink = ():Promise<void> => {
  const chatId = currentChatCopy.chat.id

  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + 'message', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
      body: JSON.stringify({
        chat: chatId,
        // App user we receive this videochat URL formatted to join as normal user
        message: `https://qix.cloud/videochat/?conversation=${chatId}&identity=admin`,
      }),
    }).then((res) => {
      if (res.status >= 300) {
        reject();
      } else {
        return res.json()
      }
    }).then((res) => {
      currentChatCopy.messages.push({
        id: res.id || '',
        attachment: res.attachment || '',
        author: res.author || '',
        authorLabel: res.authorLabel || '',
        text: res.text || '',
        date: res.date || 0,
        source: res.source,
        attachmentMimeType: res.attachmentMimeType || ''
      });

      currentChat.set(currentChatCopy);
      resolve();
    }).catch(() => {
      reject();
    });
  });

};

export const twilioSettings = writable<ITwilioSettings>({
  sid: '',
  authToken: '',
  serviceId: '',
  phone: '',
});

export const teamTwilioSettings = writable<ITwilioSettings[]>([]);

export const getTwilioSettings = ():Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + 'twilio', {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
    }).then((res) => {
      if (res.status >= 300) {
        reject();
      } else {
        return res.json();
      }
    }).then((res) => {
      twilioSettings.set({
        sid: res.sid,
        serviceId: res.serviceId,
        authToken: res.authToken,
        phone: res.phone,
      });
      resolve();
    }).catch(() => {
      reject();
    });
  });
};

export const getTeamTwilioSettings = ():Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + 'teamTwilio', {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
    }).then((res) => {
      if (res.status >= 300) {
        reject();
      } else {
        return res.json();
      }
    }).then((res) => {
      teamTwilioSettings.set(res);
      resolve();
    }).catch(() => {
      reject();
    });
  });
};

export const setTwilioSettings = (settings:ITwilioSettings):Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + 'twilio', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
      body: JSON.stringify({
        sid: settings.sid || '-',
        serviceId: settings.serviceId || '-',
        phone: settings.phone.replace(' ', '') || '-',
        authToken: settings.authToken  || '-',
      }),
    }).then((res) => {
      if (res.status >= 300) {
        reject();
      } else {
        Promise.all([
          getTeamTwilioSettings(),
          getTwilioSettings()]).finally(() => {
          resolve();
        });
      }
    }).catch(() => {
      reject();
    });
  });
};

export const chatsSettings = writable<IChatsSettings>({
  chatList: {}
});

export const getChatsSettings = ():Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + 'chatListSettings', {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
    }).then((res) => {
      if (res.status >= 300) {
        reject();
      } else {
        return res.json();
      }
    }).then((res) => {


      if (res?.assigned) {
        delete res.assigned;
      }

      chatsSettings.set({
        chatList: res
      });
      resolve();
    }).catch((err) => {
      console.error(err);
      reject();
    });
  });
};

export const saveChatsSettings = (settings:IChatsSettings):Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + 'chatListSettings', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
      body: JSON.stringify({
        settings: settings.chatList
      }),
    }).then((res) => {
      if (res.status >= 300) {
        reject();
      } else {
        return 'success';
      }
    }).then(() => {
      getChatsSettings().finally(() => {
        resolve();
      });
    }).catch((err) => {
      console.error(err);
      reject();
    });
  });
};

export const tags = writable<ITag[]>([]);
let tagsLoaded:number = 0;
export const getTags = (force?: boolean):Promise<void> => {
  return new Promise((resolve, reject) => {

    if ((Date.now() - tagsLoaded > 600_000) || force) {
      fetch(VITE_API_LINK + 'tags', {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
      }).then((res) => {
        if (res.status >= 300) {
          reject();
        } else {
          return res.json();
        }
      }).then((res) => {
        tags.set(res);
        tagsLoaded = Date.now();
        resolve();
      }).catch((err) => {
        console.error(err);
        reject();
      });
    } else {
      resolve();
    }
  });
};

export const deleteTag = (tagId: string):Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + 'tags/' + tagId, {
      method: 'DELETE',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
    }).then((res) => {
      if (res.status >= 300) {
        reject();
      } else {
        return res.json();
      }
    }).then(() => {
      getTags(true)
        .then(() => {
          resolve();
        })
        .catch(err => {
          console.error(err);
          reject();
        })
    }).catch((err) => {
      console.error(err);
      reject();
    });
  });
};

export const transalteText = (from:string | undefined, to:string, text:string):Promise<{text: string, detectedSourceLanguage:string}> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + 'translate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        from, to, text
      }),
    }).then((res) => {
      if (res.status >= 300) {
        reject();
      } else {
        return res.json()
      }
    }).then((res) => {
      resolve(res);
    }).catch(() => {
      reject();
    });
  });
};

export const sendTestNotificatiion = (settings:INotifySettings, chat:IChat, template:string, eventType:string ):Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + 'sendTestNotification', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
      body: JSON.stringify({
        settings,
        template,
        eventType,
        fullName: chat.fullName || '',
        chatId: chat.id,
        email: chat.email || ''
      }),
    }).then((res) => {
      if (res.status >= 300) {
        reject();
      } else {
        return res.json()
      }
    }).then((res) => {
      resolve();
    }).catch(() => {
      reject();
    });
  });
};

export const getAIMessage = (chat:IFullChat):Promise<string> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + 'generateAIMessage', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
      body: JSON.stringify(chat),
    })
    .then((res) => {
      if (res.status >= 300) {
        reject();
      } else {
        return res.json()
      }
    })
    .then((res) => {
      console.log(res)
      resolve(res);
    })
    .catch((err) => {
      reject(err)
    });
  });
};

export const getCaseFileName = (id: string):Promise<string> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + `caseFiles/${id}/name`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .then((res) => {
      if (res.status >= 300) {
        reject();
      } else {
        return res.json()
      }
    })
    .then((res) => {
      resolve(res);
    })
    .catch((err) => {
      reject(err)
    });
  });
};

export const getFileVineProjectName = (id: string):Promise<string> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + `fileVineProjects/${id}/name`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .then((res) => {
      if (res.status >= 300) {
        reject();
      } else {
        return res.json()
      }
    })
    .then((res) => {
      resolve(res);
    })
    .catch((err) => {
      reject(err)
    });
  });
};

export const textToPdf = (text: string):Promise<string> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + `textToPdf`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
      body: JSON.stringify({
        text,
      }),
    })
    .then((res) => {
      if (res.status >= 300) {
        reject();
      } else {
        return res.json()
      }
    })
    .then((res) => {
      resolve(res);
    })
    .catch((err) => {
      reject(err)
    });
  });
};


let predictionary = Predictionary.instance();
let predictionsFetched:boolean = false;
let predictionsFetching:boolean = false;

export const getPredictiveText = (payload:string):string[] => {

  if (predictionsFetched) {
    return predictionary.predict(payload, { maxPredictions: 3 });
  } else {
    if (!predictionsFetching) {
      predictionsFetching = true;
      fetch('/words_dictionary.json', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then((res) => {
        if (res.status < 300) {
          return res.json()
        }
      })
      .then((res) => {
        predictionary.addWords(res);
        predictionsFetched = true;
      })
      .catch(() => {
        predictionsFetched = false;
      })
      .finally(() => {
        predictionsFetching = false;
      });
    }
    return [];
  }

};