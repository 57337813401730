<script lang="ts">
  import DefaultLoader from '../other/DefaultLoader.svelte';

  let clazz:string = '';
  export { clazz as class };
  export let text:string = 'Save';
  export let type:'submit' | 'button' | 'reset' = 'button';
  export let disabled:boolean = false;
  export let loading:boolean = false;
  export let negative:boolean = false;
  export let title:string = '';
  export let icon:string = '';
  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();

  const click = ():void => {
    if (!disabled && !loading) {
      dispatch('click');
    }
  };

  const pointerdown = ():void => {
    if (!disabled && !loading) {
      dispatch('pointerdown');
    }
  };
</script>

<button title={title} class="btn {clazz} {disabled ? 'btn--disabled' : ''} {negative ? 'btn--negative' : ''} {loading ? 'btn--loading' : ''}" type="{type}" on:click={click} on:pointerdown={pointerdown}>
  {#if loading}
    <DefaultLoader class="btn__loader" />
  {/if}
  {#if icon}
    <img class="btn__img" src="{icon}" alt="">
  {/if}
  <span class="btn__text" style="opacity: {loading ? '0' : '1'}">{text}</span>
</button>

<style lang="scss" scoped>

@import '../../style/variables.scss'; // Adjust the path as needed


  :global(.btn__loader) {
    width: 20px;
    position: absolute;

    @media (max-width: $laptop-width) {
      width: 20px * $laptop-width-mp;
    }
  } 

  .btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #009AE4;
    color: #fff;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 15px;
    cursor: pointer;
    user-select: none;
    position: relative;
    transition: background-color 0.25s;

    @media (max-width: $laptop-width) {
      border-radius: 5px * $laptop-width-mp;
      font-size: 16px * $laptop-width-mp;
      font-weight: 500 * $laptop-width-mp;
      padding: 10px * $laptop-width-mp 15px * $laptop-width-mp;
    }

    @media(max-width: $phone-width) {
      font-size: 14px;
      padding: 6px 11px;
    }

    &:hover {
      background-color: #057bb1;
    }

    &:active {
      background-color: #009AE4;
    }

    &--negative {
      background-color: rgb(237, 75, 75);

      &:hover {
        background-color: rgb(194, 66, 66);
      }

      &:active {
        background-color: rgb(152, 52, 52);
      }
    }

    &--disabled {
      background-color: #a3a3a3;
      pointer-events: none;
    }

    &--loading {
      pointer-events: none;
      background-color: #c4c4c4;
    }

    &__img {
      width: 35px;
      height: auto;
      margin-right: 0.5em;

      @media (max-width: $laptop-width) {
        width: 35px * $laptop-width-mp;
      }

      @media(max-width: $phone-width) {
        width: 20px;
      }
    }
  }
</style>