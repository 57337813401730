<svelte:options immutable={true} />

<script>
  import { onMount, createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  export let templates;
  export let blog;
  export let user;
  let templateId = 0;
  let showRenameTemp = false;
  let newTempName = "";
  let merusUserId = "";
  let searchKeytemplate = "";
  let showTaskTemp = false;
  let showActivityTemp = false;
  let activity_type_id = "";
  function loadTemplate(template) {
    dispatch("select", { template });
  }
  async function deleteTemplate(id) {
    if (confirm("Are you sure you want to delete the Template?") == true) {
      // signatureTabLoading = true;
      let body = {
        id: id,
        auth: "google",
        user_id: user.id,
      };
      const req = await fetch(
        blog.siteurl +
          "/wp-admin/admin-ajax.php?action=deleteSignatureTemplateForCloudflare",
        {
          method: "POST",
          body: JSON.stringify(body),
        }
      );
      let data = await req.json();
      templates = data.templates;
      // signatureTabLoading = false;
    }
  }
  async function showRenameTempPanel(template) {
    templateId = template.id;
    newTempName = template.pdfName;
    showRenameTemp = true;
    showTaskTemp = false;
    showActivityTemp = false;
  }
  async function hideRenameTempPanel() {
    templateId = 0;
    newTempName = "";
    showRenameTemp = false;
  }
  async function showTaskTempPanel(template) {
    templateId = template.id;
    merusUserId = template.merusUserId;
    showRenameTemp = false;
    showActivityTemp = false;
    showTaskTemp = true;
  }
  async function showActivityTempPanel(template) {
    templateId = template.id;
    activity_type_id = template.activity_type_id;
    showRenameTemp = false;
    showTaskTemp = false;
    showActivityTemp = true;
  }
  async function hideActivityTempPanel() {
    templateId = 0;
    activity_type_id = "";
    showActivityTemp = false;
  }
  async function hideTaskTempPanel() {
    templateId = 0;
    merusUserId = "";
    showTaskTemp = false;
  }
  async function update_activity_type_id() {
    if (templateId > 0 && activity_type_id != "") {
      showRenameTemp = false;
      // signatureTabLoading = true;
      let body = {
        id: templateId,
        activity_type_id: activity_type_id,
        auth: "google",
        user_id: user.id,
      };
      const req = await fetch(
        blog.siteurl +
          "/wp-admin/admin-ajax.php?action=updateTemplateMerusActivityTypeIdForCloudflare",
        {
          method: "POST",
          body: JSON.stringify(body),
        }
      );
      let data = await req.json();
      merusUserId = "";
      templateId = 0;
      templates = data.templates;
      showActivityTemp = false;
    }
  }
  async function updateMerusUserId() {
    if (templateId > 0 && merusUserId != "") {
      showRenameTemp = false;
      // signatureTabLoading = true;
      let body = {
        id: templateId,
        merusUserId: merusUserId,
        auth: "google",
        user_id: user.id,
      };
      const req = await fetch(
        blog.siteurl +
          "/wp-admin/admin-ajax.php?action=updateTemplateMerusUserIdForCloudflare",
        {
          method: "POST",
          body: JSON.stringify(body),
        }
      );
      let data = await req.json();
      merusUserId = "";
      templateId = 0;
      templates = data.templates;
      showTaskTemp = false;
    }
  }
  async function renameTemplateName() {
    if (templateId > 0 && newTempName != "") {
      showRenameTemp = false;
      // signatureTabLoading = true;
      let body = {
        id: templateId,
        name: newTempName,
        auth: "google",
        user_id: user.id,
      };
      const req = await fetch(
        blog.siteurl +
          "/wp-admin/admin-ajax.php?action=updateTemplateNameForCloudflare",
        {
          method: "POST",
          body: JSON.stringify(body),
        }
      );
      let data = await req.json();
      newTempName = "";
      templateId = 0;
      templates = data.templates;
      showRenameTemp = false;
    }
  }
</script>

<div class="template-wrapper">
  <div style="margin: 10px">
    <input
      placeholder="Search Template"
      type="text"
      class="flex-grow bg-transparent py-1 px-2 w-full"
      style="border: 1px solid;border-radius: 5px;margin-top: 1em;"
      bind:value={searchKeytemplate}
    />
  </div>
  {#if templates.length > 0}
    <div class:hidden={showRenameTemp || showTaskTemp || showActivityTemp}>
      {#each templates as template}
        {#if searchKeytemplate == "" || template.pdfName
            .toLowerCase()
            .includes(searchKeytemplate.toLowerCase())}
          <div class="signItem">
            <div>
              <div>{template.pdfName}</div>
            </div>
            <div class="action w-full flex items-center">
              <div class="flex items-center">
                <span class="load" on:click={() => loadTemplate(template)}
                  >Load</span
                >
                {#if template.author_id == user.id}
                  <span
                    class="rename"
                    on:click={() => showRenameTempPanel(template)}>Rename</span
                  >
                  <span
                    class="task"
                    on:click={() => showTaskTempPanel(template)}>Task</span
                  >
                  <span
                    class="activity"
                    on:click={() => showActivityTempPanel(template)}
                    >Activity</span
                  >
                  <span
                    class="delete"
                    on:click={() => deleteTemplate(template.id)}>Delete</span
                  >
                {/if}
              </div>
            </div>
          </div>
        {/if}
      {/each}
    </div>
    {#if showRenameTemp}
      <div style="padding: 10px;">
        <span
          on:click={hideRenameTempPanel}
          class="cursor-pointer"
          style="float: right;font-size: 1.5rem;margin-top: -1rem;">×</span
        >
        <input
          type="text"
          class="flex-grow bg-transparent py-1 px-2 w-full"
          style="border: 1px solid;border-radius: 5px;"
          bind:value={newTempName}
        />
        <button
          on:click={renameTemplateName}
          class="w-30 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded"
          style="float: right; margin-top: 10px; margin-bottom: 5px;"
        >
          Update
        </button>
      </div>
    {/if}
    {#if showTaskTemp}
      <div style="padding: 10px;">
        <span
          on:click={hideTaskTempPanel}
          class="cursor-pointer"
          style="float: right;font-size: 1.5rem;margin-top: -1rem;">×</span
        >
        <input
          type="text"
          class="flex-grow bg-transparent py-1 px-2 w-full"
          style="border: 1px solid;border-radius: 5px;"
          placeholder="Meruscase User ID"
          bind:value={merusUserId}
        />
        <button
          on:click={updateMerusUserId}
          class="w-30 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded"
          style="float: right; margin-top: 10px; margin-bottom: 5px;"
        >
          Update
        </button>
      </div>
    {/if}
    {#if showActivityTemp}
      <div style="padding: 10px;">
        <span
          on:click={hideActivityTempPanel}
          class="cursor-pointer"
          style="float: right;font-size: 1.5rem;margin-top: -1rem;">×</span
        >
        <input
          type="text"
          class="flex-grow bg-transparent py-1 px-2 w-full"
          style="border: 1px solid;border-radius: 5px;"
          placeholder="Activity Type ID"
          bind:value={activity_type_id}
        />
        <button
          on:click={update_activity_type_id}
          class="w-30 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded"
          style="float: right; margin-top: 10px; margin-bottom: 5px;"
        >
          Update
        </button>
      </div>
    {/if}
  {:else}
    <div class="text-center">There is no data.</div>
  {/if}
</div>
