import { writable } from "svelte/store";
import {
  type IChat,
  type IUser,
  type IAutomationSettings,
  AutomationSetting,
  type IMonitoring,
  type IUserPaymentDetails,
  type IPhase,
} from "./../typesTs";
import { currentChat, getChat, getChats, setCurrentChat } from "./chats.store";
const VITE_API_LINK = "https://api.qix.cloud/";
const VITE_AUTH_M_REDIRECT_URI = "https://api.qix.cloud/microsoftRedirectSign";
const VITE_AUTH_REDIRECT_URI = "https://api.qix.cloud/googleRedirectSign";
//const VITE_BASE_LINK = "https://qixcloud-chat.pages.dev";
const VITE_ORIGIN_LINK = "https://legalpal.app/sign";
export const user = writable<IUser | null>(null);

let userCopy: IUser;
user.subscribe((value) => {
  userCopy = value;
});

export const settings = writable<IAutomationSettings>({
  gcalendar: {},
  outlookCalendar: {},
  merusCase: {},
  fileVine: {},
  fluentCase: {},
  notify: {},
  twilio: {},
});

export const templateResponses = writable<{ text: string; id: string }[]>([]);

export const auth = (): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    const urlParams: URLSearchParams = new URLSearchParams(
      window.location.search
    );
    let code: string = urlParams.get("token");
    let invitation: string;
    let chatId: string;
    let rtnk: string = urlParams.get("rtnk");

    if (code && code.includes("qix_invitaion_")) {
      invitation = code.replace("qix_invitaion_", "");
      sessionStorage.setItem("invitation", invitation);
      code = undefined;
    }

    if (rtnk) {
      localStorage.setItem("token", rtnk);
    }

    if (sessionStorage.getItem("invitation") && localStorage.getItem("token")) {
      invitation = sessionStorage.getItem("invitation");
      sessionStorage.removeItem("invitation");
      code = undefined;
    }

    if (code && code.includes("qix_shared_chat_")) {
      chatId = code.replace("qix_shared_chat_", "");
      code = undefined;
    }

    if (invitation && localStorage.getItem("token")) {
      addTeamMember(invitation).finally(() => {
        window.parent.location.href = VITE_ORIGIN_LINK;
      });
    } else if (code && code.length) {
      if (code.includes("microsoft_code_")) {
        code = code.replace("microsoft_code_", "");
        getTokenWithMicrosoftCode(code)
          .then((token) => {
            localStorage.setItem("token", token);
            window.parent.location.href =
              VITE_ORIGIN_LINK + "?rtnk=" + token;
            resolve();
          })
          .catch((err) => {
            console.error(err);
            window.parent.location.href = VITE_ORIGIN_LINK;
            reject();
          });
      } else {
        getTokenWithGoogleCode(code)
          .then((token) => {
            localStorage.setItem("token", token);
            window.parent.location.href =
              VITE_ORIGIN_LINK + "?rtnk=" + token;
            resolve();
          })
          .catch((err) => {
            console.error(err);
            window.parent.location.href = VITE_ORIGIN_LINK;
            reject();
          });
      }
    } else if (localStorage.getItem("token")) {
      getUserInfo(localStorage.getItem("token"))
        .then((info) => {
          user.set(info);
          getSettings();

          if (chatId) {
            getChats();
            getChat(chatId)
              .then((res: IChat) => {
                if (res) {
                  if (res.members[info.id]) {
                    setCurrentChat(res);
                  }
                }

                resolve();
              })
              .catch(() => {
                resolve();
              });
          } else {
            resolve();
          }
        })
        .catch((err) => {
          localStorage.removeItem("token");
          window.parent.location.href = VITE_ORIGIN_LINK;
          console.error(err);
          reject();
        });
    } else {
      resolve();
    }
  });
};

export const logout = (): void => {
  user.set(undefined);
  localStorage.removeItem("token");
};

export const signInWithGoogle = (): void => {
  const rootUrl: string = "https://accounts.google.com/o/oauth2/v2/auth";
  const options = {
    redirect_uri: VITE_AUTH_REDIRECT_URI,
    client_id:
      "567227164069-n724qdj6qo5ri7u2dt922j8o6f536027.apps.googleusercontent.com",
    access_type: "offline",
    response_type: "code",
    prompt: "consent",
    scope: [
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/userinfo.profile",
      "https://www.googleapis.com/auth/calendar.app.created",
      "https://www.googleapis.com/auth/drive.file",
      "https://www.googleapis.com/auth/gmail.send",
    ].join(" "),
  };

  const url: string = `${rootUrl}?${new URLSearchParams(options).toString()}`;

  window.parent.location.href = url;
};

export const signInWithMicrosoft = (): void => {
  const rootUrl: string =
    "https://login.microsoftonline.com/common/oauth2/v2.0/authorize";
  const options = {
    redirect_uri: VITE_AUTH_M_REDIRECT_URI,
    client_id: "0c8ee278-e0c4-4f30-80d5-57f89fa76376",
    response_type: "code",
  };

  const url: string = `${rootUrl}?${
    new URLSearchParams(options).toString() +
    "&scope=profile+openid+email+offline_access+User.Read+Calendars.ReadWrite+Files.ReadWrite+Mail.Send+Mail.Send.Shared"
  }`;

  window.parent.location.href = url;
};

const getTokenWithGoogleCode = (code: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + "sign/google", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        code,
        redirectUri: VITE_AUTH_REDIRECT_URI,
      }),
    })
      .then((res) => {
        if (res.status >= 300) {
          reject();
        } else {
          return res.json();
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch(() => {
        reject();
      });
  });
};

const getTokenWithMicrosoftCode = (code: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + "sign/microsoft", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        code,
        redirect_uri: VITE_AUTH_M_REDIRECT_URI,
      }),
    })
      .then((res) => {
        if (res.status >= 300) {
          reject();
        } else {
          return res.json();
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch(() => {
        reject();
      });
  });
};

const getUserInfo = (token: string): Promise<IUser> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + "auth", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        if (res.status >= 300) {
          reject();
        } else {
          return res.json();
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch(() => {
        reject();
      });
  });
};

export const addTeamMember = (id: string): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    fetch(VITE_API_LINK + "team", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify({
        id,
      }),
    })
      .then((res) => {
        if (res.status >= 300) {
          reject();
        } else {
          return res.json();
        }
      })
      .then((res) => {
        resolve();
      })
      .catch(() => {
        reject();
      });
  });
};

export const getSettings = (team?: boolean): Promise<void> => {
  return new Promise((resolve, reject) => {
    Promise.all([
      fetch(
        VITE_API_LINK +
          "settings/" +
          AutomationSetting.Gcalendar +
          "?userId=" +
          userCopy.appId,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          if (res.status >= 300) {
            reject();
          } else {
            return res.json();
          }
        })
        .then((res) => {
          settings.update((settings) => ({
            gcalendar: res,
            merusCase: settings.merusCase,
            fileVine: settings.fileVine,
            fluentCase: settings.fluentCase,
            notify: settings.notify,
            outlookCalendar: settings.outlookCalendar,
            twilio: settings.twilio,
          }));
          resolve();
        })
        .catch(() => {
          reject();
        }),
      fetch(
        VITE_API_LINK +
          "settings/" +
          AutomationSetting.OutlookCalendar +
          "?userId=" +
          userCopy.appId,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          if (res.status >= 300) {
            reject();
          } else {
            return res.json();
          }
        })
        .then((res) => {
          settings.update((settings) => ({
            gcalendar: settings.gcalendar,
            merusCase: settings.merusCase,
            fileVine: settings.fileVine,
            fluentCase: settings.fluentCase,
            notify: settings.notify,
            outlookCalendar: res,
            twilio: settings.twilio,
          }));
          resolve();
        })
        .catch(() => {
          reject();
        }),
      fetch(
        VITE_API_LINK +
          "settings/" +
          AutomationSetting.MerusCase +
          "?userId=" +
          userCopy.appId,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          if (res.status >= 300) {
            reject();
          } else {
            return res.json();
          }
        })
        .then((res) => {
          settings.update((settings) => ({
            gcalendar: settings.gcalendar,
            merusCase: res,
            fileVine: settings.fileVine,
            fluentCase: settings.fluentCase,
            notify: settings.notify,
            outlookCalendar: settings.outlookCalendar,
            twilio: settings.twilio,
          }));
          resolve();
        })
        .catch(() => {
          reject();
        }),
      fetch(
        VITE_API_LINK +
          "settings/" +
          AutomationSetting.FileVine +
          "?userId=" +
          userCopy.appId,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          if (res.status >= 300) {
            reject();
          } else {
            return res.json();
          }
        })
        .then((res) => {
          settings.update((settings) => ({
            gcalendar: settings.gcalendar,
            merusCase: settings.merusCase,
            fileVine: res,
            fluentCase: settings.fluentCase,
            notify: settings.notify,
            outlookCalendar: settings.outlookCalendar,
            twilio: settings.twilio,
          }));
          resolve();
        })
        .catch(() => {
          reject();
        }),
      fetch(
        VITE_API_LINK +
          "settings/" +
          AutomationSetting.Notyfy +
          "?userId=" +
          userCopy.appId,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          if (res.status >= 300) {
            reject();
          } else {
            return res.json();
          }
        })
        .then((res) => {
          settings.update((settings) => ({
            gcalendar: settings.gcalendar,
            merusCase: settings.merusCase,
            fileVine: settings.fileVine,
            fluentCase: settings.fluentCase,
            notify: res,
            outlookCalendar: settings.outlookCalendar,
            twilio: settings.twilio,
          }));
          resolve();
        })
        .catch(() => {
          reject();
        }),
      fetch(
        VITE_API_LINK +
          "settings/" +
          AutomationSetting.Twilio +
          "?userId=" +
          userCopy.id,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          if (res.status >= 300) {
            reject();
          } else {
            return res.json();
          }
        })
        .then((res) => {
          settings.update((settings) => ({
            gcalendar: settings.gcalendar,
            merusCase: settings.merusCase,
            fileVine: settings.fileVine,
            fluentCase: settings.fluentCase,
            notify: settings.notify,
            outlookCalendar: settings.outlookCalendar,
            twilio: res,
          }));
          resolve();
        })
        .catch(() => {
          reject();
        }),
    ])
      .then(() => {
        resolve();
      })
      .catch((err) => {
        console.error(err);
      });
  });
};

export const setSettings = <SetingsType>(
  settingsType: AutomationSetting,
  mewSettings: SetingsType,
  team?: boolean
): Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + "settings/" + settingsType, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify({
        settings: mewSettings || {},
        userId:
          settingsType === AutomationSetting.Twilio
            ? userCopy.id
            : userCopy.appId,
      }),
    })
      .then((res) => {
        if (res.status >= 300) {
          reject();
        } else {
          return res.json();
        }
      })
      .then((res) => {
        getSettings().finally(() => {
          resolve();
        });
      })
      .catch(() => {
        reject();
      });
  });
};

export const syncGoogleCalendar = (): Promise<[number, number, number]> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + "syncGoogleCalendar", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.status >= 300) {
          reject();
        } else {
          return res.json();
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch(() => {
        reject();
      });
  });
};

export const createGoogleCalendar = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + "createGoogleCalendar", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.status >= 300) {
          reject();
        } else {
          return res.json();
        }
      })
      .then((res) => {
        getSettings().finally(() => {
          resolve();
        });
      })
      .catch(() => {
        reject();
      });
  });
};

export const syncOutlookCalendar = (): Promise<[number, number, number]> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + "syncOutlookCalendar", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.status >= 300) {
          reject();
        } else {
          return res.json();
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch(() => {
        reject();
      });
  });
};

export const syncMerusCase = (): Promise<[number, number, number]> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + "syncMerusCase", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.status >= 300) {
          reject();
        } else {
          return res.json();
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch(() => {
        reject();
      });
  });
};

export const syncFileVineContacts = (): Promise<number> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + "syncFileVineContacts", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.status >= 300) {
          reject();
        } else {
          return res.json();
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch(() => {
        reject();
      });
  });
};

export const syncFileVineEvents = (projectId: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + "syncFileVineEvents", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        projectId,
      }),
    })
      .then((res) => {
        if (res.status >= 300) {
          reject();
        } else {
          return res.json();
        }
      })
      .then((res) => {
        resolve();
      })
      .catch(() => {
        reject();
      });
  });
};

export const getMonitoring = (
  type: "fileVine" | "merusCase" | "gCalendar" | "notify" | "outlookCalendar"
): Promise<IMonitoring[]> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + "monitoring/" + type, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.status >= 300) {
          reject();
        } else {
          return res.json();
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch(() => {
        reject();
      });
  });
};

export const setMonitoring = (
  type: "fileVine" | "merusCase" | "gCalendar" | "notify",
  monitoring: IMonitoring,
  monitoringId?: string
): Promise<IMonitoring[]> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + "monitoring/" + type, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        monitoring,
        monitoringId,
      }),
    })
      .then((res) => {
        if (res.status >= 300) {
          reject();
        } else {
          return res.json();
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch(() => {
        reject();
      });
  });
};

export const getData = (
  type: "savedMessages" | "templateResponse" | "fileVinePhases" | "merusCasePhases"
): Promise<{ id: string; [key: string]: any }[]> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + "data/" + type, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.status >= 300) {
          reject();
        } else {
          return res.json();
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch(() => {
        reject();
      });
  });
};

export const setData = (
  type: "savedMessages" | "templateResponse"  | "fileVinePhases" | "merusCasePhases",
  data: { [key: string]: any },
  dataId?: string
): Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + "data/" + type, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data,
        dataId,
      }),
    })
      .then((res) => {
        if (res.status >= 300) {
          reject();
        } else {
          return res.json();
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch(() => {
        reject();
      });
  });
};

export const getFileVinePhasesFromFileVine = (): Promise<{phase: string, id: string, type: string}[]> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + "getFileVinePhases", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      }
    })
      .then((res) => {
        if (res.status >= 300) {
          reject();
        } else {
          return res.json();
        }
      })
      .then((res) => {
        resolve(res || []);
      })
      .catch(() => {
        reject();
      });
  });
};

export const getPaymentDetails = (): Promise<IUserPaymentDetails> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + "paymentDetails", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
    })
      .then((res) => {
        if (res.status >= 300) {
          reject();
        } else {
          return res.json();
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch(() => {
        reject();
      });
  });
};


export const merusCaseTypes = writable<{title: string}[]>([]);
export const getMerusCaseTypes = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch("https://qix.cloud/ajax/app_new.php?userId=" + userCopy.id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
    })
      .then((res) => {
        if (res.status >= 300) {
          reject();
        } else {
          return res.json();
        }
      })
      .then((res) => {
        merusCaseTypes.set(res?.status?.en || res?.status || []);
        resolve();
      })
      .catch(() => {
        reject();
      });
  });
};

export const fileVinePhases = writable<IPhase[]>([]);
export const getFileVinePhases = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    getData('fileVinePhases')
      .then((phases) => {
        fileVinePhases.set(phases[0]?.phases || []);
        resolve();
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      })
  });
};

export const merusCasePhases = writable<IPhase[]>([]);
export const getMerusCasePhases = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    getData('merusCasePhases')
      .then((phases) => {
        merusCasePhases.set(phases[0]?.phases || []);
        resolve();
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      })
  });
};

export const getMerusCaseTypesFromMerusCase = (): Promise<{data?: {[key: number]: string}}> => {
  return new Promise((resolve, reject) => {
    fetch(VITE_API_LINK + "getMerusCaseTypes", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      }
    })
      .then((res) => {
        if (res.status >= 300) {
          reject();
        } else {
          return res.json();
        }
      })
      .then((res) => {
        resolve(res || []);
      })
      .catch(() => {
        reject();
      });
  });
};


