<svelte:options immutable={true} />

<script>
  import { onMount, createEventDispatcher } from "svelte";
  import Selectbox from "./Selectbox.svelte";
  import {
    readAsArrayBuffer,
    readAsImage,
    readAsPDF,
    readAsDataURL,
  } from "./utils/asyncReader.js";
  import { save, getData } from "./utils/PDF.js";
  import CheckMark from "./CheckMark.svelte";
  import CopyClipBoard from "./CopyClipBoard.svelte";
  const dispatch = createEventDispatcher();
  export let templates;
  export let blog;
  export let user;
  export let chatUsers;
  export let merusToken;
  export let signColor;
  let templateId = 0;
  let newTempName = "";
  let sharepointVal = "";
  let merusUserId = "";
  let searchKeytemplate = "";
  let activity_type_id = "";
  let showtab = "";
  let checkIfEmail = false;
  let shareEmailPanel = false;
  let sending = false;
  let sendingSign = false;
  let sendPDFType = "";
  let selectedTemplate = "";
  let shareEmail = "";
  let shareEmailcc = "";
  let sendPDFRes = false;
  let clipboardTxt = "";
  let shareCaseFileId = "";
  let shareUserId = "";
  let shareUserName = "";
  function loadTemplate(template) {
    dispatch("select", { template });
  }
  async function deleteTemplate(id) {
    if (confirm("Are you sure you want to delete the Template?") == true) {
      // signatureTabLoading = true;
      let body = {
        id: id,
        auth: "google",
        user_id: user.id,
      };
      const req = await fetch(
        blog.siteurl +
          "/wp-admin/admin-ajax.php?action=deleteSignatureTemplateForCloudflare",
        {
          method: "POST",
          body: JSON.stringify(body),
        }
      );
      let data = await req.json();
      templates = data.templates;
      // signatureTabLoading = false;
    }
  }
  async function showTempPanel(template, type) {
    templateId = template.id;
    activity_type_id = template.activity_type_id;
    sharepointVal = template.sharepoint;
    showtab = type;
    selectedTemplate = template;
  }
  async function shareEmailPanelFunc() {
    shareEmailPanel = true;
  }
  async function hideTempPanel() {
    templateId = 0;
    merusUserId = "";
    showtab = "";
    shareEmailPanel = false;
  }
  async function update_activity_type_id() {
    if (templateId > 0 && activity_type_id != "") {
      let body = {
        id: templateId,
        activity_type_id: activity_type_id,
        auth: "google",
        //user_id: user.id,
        user_id: user.appId,
      };
      const req = await fetch(
        blog.siteurl +
          "/wp-admin/admin-ajax.php?action=updateTemplateMerusActivityTypeIdForCloudflare",
        {
          method: "POST",
          body: JSON.stringify(body),
        }
      );
      let data = await req.json();
      merusUserId = "";
      templateId = 0;
      //templates = data.templates;
      showtab = "";
    }
  }
  async function updateMerusUserId() {
    if (templateId > 0 && merusUserId != "") {
      let body = {
        id: templateId,
        merusUserId: merusUserId,
        auth: "google",
        //user_id: user.id,
        user_id: user.appId,
      };
      const req = await fetch(
        blog.siteurl +
          "/wp-admin/admin-ajax.php?action=updateTemplateMerusUserIdForCloudflare",
        {
          method: "POST",
          body: JSON.stringify(body),
        }
      );
      let data = await req.json();
      merusUserId = "";
      templateId = 0;
      //templates = data.templates;
      showtab = "";
    }
  }
  async function renameTemplateName() {
    if (templateId > 0 && newTempName != "") {
      let body = {
        id: templateId,
        name: newTempName,
        auth: "google",
        user_id: user.id,
      };
      const req = await fetch(
        blog.siteurl +
          "/wp-admin/admin-ajax.php?action=updateTemplateNameForCloudflare",
        {
          method: "POST",
          body: JSON.stringify(body),
        }
      );
      let data = await req.json();
      newTempName = "";
      templateId = 0;
      //templates = data.templates;
      showtab = "";
    }
  }
  async function sharepointTemplate() {
    if (templateId > 0 && sharepointVal != "") {
      let body = {
        id: templateId,
        sharepoint: sharepointVal,
        auth: "google",
        //user_id: user.id,
        user_id: user.appId,
      };
      const req = await fetch(
        blog.siteurl +
          "/wp-admin/admin-ajax.php?action=updateTemplateSharepoint",
        {
          method: "POST",
          body: JSON.stringify(body),
        }
      );
      let data = await req.json();
      merusUserId = "";
      templateId = 0;
      //templates = data.templates;
      showtab = "";
    }
  }

  function checkIfEmailFunc() {
    // Regular expression to check if string is email
    const regexExp =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;

    checkIfEmail = regexExp.test(shareEmail);
  }

  async function handleChatUserSelect(event) {
    shareEmail = event.detail.item.email;
    if (event.detail.item.id != "") {
      shareUserId = event.detail.item.id;
      shareUserName = event.detail.item.fullName;
      shareCaseFileId = event.detail.item.advanced.caseFileIds.toString();
    }
    checkIfEmailFunc();
    console.log("shareEmail", shareEmail);
  }
  async function handleChatUserEnterEmail(event) {
    shareEmail = event.detail.item.email;
    shareUserId = "";
    shareUserName = "";
    shareCaseFileId = "";
    checkIfEmailFunc();
    console.log("enterEmail", shareEmail);
  }
  async function getSignatureOptions(contact_id = 0) {
    let ajaxBody = { url: selectedTemplate.pdfUrl, auth: "google" };
    const res = await fetch(
      blog.siteurl + "/wp-admin/admin-ajax.php?action=loadTemplateCloudFlare",
      {
        method: "POST",
        body: JSON.stringify(ajaxBody),
      }
    );
    let pdfFile = await res.blob();
    const pdf = await readAsPDF(pdfFile);
    const allObjects = JSON.parse(selectedTemplate.allObjects);
    const numPages = pdf.numPages;
    const pagesScale = Array(numPages).fill(1);
    const pdfName = selectedTemplate.pdfName;
    const prePdfData = await getData(pdfFile, allObjects, pdfName, pagesScale);
    let pdfData = prePdfData[0];
    let optionData = prePdfData[1];
    let sign1 = "";
    let sign2 = "";
    let sign3 = "";
    let sign4 = "";
    let sign5 = "";
    let sign6 = "";
    for (let i = 0; i < optionData.length; i++) {
      const pageNum = optionData[i].pageIndex + 1;
      let pageWidth = optionData[i].pageWidth;
      let pageHeight = optionData[i].pageHeight;
      if (pageWidth == 792 && pageHeight == 612) {
        if (optionData[0].pageWidth == 612 && optionData[0].pageHeight == 792) {
          pageWidth = 612;
          pageHeight = 792;
        }
      }
      if (optionData[i].type == "text") {
        //console.log(optionData[i].pageWidth, optionData[i].pageHeight);
        let posX = optionData[i].x / pageWidth;
        let posY = (optionData[i].y + 15) / pageHeight;
        if (optionData[i].text == "Initial") {
          if (sign2 == "") {
            sign2 += pageNum + ":" + posX + ":" + posY;
          } else {
            sign2 += "," + pageNum + ":" + posX + ":" + posY;
          }
        } else if (optionData[i].text == "Date") {
          if (sign3 == "") {
            sign3 += pageNum + ":" + posX + ":" + posY;
          } else {
            sign3 += "," + pageNum + ":" + posX + ":" + posY;
          }
        } else if (optionData[i].text == "Name") {
          if (sign4 == "") {
            sign4 += pageNum + ":" + posX + ":" + posY;
          } else {
            sign4 += "," + pageNum + ":" + posX + ":" + posY;
          }
        } else {
          //console.log("optionData", i, optionData);
          if (sign5 == "") {
            sign5 +=
              pageNum + ":" + posX + ":" + posY + ":" + optionData[i].text;
          } else {
            sign5 +=
              "," +
              pageNum +
              ":" +
              posX +
              ":" +
              posY +
              ":" +
              optionData[i].text.replace(/,/g, "-*-").replace(/:/g, "_*_");
          }
        }
      } else {
        let posX = optionData[i].x / pageWidth;
        let posY = optionData[i].y / (pageHeight - 23);
        if (optionData[i].type == "quickSign") {
          let path = optionData[i].url.replace(blog.siteurl, "");
          if (sign6 == "") {
            sign6 += pageNum + ":" + posX + ":" + posY + ":" + path;
          } else {
            sign6 += "," + pageNum + ":" + posX + ":" + posY + ":" + path;
          }
        }
        if (optionData[i].type == "Signature") {
          if (sign1 == "") {
            sign1 += pageNum + ":" + posX + ":" + posY;
          } else {
            sign1 += "," + pageNum + ":" + posX + ":" + posY;
          }
        }
      }
    }
    let timestamp = 0;
    let option1 = sign1 + "--*--" + sign3;
    let option2 =
      sign2 +
      "--*--" +
      sign4 +
      "--*--" +
      sign5 +
      "--*--letter--*--" +
      timestamp +
      "--*--" +
      sign6;
    let display_name = "google_" + user.name;
    let body = {
      author_id: user.id,
      author: display_name,
      contact_id: contact_id,
      pdfData: pdfData,
      pdfName: pdfName,
      option1: option1,
      option2: option2,
      sendPDFType: sendPDFType,
      allObjects: allObjects,
    };
    return body;
  }
  async function sendSignature(
    contact_id = 0,
    cblog = "",
    tw_b_id = 0,
    phone = "",
    email = "",
    cc = ""
  ) {
    if (sending) return;
    sending = true;
    try {
      clipboardTxt = "";
      sendingSign = true;
      // usersPanel = true;
      let body = await getSignatureOptions(contact_id);
      body.newName = "";
      body.lang = "en";
      body.color = signColor;
      if (cblog != "") body.blog = cblog;
      body.tw_b_id = tw_b_id;
      body.phone = phone;
      body.email = email;
      body.user_id = shareUserId;
      body.user_name = shareUserName;
      body.user_caseFileId = shareCaseFileId;
      body.cc = cc;
      body.staff_id = user.id;
      body.staff_email = user.email;
      body.appId = user.appId;
      body.merusUserId = selectedTemplate.merusUserId;
      body.merusActivityTypeId = selectedTemplate.activity_type_id;
      body.sharepoint = selectedTemplate.sharepoint;

      const req = await fetch(
        blog.siteurl + "/wp-admin/admin-ajax.php?action=updateContactPost",
        {
          method: "POST",
          body: JSON.stringify(body),
        }
      );
      let data = await req.json();
      //alert('Sent successfully!.');

      shareEmail = "";
      shareUserId = "";
      shareUserName = "";
      shareCaseFileId = "";
      if (sendPDFType == "link") {
        clipboardTxt = blog.siteurl + "/s/" + data.id;
      }
      sendPDFRes = true;
      //await getUsersFunc();
    } catch (e) {
      console.log(e);
    } finally {
      sending = false;
      //selectedUser = "";
    }
    sendingSign = false;
    //smsLang = "";
  }
  async function sendSignatureHandle(type) {
    if (type == "link") {
      sendPDFType = "link";
      sendSignature(0);
    } else if (type == "email") {
      sendPDFType = "email";
      sendSignature(0, 1, 1, "", shareEmail, shareEmailcc);
    }
  }
  const copy = () => {
    const app = new CopyClipBoard({
      target: document.getElementById("clipboard"),
      props: { clipboardTxt },
    });

    app.$destroy();
    //clipboardTxt = "";
    //usersPanel = false;
    alert("Copied.");
  };
  async function showShareEmailPanel() {
    shareEmailPanel = true;
    sendPDFRes = false;
    sendPDFType = "email";
  }
</script>

<div class="template-wrapper">
  <div style="margin: 10px">
    <input
      placeholder="Search Template"
      type="text"
      class="flex-grow bg-transparent py-1 px-2 w-full"
      style="border: 1px solid;border-radius: 5px;margin-top: 1em;"
      bind:value={searchKeytemplate}
    />
  </div>
  {#if templates.length > 0}
    <!-- <div class:hidden={showRenameTemp || showTaskTemp || showActivityTemp}> -->
    <div class:hidden={showtab != ""}>
      {#each templates as template}
        {#if searchKeytemplate == "" || template.pdfName
            .toLowerCase()
            .includes(searchKeytemplate.toLowerCase())}
          <div class="signItem">
            <div>
              <div>{template.pdfName}</div>
            </div>
            <div class="action w-full flex items-center">
              <div class="flex items-center">
                <span class="load" on:click={() => loadTemplate(template)}
                  >Load</span
                >
                {#if user.microsoft || user.id == "WXGQglBzvBxotzUznSAi"}
                  <span
                    class="share"
                    on:click={() => showTempPanel(template, "sharepoint")}
                    >Sharepoint</span
                  >
                {/if}
                <span
                  class="share"
                  on:click={() => showTempPanel(template, "share")}>Share</span
                >
                <span
                  class="rename"
                  on:click={() => showTempPanel(template, "rename")}
                  >Rename</span
                >
                <span
                  class="task"
                  on:click={() => showTempPanel(template, "task")}>Task</span
                >
                <span
                  class="activity"
                  on:click={() => showTempPanel(template, "activity")}
                  >Activity</span
                >
                {#if template.author_id == user.id}
                  <span
                    class="delete"
                    on:click={() => deleteTemplate(template.id)}>Delete</span
                  >
                {/if}
              </div>
            </div>
          </div>
        {/if}
      {/each}
    </div>
    {#if showtab == "share"}
      <div style="padding: 10px;">
        <span
          on:click={hideTempPanel}
          class="cursor-pointer"
          style="float: right;font-size: 1.5rem;margin-top: -1rem;">×</span
        >
        <div class="p-2 flex" style="gap: 20px;">
          <label
            class="flex items-center h-full hover:bg-gray-500
          cursor-pointer px-2 py-1"
            on:click={() => sendSignatureHandle("link")}
          >
            <img
              alt=""
              src="link.svg"
              style="width: 30px; margin-right: 15px;"
            />
            <span>Link</span>
          </label>
          <label
            class="flex items-center h-full hover:bg-gray-500
          cursor-pointer px-2 py-1"
            on:click={() => showShareEmailPanel()}
          >
            <img
              alt=""
              src="mail.svg"
              style="width: 30px; margin-right: 15px;"
            />
            <span>Email</span>
          </label>
        </div>
        {#if shareEmailPanel && sendPDFType != "link" && !sendPDFRes}
          <div class="flex" style="min-height: 220px;">
            <Selectbox
              items={chatUsers}
              type="email"
              placeholder="Enter Email"
              {merusToken}
              appId={user.appId}
              on:select={handleChatUserSelect}
              on:enterEmail={handleChatUserEnterEmail}
            />
          </div>
          {#if checkIfEmail}
            <button
              class="text-white font-bold py-1 px-3 md:px-4 mr-3 md:mr-4 rounded bg-blue-500 hover:bg-blue-700"
              style="
              margin-top: 15px;
              display: flex;
              align-items: center;
              margin-left: auto;"
              on:click={() => sendSignatureHandle("email")}
            >
              <img
                alt=""
                src="mail_w.png"
                style="width: 25px; margin-right: 15px;"
              /> Send</button
            >
          {:else}
            <button
              class="text-white font-bold py-1 px-3 md:px-4 mr-3 md:mr-4 rounded"
              style="
              margin-top: 15px;
              display: flex;
              align-items: center;
              margin-left: auto;
              background: grey"
              ><img
                alt=""
                src="mail_w.png"
                style="width: 25px; margin-right: 15px;"
              /> Send</button
            >
          {/if}
        {/if}

        {#if sendingSign}
          <div
            class="flex items-center"
            style="justify-content: space-between; max-width: 200px;"
          >
            <span class:hidden={sendPDFType != "link"}>Generating Link</span>
            <img alt="" src="/loading.gif" style="max-width: 30px;" />
          </div>
        {:else if sendPDFRes}
          {#if sendPDFType != "link"}
            <div
              class="flex items-center"
              style="justify-content: space-between; max-width: 200px;"
            >
              <span>Sent Successfully</span><CheckMark />
            </div>
          {:else}
            <div id="clipboard" />
            <div
              class="flex items-center"
              style="justify-content: space-between;"
            >
              <span>{clipboardTxt}</span>
              <img
                src="filter.svg"
                class="cursor-pointer"
                style="width: 30px; margin-left: 15px;"
                on:click={copy}
                alt="Copy"
              />
            </div>
          {/if}
        {/if}
      </div>
    {/if}
    {#if showtab == "sharepoint"}
      <div style="padding: 10px;">
        <span
          on:click={hideTempPanel}
          class="cursor-pointer"
          style="float: right;font-size: 1.5rem;margin-top: -1rem;">×</span
        >
        <input
          type="text"
          class="flex-grow bg-transparent py-1 px-2 w-full"
          style="border: 1px solid;border-radius: 5px;"
          bind:value={sharepointVal}
        />
        <button
          on:click={sharepointTemplate}
          class="w-30 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded"
          style="float: right; margin-top: 10px; margin-bottom: 5px;"
        >
          Save
        </button>
      </div>
    {/if}
    {#if showtab == "rename"}
      <div style="padding: 10px;">
        <span
          on:click={hideTempPanel}
          class="cursor-pointer"
          style="float: right;font-size: 1.5rem;margin-top: -1rem;">×</span
        >
        <input
          type="text"
          class="flex-grow bg-transparent py-1 px-2 w-full"
          style="border: 1px solid;border-radius: 5px;"
          bind:value={newTempName}
        />
        <button
          on:click={renameTemplateName}
          class="w-30 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded"
          style="float: right; margin-top: 10px; margin-bottom: 5px;"
        >
          Update
        </button>
      </div>
    {/if}
    {#if showtab == "task"}
      <div style="padding: 10px;">
        <span
          on:click={hideTempPanel}
          class="cursor-pointer"
          style="float: right;font-size: 1.5rem;margin-top: -1rem;">×</span
        >
        <input
          type="text"
          class="flex-grow bg-transparent py-1 px-2 w-full"
          style="border: 1px solid;border-radius: 5px;"
          placeholder="Meruscase User ID"
          bind:value={merusUserId}
        />
        <button
          on:click={updateMerusUserId}
          class="w-30 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded"
          style="float: right; margin-top: 10px; margin-bottom: 5px;"
        >
          Update
        </button>
      </div>
    {/if}
    {#if showtab == "activity"}
      <div style="padding: 10px;">
        <span
          on:click={hideTempPanel}
          class="cursor-pointer"
          style="float: right;font-size: 1.5rem;margin-top: -1rem;">×</span
        >
        <input
          type="text"
          class="flex-grow bg-transparent py-1 px-2 w-full"
          style="border: 1px solid;border-radius: 5px;"
          placeholder="Activity Type ID"
          bind:value={activity_type_id}
        />
        <button
          on:click={update_activity_type_id}
          class="w-30 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded"
          style="float: right; margin-top: 10px; margin-bottom: 5px;"
        >
          Update
        </button>
      </div>
    {/if}
  {:else}
    <div class="text-center">There is no data.</div>
  {/if}
</div>
