
<script lang="ts">
  import { settings, setSettings, syncFileVineContacts, syncFileVineEvents } from '../../store/auth.storeTs';
  import AutomationItem from './AutomationItem.svelte';
  import { type IFileVineSettings, AutomationSetting } from '../../typesTs';
  import Portal from '../other/Portal.svelte';
  import DefaultPopup from '../popups/DefaultPopup.svelte';
  import DefaultButton from '../buttons/DefaultButton.svelte';
  import DefaultLoader from '../other/DefaultLoader.svelte';
  import { createEventDispatcher } from 'svelte';
  export let user;
  const dispatch = createEventDispatcher();

  let automationSettings:IFileVineSettings = {};
  settings.subscribe(value => {
    automationSettings = value.fileVine;
  });

  let loading:boolean = false;
  let popupActive:boolean = false;

  const enable = () => {
    loading = true;
    setSettings<IFileVineSettings>(AutomationSetting.FileVine, Object.assign({...automationSettings}, {
      enabled: true,
    })).finally(() => {
      loading = false;
      popupActive = false;
    });
  };

  const handleClick = () => {
    console.log('handleClick', user.settings.fileVine);
    // if (!automationSettings.enabled) {
    //   popupActive = true;
    // } else {
    //   dispatch('open');
    // }
  };

</script>

<AutomationItem enabled={user.fileVineEnabled} {loading} name="FileVine" img="Filevine_Square_Logo.png" on:click={() => handleClick()} />
<Portal>
  <DefaultPopup bind:active={popupActive} zIndex="{1102}">
    <form class="filevine-automation-popup { loading ? 'filevine-automation-popup--loading' : '' }" on:submit|preventDefault={() => enable()}>
      {#if loading}
        <DefaultLoader class="filevine-automation-popup__loader" />
      {/if}
      <h2 class="filevine-automation-popup__title">Add Automation</h2>
      <img class="filevine-automation-popup__img" src="/Filevine_Square_Logo.png" alt="">
      <span class="filevine-automation-popup__subtitle">Insert API Credentials To Enable</span>
      <input class="filevine-automation-popup__input" bind:value="{automationSettings.token}" type="text" placeholder="API Key/Token" required>
      <input class="filevine-automation-popup__input" bind:value="{automationSettings.secret}" type="text" placeholder="API Secret" required>
      <div class="filevine-automation-popup__toggle">
        <DefaultButton text="Enable" type="submit" />
      </div>
    </form>
  </DefaultPopup>
</Portal>

<style lang="scss" scoped>

  :global(.filevine-automation-popup__loader) {
    position: absolute;
  }
  
  .filevine-automation-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90vw;
    max-width: 400px;
    padding: 10px 30px 30px;

    &--loading {
      pointer-events: none;
      opacity: 0.5;
    }

    &__title {
      margin: 0 auto 10px 0;
      font-weight: 400;
      font-size: 1.2rem;
    }

    &__img {
      width: 90%;
      max-width: 100px;
      margin-bottom: 10px;
    }

    &__subtitle {
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
    }

    &__input {
      border-radius: 5px;
      border: 1px solid #000;
      margin-right: 10px;
      flex-grow: 1;
      padding: 5px 10px;
      width: 90%;
      margin-bottom: 20px;
    }

    &__toggle {
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }
</style>