export interface IUser {
  id: string,
  appId: string,
  email: string,
  name: string,
  surname: string,
  img: string,
  phone: string,
  role: string,
  locale: string,
  loggedIn?: number,
  google?: boolean,
  microsoft?: boolean,
  settings?: IAutomationSettings,
  subscribed?: string[];
  newUser?: boolean;
}

export interface IUserPaymentDetails {
  subscribtions: {
    id: string,
    ends: number,
    status: string,
    defaultPaymentMethodId: string,
  }[],
  paymentMethods: {
    id: string,
    type: string,
    cardNumber: string,
  }[]
}

interface IScheduleItem {
  day: number,
  time: number
}

export interface ITag {
  name: string,
  id: string,
}

export interface ICaseMatter {
  name: string,
  details: string,
  creator: string,
  recipient: string,
  timestamp: number,
}

export interface IPartyInfo {
  name: string,
  details: string,
  creator: string,
  recipient: string,
  address1: string,
  address2: string,
  city: string,
  country: string,
  zipCode: string,
  timestamp: number,
}

export interface IEvent {
  name: string,
  details: string,
  eventTimestamp: number,
  eventDate: string,
  eventTime: string,
  eventType: string,
  members?: string[],
  location: string,
  creator: string,
  timestamp: number, // created
  demo?: boolean,
  chatId?: string,
  timeZone?: string,
  fvId?: number | null,
  mcId?: number | null,
  gcId?: string | null,
  ocId?: string | null,
  notified?: number
  noteId?: string,
}

export interface IChat {
  id: string,
  appId: string,
  chatStatus?: string,
  chatType: string,
  language?: string,
  fullName?: string,
  phone?: string,
  email?: string,
  birthday?: string,
  accountStatus?: string,
  img?: string,
  device?: string,
  tags: ITag[],
  cases: ICaseMatter[],
  party: IPartyInfo[],
  events: IEvent[],
  noteids?: string[],
  notes?: string[],
  creationTime?: number,
  appInstalled?:boolean,
  defaultNoteId?: string,
  twilioLastErr?: string, 
  lastMessage: {
    text: string,
    author: string,
  },
  lastActivity: number
  with: string,
  members?: {
    [key:string]: IChatMember
  },
  realMembers?: {
    [key:string]: IChatMember
  },
  advanced: {
    fileVineProjectIds?: string[],
    caseFileIds?: string[],
  },
  assignedTo?: string[],
  caseFileIdsNameMap?: {[key: string]: string},
  caseFileIdsMetaMap?: {[key: string]: {type?: string, phase: string}},
  fileVineProjectIdsNameMap?: {[key: string]: string},
  fileVineProjectIdsMetaMap?: {[key: string]: {type?: string, phase?: string}},
  notifyExcludedEventTypes?:string[] | null
}

export interface IChatMember {
  id?: string,
  status: string,
  newMessages: number,
  role?: string,
}

export interface ITwilioSettings {
  sid: string,
  authToken: string,
  serviceId: string,
  phone: string,
  userId?: string,
}

export interface IMessage {
  id: string,
  text: string,
  date: number,
  author: string,
  authorLabel: string,
  attachment: string,
  source: string,
  loading?: boolean,
  twilioStatus?: string,
  attachmentMimeType?: string,
  noteId?: string,
  projectId?: string
}

export interface IFullChat {
  chat: IChat,
  messages: IMessage[]
}

export interface IChatsSettings {
  chatList: {
    [key:string]: {
      order: number,
      color: string,
    }
  } 
}

export interface IMonthDay {
  date: Date,
  current: boolean,
}

export interface IGcalendarSettings {
  enabled?: boolean,
  calendarId?: string,
  schedule?: IScheduleItem
}

export interface ITwilioTileSettings {
  enabled?: boolean
}

export interface IOcalendarSettings {
  enabled?: boolean,
  calendarId?: string,
  schedule?: IScheduleItem
}

export interface IFileVineSettings {
  enabled?: boolean
  token?: string
  secret?: string
  projectId?: string
  linkSubDomain?: string,
}

export interface IMerusCaseSettings {
  enabled?: boolean,
  token?: string,
  projectId?: string 
  schedule?: IScheduleItem
}
export interface IFluentCaseSettings {
  enabled?: boolean,
  token?: string,
  projectId?: string 
  schedule?: IScheduleItem
}

export interface INotifySettings {
  enabled?: boolean,
  logicSend?: boolean,
  appSend?: boolean,
  smsSend?: boolean,
  smsUser?: string,
  emailSend?: boolean,
  emailUser?: string,
  emailUser2?: string,
  sendOffset?: number,
  sendAfterOffset?: number,
  eventTypes?: string[],
  eventTypesAfter?: string[],
  eventTypesInitial?: string[],
  eventTypesCanceletion?: string[],
  companyName?: string,
  birthday?: boolean,
  eventTemplates?: {[key:string]: {[key:string]: string}},
  eventTemplatesAfter?: {[key:string]: {[key:string]: string}},
  eventTemplatesInitial?: {[key:string]: {[key:string]: string}},
  eventTemplatesCanceletion?: {[key:string]: {[key:string]: string}},
  eventTemplatesBirthday?: {[key:string]: {[key:string]: string}},
}

export interface IAutomationSettings {
  outlookCalendar: IOcalendarSettings,
  gcalendar: IGcalendarSettings,
  merusCase: IMerusCaseSettings,
  fileVine: IFileVineSettings,
  fluentCase: IFluentCaseSettings,
  notify: INotifySettings,
  twilio: ITwilioTileSettings,
}

export enum AutomationSetting {
  Gcalendar = 'gcalendar',
  OutlookCalendar = 'outlookCalendar',
  MerusCase = 'merusCase',
  FluentCase = 'fluentCase',
  FileVine = 'fileVine',
  Notyfy = 'notify',
  Twilio = 'twilio',
}

export interface IMonitoring {
  time: number,
  events?: number,
  conversations?: number,
  details?: string,
  message?: string,
  messagesCount?: string,
  event?: IEvent,
  link?: string,
  endpoint?: string
}

export interface IResProject {
  projectId: string,
  projectName: string,
  clientName: string,
}

export interface IResChatData {
  resId: string,
  name: string,
  email: string,
  phone: string,
}

export interface IPhase {
  phase: string; 
  title: string; 
  description: string; 
  order: number, 
  type?: string, 
  fileVineId?: string;
  languages?: {
    [lang: string]: {
      phase?: string; 
      title?: string; 
      description?: string; 
    }
  }
}

export interface IPhaseFull extends IPhase {
  index: number
}