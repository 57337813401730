<section class="background">
  <ul class="background-grid">
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
  </ul>
  <div class="background-blurs">
    <div class="blur-1"><span></span></div>
    <div class="blur-2"><span></span></div>
    <div class="blur-3"><span></span></div>
  </div>
</section>

<style>
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  html,
  body {
    margin: 0;
  }

  .background {
    background: white;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
  }
</style>
