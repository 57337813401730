
<script lang="ts">
  let clazz:string = '';
  export { clazz as class };
</script>

<div class="default-loader {clazz}">
  <svg width="44px" height="44px" viewBox="0 0 100 100" ><circle cx="50" cy="50" fill="none" stroke="#85a2b6" stroke-width="15" r="32" stroke-dasharray="150.79644737231007 52.26548245743669"><animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform></circle></svg>
</div>

<style lang="scss" scoped>
  
  .default-loader {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      max-width: 100%;
      height: auto;
    }
  }
</style>