<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import DefaultLoader from "./DefaultLoader.svelte";
  import {
    sendBase64FileToGoogleDrive,
    sendBase64FileToOneDrive,
  } from "./store/auth.store";

  const dispatch = createEventDispatcher();

  export let src = "";
  export let user = "";
  let loading = false;

  let localSrc = "";
  const fileReader = new FileReader();
  let attachment = "";
  let attachmentMimeType = "";

  fileReader.onload = function (e) {
    if (e.target.result) {
      attachment = e.target.result.toString();
      localSrc = attachment;
      // dispatch("upload", {
      //   file: attachment.split(";base64,")[1],
      //   fileMimeType: attachmentMimeType,
      // });
      handleIconUpload(attachment.split(";base64,")[1], attachmentMimeType);
    }
  };

  const handleIconUpload = (file, fileMimeType) => {
    loading = true;

    const handleUpload = (uploadFunc) => {
      uploadFunc(file, fileMimeType)
        .then((src) => {
          dispatch("upload", {
            image: src,
          });
        })
        .catch(console.error)
        .finally(() => {
          loading = false;
        });
    };

    if (user.google) {
      handleUpload(sendBase64FileToGoogleDrive);
    } else if (user.microsoft) {
      handleUpload(sendBase64FileToOneDrive);
    }
  };

  const handleAttachmentUpload = (e) => {
    if (e && e.target && e.target.files && e.target.files[0]) {
      if (e.target.files[0].size > 200000000) {
        alert("File is too big!");
      } else {
        const file = e.target.files[0];
        attachmentMimeType = file.type;
        fileReader.readAsDataURL(file);
      }

      e.target.value = "";
    }
  };

  const loadAlternativeImage = () => {
    if (src) {
      if (src.includes("https://lh3.google.com/u/0/d/")) {
        src = "";
      } else {
        src = src.replace(
          "https://drive.google.com/uc?export=view&id=",
          "https://lh3.google.com/u/0/d/"
        );
      }
    }
  };
</script>

<label class="icon-uploader {loading ? 'icon-uploader--loading' : ''}">
  {#if loading}
    <DefaultLoader class="icon-uploader__loader" />
  {/if}

  {#if localSrc || src}
    {#if !loading}
      <img
        class="icon-uploader__img"
        src={localSrc || src}
        alt=""
        on:error={loadAlternativeImage}
      />
    {/if}
  {:else}
    <div class="icon-uploader__placeholder">
      <span class="material-symbols-outlined"> image </span>
    </div>
  {/if}
  <div class="icon-uploader__badge">
    <svg
      class="icon-uploader__pen"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0"
      y="0"
      viewBox="0 0 512.001 512.001"
      style="enable-background:new 0 0 512 512"
      xml:space="preserve"
      ><g
        ><path
          d="m496.063 62.299-46.396-46.4c-21.199-21.199-55.689-21.198-76.888 0L27.591 361.113c-2.17 2.17-3.624 5.054-4.142 7.875L.251 494.268a15.002 15.002 0 0 0 17.48 17.482L143 488.549c2.895-.54 5.741-2.008 7.875-4.143l345.188-345.214c21.248-21.248 21.251-55.642 0-76.893zM33.721 478.276l14.033-75.784 61.746 61.75-75.779 14.034zm106.548-25.691L59.41 371.721 354.62 76.488l80.859 80.865-295.21 295.232zM474.85 117.979l-18.159 18.161-80.859-80.865 18.159-18.161c9.501-9.502 24.96-9.503 34.463 0l46.396 46.4c9.525 9.525 9.525 24.939 0 34.465z"
          fill="#000000"
          data-original="#000000"
          class=""
        ></path></g
      ></svg
    >
  </div>
  <input
    class="icon-uploader__input"
    type="file"
    accept="image/*"
    on:change={handleAttachmentUpload}
  />
</label>

<style>
  :global(.icon-uploader__loader) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .icon-uploader {
    display: block;
    padding: 0;
    margin: 0;
    cursor: pointer;
    position: relative;
    flex-shrink: 0;
    max-height: 40px;
  }
  .icon-uploader--loading {
    opacity: 0.7;
    pointer-events: none;
  }

  .icon-uploader__placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #cdcdcd;
    border-radius: 10px;
  }

  .icon-uploader__img {
    /* aspect-ratio: 1 / 1; */
    object-fit: cover;
    border-radius: 7px;
    height: 40px;
  }

  .icon-uploader__badge {
    position: absolute;
    z-index: 2;
    bottom: -5px;
    right: -5px;
    width: 25px;
    height: 25px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-uploader__pen {
    width: 60%;
    height: auto;
  }

  .icon-uploader__input {
    display: none;
  }
</style>
