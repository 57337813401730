
<script lang="ts">
  import { settings, setSettings } from '../../store/auth.storeTs';
  import AutomationItem from './AutomationItem.svelte';
  import { type IFluentCaseSettings, AutomationSetting } from '../../typesTs';
  import Portal from '../other/Portal.svelte';
  import DefaultPopup from '../popups/DefaultPopup.svelte';
  import DefaultButton from '../buttons/DefaultButton.svelte';
  import DefaultLoader from '../other/DefaultLoader.svelte';
  import { createEventDispatcher } from 'svelte';
  // import TutorialStep from './TutorialStep.svelte';

  const dispatch = createEventDispatcher();

  let automationSettings:IFluentCaseSettings = {};
  let savedSettings:IFluentCaseSettings = {}; 
  settings.subscribe(value => {
    automationSettings = value.merusCase;
    savedSettings = value.merusCase;
  });

  let loading:boolean = false;
  let popupActive:boolean = false;

  export let user;
  let blog = { siteurl: "https://qix.cloud" };
  export let fluentCaseToken;

  async function setFluentCaseToken() {
    let body = { user_id: user.appId };
    if (fluentCaseToken != "") {
      body = { user_id: user.appId, token: fluentCaseToken };
    }
    const req = await fetch(
      blog.siteurl + "/ajax/fluentCaseToken.php",
      {
        method: "POST",
        body: JSON.stringify(body),
      }
    );
    let res = await req.json();
    fluentCaseToken = res.token != "" ? fluentCaseToken : "";

  }

  const enable = () => {
    loading = true;
    setFluentCaseToken().finally(() => {
      loading = false;
      popupActive = false;
    });
  };

  const handleClick = () => {
    if (!automationSettings.enabled) {
      popupActive = true;
    } else {
      dispatch('open');
    }
  };

  export let tutorialStep:number = 0;
  

  $: {
    if (tutorialStep === 202) {
      popupActive = true;
      savedSettings = JSON.parse(JSON.stringify(automationSettings));
      automationSettings.enabled = false;
    } else {
      automationSettings = savedSettings;
      popupActive = false;
    }
  }

</script>

<AutomationItem enabled={(tutorialStep === 201 || tutorialStep === 202) ? false : fluentCaseToken} {loading} highlighted={tutorialStep === 201} name="FluentCase" img="/fluentcase.svg" on:click={() => handleClick()} />
<Portal>
  <DefaultPopup bind:active={popupActive} zIndex="{1102}">
    <form class="meruscase-automation-popup { loading ? 'meruscase-automation-popup--loading' : '' }" on:submit|preventDefault={() => enable()}>
      {#if loading}
        <DefaultLoader class="meruscase-automation-popup__loader" />
      {/if}
      <h2 class="meruscase-automation-popup__title">Add Automation</h2>
      {#if tutorialStep === 202}
        <div class="meruscase-automation-popup__tutorial">
          <!-- <TutorialStep text="Enter credentials and click 'Enable' button" /> -->
        </div>
      {/if}
      <img class="meruscase-automation-popup__img" src="/fluentcase.svg" alt="">
      <span class="meruscase-automation-popup__subtitle">Insert API Credentials To Enable</span>
      <input
      class="meruscase-automation-popup__input"
      class:highlighted={tutorialStep === 202}
      bind:value={fluentCaseToken}
      type="text"
      placeholder="API Token"
     />
    
    <div class="meruscase-automation-popup__toggle">
      <DefaultButton
        class={tutorialStep === 202 ? 'highlighted' : ''}
        text= {fluentCaseToken == '' ? "Disable" : "Enable"}
        type="submit"
        />
    </div>
    </form>
  </DefaultPopup>
</Portal>

<style lang="scss" scoped>

  :global(.meruscase-automation-popup__loader) {
    position: absolute;
  }
  
  .meruscase-automation-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90vw;
    max-width: 400px;
    padding: 10px 30px 30px;

    &--loading {
      pointer-events: none;
      opacity: 0.5;
    }

    &__title {
      margin: 0 auto 10px 0;
      font-weight: 400;
      font-size: 1.2rem;
    }

    &__img {
      width: 90%;
      max-width: 60px;
      margin-bottom: 20px;
      margin-top: 20px;
    }

    &__subtitle {
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
    }

    &__input {
      border-radius: 5px;
      border: 1px solid #000;
      margin-right: 10px;
      flex-grow: 1;
      padding: 5px 10px;
      width: 90%;
      margin-bottom: 20px;
    }

    &__toggle {
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }
</style>