import { readAsArrayBuffer } from "./asyncReader.js";
import { fetchFont, getAsset } from "./prepareAssets";
import { noop } from "./helper.js";

export async function save(pdfFile, objects, name) {
  const PDFLib = await getAsset("PDFLib");
  const download = await getAsset("download");
  const makeTextPDF = await getAsset("makeTextPDF");
  let pdfDoc;
  try {
    pdfDoc = await PDFLib.PDFDocument.load(await readAsArrayBuffer(pdfFile));
  } catch (e) {
    console.log("Failed to load PDF.");
    throw e;
  }
  const pagesProcesses = pdfDoc.getPages().map(async (page, pageIndex) => {
    const pageObjects = objects[pageIndex];
    // 'y' starts from bottom in PDFLib, use this to calculate y
    const pageHeight = page.getHeight();
    const pageWidth = page.getWidth();
    const embedProcesses = pageObjects.map(async (object) => {
      if (object.type === "image") {
        let { file, x, y, width, height } = object;
        let img;
        try {
          if (file.type === "image/jpeg") {
            img = await pdfDoc.embedJpg(await readAsArrayBuffer(file));
          } else {
            img = await pdfDoc.embedPng(await readAsArrayBuffer(file));
            console.log(await readAsArrayBuffer(file));
          }
          return () =>
            page.drawImage(img, {
              x,
              y: pageHeight - y - height,
              width,
              height,
            });
        } catch (e) {
          console.log("Failed to embed image.", e);
          return noop;
        }
      } else if (object.type === "Signature" || object.type === "quickSign") {
        return () => page;
      } else if (object.type === "text") {
        if (
          object.text != "Initial" &&
          object.text != "Date" &&
          object.text != "Name"
        ) {
          let { x, y, lines, lineHeight, size, fontFamily, width } = object;
          if (typeof lines === "undefined") {
            //return () => page;
            lines = [text];
          }
          const height = size * lineHeight * lines.length;
          const font = await fetchFont(fontFamily);
          if (width == 0) width = 99;
          const [textPage] = await pdfDoc.embedPdf(
            await makeTextPDF({
              lines,
              fontSize: size,
              lineHeight,
              width,
              height,
              font: font.buffer || fontFamily, // built-in font family
              dy: font.correction(size, lineHeight),
            })
          );
          return () =>
            page.drawPage(textPage, {
              width,
              height,
              x,
              y: pageHeight - y - height,
            });
        } else {
          return () => page;
        }
      } else if (object.type === "drawing" || object.type === "drawingSign") {
        let { x, y, path, scale } = object;
        const {
          pushGraphicsState,
          setLineCap,
          popGraphicsState,
          setLineJoin,
          LineCapStyle,
          LineJoinStyle,
        } = PDFLib;
        return () => {
          page.pushOperators(
            pushGraphicsState(),
            setLineCap(LineCapStyle.Round),
            setLineJoin(LineJoinStyle.Round)
          );
          page.drawSvgPath(path, {
            borderWidth: 5,
            scale,
            x,
            y: pageHeight - y,
          });
          page.pushOperators(popGraphicsState());
        };
      }
    });
    // embed objects in order
    const drawProcesses = await Promise.all(embedProcesses);
    drawProcesses.forEach((p) => p());
  });
  await Promise.all(pagesProcesses);
  try {
    const pdfBytes = await pdfDoc.save();
    download(pdfBytes, name, "application/pdf");
  } catch (e) {
    console.log("Failed to save PDF.");
    throw e;
  }
}
function base64ArrayBuffer(arrayBuffer) {
  var base64 = "";
  var encodings =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";

  var bytes = new Uint8Array(arrayBuffer);
  var byteLength = bytes.byteLength;
  var byteRemainder = byteLength % 3;
  var mainLength = byteLength - byteRemainder;

  var a, b, c, d;
  var chunk;

  // Main loop deals with bytes in chunks of 3
  for (var i = 0; i < mainLength; i = i + 3) {
    // Combine the three bytes into a single integer
    chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

    // Use bitmasks to extract 6-bit segments from the triplet
    a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
    b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
    c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
    d = chunk & 63; // 63       = 2^6 - 1

    // Convert the raw binary segments to the appropriate ASCII encoding
    base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
  }

  // Deal with the remaining bytes and padding
  if (byteRemainder == 1) {
    chunk = bytes[mainLength];

    a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

    // Set the 4 least significant bits to zero
    b = (chunk & 3) << 4; // 3   = 2^2 - 1

    base64 += encodings[a] + encodings[b] + "==";
  } else if (byteRemainder == 2) {
    chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

    a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
    b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4

    // Set the 2 least significant bits to zero
    c = (chunk & 15) << 2; // 15    = 2^4 - 1

    base64 += encodings[a] + encodings[b] + encodings[c] + "=";
  }

  return base64;
}
export async function getData(pdfFile, objects, name) {
  const PDFLib = await getAsset("PDFLib");
  const download = await getAsset("download");
  const makeTextPDF = await getAsset("makeTextPDF");
  let pdfDoc;
  let signatures = [];
  try {
    pdfDoc = await PDFLib.PDFDocument.load(await readAsArrayBuffer(pdfFile));
  } catch (e) {
    console.log("Failed to load PDF.");
    throw e;
  }
  const pagesProcesses = pdfDoc.getPages().map(async (page, pageIndex) => {
    const pageObjects = objects[pageIndex];
    // 'y' starts from bottom in PDFLib, use this to calculate y
    const pageHeight = page.getHeight();
    const pageWidth = page.getWidth();
    const embedProcesses = pageObjects.map(async (object) => {
      if (object.type === "image") {
        let { file, x, y, width, height } = object;
        let img;
        try {
          if (file.type === "image/jpeg") {
            img = await pdfDoc.embedJpg(await readAsArrayBuffer(file));
          } else {
            img = await pdfDoc.embedPng(await readAsArrayBuffer(file));
            console.log(await readAsArrayBuffer(file));
          }
          return () =>
            page.drawImage(img, {
              x,
              y: pageHeight - y - height,
              width,
              height,
            });
        } catch (e) {
          console.log("Failed to embed image.", e);
          return noop;
        }
      } else if (object.type === "Signature") {
        let { file, x, y, width, height } = object;
        let tmp = {
          type: object.type,
          url: file,
          x,
          y,
          width,
          height,
          pageIndex,
          pageWidth,
          pageHeight,
        };
        signatures.push(tmp);
        return () => page;
      } else if (object.type === "quickSign") {
        let { file, x, y, width, height } = object;
        let tmp = {
          type: object.type,
          url: file,
          x,
          y,
          width,
          height,
          pageIndex,
          pageWidth,
          pageHeight,
        };
        signatures.push(tmp);
        return () => page;
      } else if (object.type === "text") {
        let { text, x, y, width } = object;
        let tmp = {
          type: object.type,
          text: text,
          x,
          y,
          width,
          pageIndex,
          pageWidth,
          pageHeight,
        };
        signatures.push(tmp);
        return () => page;
        //}
      } else if (object.type === "drawing" || object.type === "drawingSign") {
        let { x, y, path, scale } = object;
        const {
          pushGraphicsState,
          setLineCap,
          popGraphicsState,
          setLineJoin,
          LineCapStyle,
          LineJoinStyle,
        } = PDFLib;
        return () => {
          page.pushOperators(
            pushGraphicsState(),
            setLineCap(LineCapStyle.Round),
            setLineJoin(LineJoinStyle.Round)
          );
          page.drawSvgPath(path, {
            borderWidth: 5,
            scale,
            x,
            y: pageHeight - y,
          });
          page.pushOperators(popGraphicsState());
        };
      }
    });
    // embed objects in order
    const drawProcesses = await Promise.all(embedProcesses);
    drawProcesses.forEach((p) => p());
  });
  await Promise.all(pagesProcesses);
  try {
    const pdfBytes = await pdfDoc.save();
    return [base64ArrayBuffer(pdfBytes), signatures];
  } catch (e) {
    console.log("Failed to save PDF.");
    throw e;
  }
}
