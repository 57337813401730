
<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import DefaultLoader from '../other/DefaultLoader.svelte';
  import { theme } from '../../store/theme.store';
  const dispatch = createEventDispatcher();

  export let enabled:boolean;
  export let loading:boolean = false;
  export let img:string;
  export let name:string;
  export let highlighted:boolean = false;
</script>

<div class="automation-item {$theme} {highlighted ? 'highlighted' : ''} {loading ? 'automation-item--loading' : ''}" on:click={() => dispatch('click')} on:keypress={() => dispatch('click')}>
  {#if loading}
    <DefaultLoader class="automation-item__loader" />
  {/if}
  <img class="automation-item__img" src="{img}" draggable="false" alt="">
  <p class="automation-item__title">{name}</p>
  {#if enabled}
    <button class="automation-item__btn">
      <span class="automation-item__status automation-item__status--enabled"></span>
      <div class="automation-item__status-name">Enabled</div>
    </button>
  {:else}
    <button class="automation-item__btn">
      <span class="automation-item__status automation-item__status"></span>
      <div class="automation-item__status-name">Disabled</div>
    </button>
  {/if}
</div>

<style lang="scss" scoped>

  :global(.automation-item__loader) {
    position: absolute;
  }

  .automation-item {
    background-color: #ECF2F6;
    padding: 30px 10px 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    max-width: 180px;
    width: 100%;
    transition: background-color 0.3s;

    &.theme-dark {
      background-color: #2b2b2b;
    }

    &--loading {
      pointer-events: none;
      opacity: 0.7;
    }

    &__img {
      width: 60%;
      max-width: 80px;
    }

    &__title {
      text-align: center;
      font-size: 18px;
      margin-bottom: 15px;
      margin-top: 35px;
      transition: color 0.3s;
    }

    &__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    &__status {
      display: block;
      width: 18px;
      height: 18px;
      background-color: #5f5f5f;
      margin-right: 10px;
      border-radius: 50%;

      &--enabled {
        background-color: rgb(7, 151, 7);
      }
    }

    &__status-name {
      transition: color 0.3s;
    }

    &.theme-dark &__status-name {
      color: #fff;
    }
  }
</style>