
<script lang="ts">
  import AutomationMerusCase from './AutomationMerusCase.svelte';
  import AutomationFileVine from './AutomationFileVine.svelte';
  import FileVineDashboard from './FileVineDashboard.svelte';
  import MerusCaseDashboard from './MerusCaseDashboard.svelte';
  import { createEventDispatcher } from 'svelte';
    import AutomationFluentCase from './AutomationFluentCase.svelte';
    import { AutomationSetting } from '../../typesTs';

  const dispatch = createEventDispatcher();
  export let user;
  export let fluentCaseToken;



  let activeDashboard:AutomationSetting|null = null; 

  const selectDashboard = (dashboard: AutomationSetting|null):void => {
    activeDashboard = dashboard;
  };
  
  export let tutorialStep:number = 0;

  $: {
    if (tutorialStep === 303) {
      setTimeout(() => {
        selectDashboard(AutomationSetting.Notyfy);
      }, 100);
    }

    if (tutorialStep === 300) {
      selectDashboard(null)
    }
  }
</script>

<div class="automation {!activeDashboard ? 'automation--grid' : ''}">
  <!-- {#if activeDashboard === AutomationSetting.FileVine}
    <FileVineDashboard on:close={() => selectDashboard(null)} on:forceClose={() => dispatch('close')} />
  {:else if activeDashboard === AutomationSetting.MerusCase}
    <MerusCaseDashboard on:close={() => selectDashboard(null)} on:forceClose={() => dispatch('close')} />
  {:else if activeDashboard === AutomationSetting.FluentCase}
  <MerusCaseDashboard on:close={() => selectDashboard(null)} on:forceClose={() => dispatch('close')} />
  {:else}  -->
    <AutomationMerusCase bind:user={user} tutorialStep={tutorialStep} on:open={() => selectDashboard(AutomationSetting.MerusCase)} />
    <AutomationFileVine bind:user={user} on:open={() => selectDashboard(AutomationSetting.FileVine)} />
    <AutomationFluentCase bind:user={user} bind:fluentCaseToken={fluentCaseToken} on:open={() => selectDashboard(AutomationSetting.FluentCase)} />

  <!-- {/if} -->
  
</div>

<style lang="scss" scoped>
  .automation {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 5px;

    &--grid {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      justify-items: center;
      align-items: center;
      align-content: start;
    }
  }
</style>