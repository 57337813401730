import { writable } from 'svelte/store';
import { type IUser } from './../typesTs';
import { user as storeUser } from './auth.store';

const VITE_API_LINK = "https://api.qix.cloud/";


let user:IUser;

storeUser.subscribe((value) => {
  user = value;
});

export const team = writable<IUser[]>([]);

export const getTeam = ():Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    fetch(VITE_API_LINK + 'team', {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
    }).then((res) => {
      if (res.status >= 300) {
        reject();
      } else {  
        return res.json();
      }
    }).then((res) => {
      team.set(res);
      resolve();
    }).catch(() => {
      reject();
    });
  });
};

export const deleteTeamMember = (id:string):Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    fetch(VITE_API_LINK + 'team', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
      body: JSON.stringify({
        id
      }),
    }).then((res) => {
      if (res.status >= 300) {
        reject();
      } else {  
        return res.json();
      }
    }).then((res) => {
      resolve();
    }).catch(() => {
      reject();
    });
  });
};